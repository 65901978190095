.container {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: black;
  border-radius: 5px;
  padding: 20px;
  width: 370px;
  z-index: 5;
  transition: all 0.5s ease;
  pointer-events: none;
  transform: translate(120%, 0);

  &.open {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  }

  .settingsSectionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .settingsRow {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 2px;
    }

    .sectionLabel {
      font-size: 14px;
      color: #7d7c7c;
    }

    .settingsDropdowns {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .fontSelector {
        height: 50px;
        border-radius: 5px 0 0 5px;
        border: none;
        overflow: hidden;

        &:hover {
          color: #fff;
          background-color: #3e3e3e;
        }
      }

      .sizeFieldWrapper {
        margin-right: 0px;
        height: 50px;

        .sizePostfix {
          display: flex;
        }

        .sizeField {
          border-radius: 0;
          padding-left: 10px;

          &:hover {
            color: #fff;
            background-color: #3e3e3e;
          }
        }

        .rightSizeField {
          border-radius: 0 5px 5px 0;
        }
      }

      .changeStyleButton {
        font-size: 14px;
        background-color: #2c2c2c;

        .fontStyleIcon {
          path {
            fill: #7d7c7c;
          }
        }

        &:hover {
          color: #fff;
          background-color: #3e3e3e;

          .fontStyleIcon {
            path {
              fill: #fff;
            }
          }
        }
      }

      .alignmentSettings {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 5px;
        overflow: hidden;

        .alignments {
          border-radius: 0;
        }

        .active {
          background-color: #252c68;
        }
      }
    }
  }

  .defaultSettings {
    margin-top: 10px;
  }
}
