.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  width: 100%;

  .toggleSwitch {
    max-width: 300px;
    border: 1px solid #d0d0d0;
  }

  .elementContainer {
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &:has(.transcription) {
      max-width: 650px;
    }

    .input {
      height: 50px;
    }
  }

  .errorMessage {
    font-size: 12px;
    color: #ff4f4f;
    position: relative;
    bottom: 8px;
  }
}
