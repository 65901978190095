@import '../constants.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  .input {
    width: inherit;
    height: inherit;
    display: flex;
    border-radius: 4px;
    font-size: 14px;
    outline: none;

    &:focus {
      border-color: $accent-700 !important;
      outline: none;
    }
  }

  .searchIcon,
  .xIcon {
    padding: 0;
    margin: 0;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
  }

  .xIcon {
    cursor: pointer;
  }

  &.border-stroke {
    .input {
      border: 1px solid;
      border-radius: 4px;
      padding-left: 15px;
      padding-right: 15px;
      padding-right: 40px;
    }

    .searchIcon,
    .xIcon {
      right: 15px;
    }
  }

  &.border-one-line {
    .input {
      border: none;
      border-bottom: 1px solid;
      border-radius: 0;
      padding-right: 24px;
    }
  }

  &.light {
    color: $black;

    .input {
      border-color: $grey-500;

      &:hover {
        border-color: $black;
      }

      &::placeholder {
        color: $grey-500;
      }
    }

    .searchIcon,
    .xIcon {
      fill: $grey-500;
    }

    .xIcon {
      &:hover {
        fill: $error;
      }
    }
  }

  &.dark {
    color: $white;

    .input {
      background-color: transparent;
      border-color: $grey-100;

      &:hover {
        border-color: $white;
      }

      &::placeholder {
        color: $grey-100;
      }
    }

    .searchIcon,
    .xIcon {
      fill: $grey-100;
    }

    .xIcon {
      &:hover {
        fill: $error;
      }
    }
  }
}
