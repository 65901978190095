.container {
  max-width: 370px;
  background-color: #000000;
  border-radius: 4px;

  .colorContainer {
    margin-bottom: 10px !important;
    min-height: 60px;
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2c2c;
    border-radius: 4px;
    margin-bottom: 1px;
    padding: 15px;

    .alignContainer {
      display: flex;
      align-items: center;
      gap: 2px;

      .alignButton {
        background-color: #3e3e3e;
        padding: 8px;
        max-height: 30px;
        cursor: pointer;

        svg {
          path {
            fill: #d0d0d0;
          }
        }

        &:hover,
        &.activeAlignButton {
          background-color: #4957d8;

          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .colorPicker {
      width: 80px;
    }

    .toggleContainer {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .title {
      color: #d0d0d0;
      font-size: 14px;
    }

    .paddingInputContainer {
      display: flex;
      gap: 5px;

      .paddingButton {
        border-radius: 4px;
        width: 30px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: #3e3e3e;
        cursor: pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  .marginContainer {
    background-color: #2c2c2c;
    padding: 15px;

    .vertical {
      flex-direction: column;
    }

    .marginInput {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .customInput {
        width: 20px;
        height: 20px;
        border-width: 0;
        background-color: transparent;
        color: #d0d0d0;

        input {
          border: none;
          text-align: center;
        }
      }

      .arrowLeft {
        transform: rotate(90deg);
      }

      .arrowTop {
        transform: rotate(180deg);
      }

      .arrowRight {
        transform: rotate(-90deg);
      }

      .arrow {
        width: 14px;
        height: 14px;
        cursor: pointer;

        &.disabledIcon {
          opacity: 0.4;
          cursor: default;
        }

        rect {
          fill: #7d7c7c;
        }
      }
    }

    .horizontalMargin {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .image {
        flex: 1;
        background-color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 10px;
        border-radius: 4px;
        border: 1px solid #7d7c7c;
      }
    }
  }
}
