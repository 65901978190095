.container {
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 12px;
  border: 1px solid #d0d0d0;
  cursor: pointer;
  padding: 20px 14px;
  border-radius: 5px;
  margin: 2px 0px;
  &:hover {
    border: 2px solid #a0a0a0;
    margin: 1px 0px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 300;
    color: #a0a0a0;
    text-align: center;
  }
}
