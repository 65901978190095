.pageWrapper {
  display: flex;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #f0f0f0;

  .pageProjectsNavigation {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    display: inline-block;
    width: 280px;
    vertical-align: top;
    transform: translateX(0%);
    transition: all;
    transition-duration: 0.5s;
  }

  .sidebarToggleBtnBlock {
    cursor: pointer;
    position: absolute;
    right: -60px;
    top: 50px;
    width: 40px;
    height: 40px;
    background-color: #000000;
    display: none;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    @media only screen and (max-width: 1000px) {
      display: flex;
    }
  }

  .moveLeft {
    transform: translateX(-300px);
  }

  .contentMoveLeft {
    margin-left: 0px;
    width: 100%;
  }

  .pageContentWrapper {
    margin-left: 280px;
    width: 100%;

    .pageContent {
      padding: 30px;
      margin: 0 auto;
      max-width: 1180px;

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .title {
          font-size: 24px;
          font-weight: 600;
        }

        .statementsAmount {
          font-size: 14px;
          font-weight: 400;
          color: #a0a0a0;
        }

        .buttonsContainer {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }

      .categoryContainer {
        margin-bottom: 20px;

        .category {
          width: 100%;
          background-color: #fafafa;
          padding: 15px 20px;
          border-radius: 6px;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .categoryNameContainer {
            display: flex;
            align-items: center;
            column-gap: 10px;
            cursor: pointer;

            &.open {
              svg {
                transform: rotate(180deg);
              }
            }

            svg {
              transition: all;
              transition-duration: 0.5s;
            }

            .categoryName {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        .templatesContainer {
          display: flex;
          flex-direction: column;
          row-gap: 2px;

          .template {
            width: 100%;
            height: 90px;
            background-color: #fafafa;
            border-radius: 6px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
              background-color: #ffffff;
            }

            .templateNameContainer {
              display: flex;
              align-items: center;
              column-gap: 20px;

              img {
                width: 165px;
                height: 90px;
                object-fit: cover;
              }

              .templateName {
                font-size: 16px;
                font-weight: 600;
              }

              .sectionsInfo {
                color: #a0a0a0;
                font-size: 14px;
                font-weight: 400;
              }
            }

            .templateName {
              font-size: 16px;
              font-weight: 600;
            }

            .templateActions {
              display: flex;
              align-items: center;
              column-gap: 20px;
            }
          }
        }
      }
    }
  }
}
