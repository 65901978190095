.button {
  background-color: #000000;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: #3e3e3e;
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: #ffffff;
    }
  }
}
