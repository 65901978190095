@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin keyframes($keyframes) {
  @-webkit-keyframes #{$keyframes} {
    @content;
  }
  @-moz-keyframes #{$keyframes} {
    @content;
  }
  @-o-keyframes #{$keyframes} {
    @content;
  }
  @keyframes #{$keyframes} {
    @content;
  }
}

@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -o-animation: #{$animation};
  animation: #{$animation};
}

@mixin animation-delay($animation-delay) {
  -webkit-animation-delay: #{$animation-delay};
  -moz-animation-delay: #{$animation-delay};
  -o-animation-delay: #{$animation-delay};
  animation-delay: #{$animation-delay};
}

.loader {
  .line {
    height: 2px;
    margin-bottom: 6px;
    @include animation(expand 1s ease-in-out infinite);

    &:nth-child(2) {
      @include animation-delay(180ms);
    }

    &:nth-child(3) {
      @include animation-delay(360ms);
    }

    &:last-child {
      margin-bottom: 0px;
      @include animation-delay(540ms);
    }
  }
}

@include keyframes(expand) {
  0% {
    @include transform(scale(1));
  }
  25% {
    @include transform(scale(2));
  }
}
