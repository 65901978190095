.wrapper {
  overflow-y: auto;
  background-color: #f0f0f0;
}

.wrapperSmallPadding {
  &::-webkit-scrollbar {
    height: 1px;
  }
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (max-width: 900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &::after {
    content: '';
    height: 15px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f0f0f0;
  }
}

.droppable {
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  min-height: 240px;
  scrollbar-color: #8591a1 #c4c9cf;
  scrollbar-width: thin;
  border-radius: 0 0 12px 12px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #c4c9cf;
  }
  &::-webkit-scrollbar-thumb {
    background: #8591a1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6b7582;
  }
}
.droppable:hover {
  cursor: default;
}

.drag:hover {
  cursor: none;
}

.datePickerWrapper {
  position: relative;
  left: 6px;
}

.totalStatistics {
  display: flex;
  height: 30px;
  width: 100%;
  min-width: 1000px;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0a0a0;
  position: sticky;
  bottom: 0;
  right: 0;
  background-color: #f0f0f0;
}
.totalStatisticsInner {
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  div {
    padding-bottom: 10px;
    padding-top: 10px;
    font-style: normal;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000000;

    border-right: 1px solid #f0f0f0;

    &:last-child {
      width: 100px;
      border-right: none;
    }
  }
}
.allStatsBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    fill: #d0d0d0;
    margin-right: 8px;
  }
  &:hover {
    svg {
      fill: #000000;
    }
  }
}
