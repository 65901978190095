.podcastPage {
  height: 100vh;
  background-color: #f0f0f0;
}

.podcasterContainerContent {
  height: 100%;
  transition-duration: 0.5s;

  &.podcastContentRight {
    padding: 0px;
    width: 100%;
    padding-left: 280px;
    transition: all;
    transition-duration: 0.5s;
  }
}

.pagePodcastNavigationWrapper {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0%);
  transition: all;
  transition-duration: 0.5s;
  width: 280px;
}
.moveLeft {
  transform: translateX(-280px);
}

.sidebarToggleBtnBlock {
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: #000000;
  display: none;
  justify-content: center;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }

  @media only screen and (max-width: 1000px) {
    display: flex;
  }
}
