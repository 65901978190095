.sections {
  flex-grow: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  overflow: auto;

  @media (max-width: 600px) {
    padding: 30px 10px;
    margin-top: 40px;
  }

  .elementsContainer {
    overflow: auto;
  }

  .label {
    color: #a0a0a0;
  }

  .title {
    font-size: 20px;
    padding-bottom: 25px;
  }

  .loadingContainer {
    margin: 10px 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 400;

    .loaderContainer {
      border: 1px solid #d0d0d0;
      border-radius: 6px;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;

      div {
        div {
          height: 1px;
        }
      }
    }
  }

  .addButton {
    margin-top: 10px;
    min-height: 40px;
  }
}
