.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    animation: 0.5s ease-in-out fadeInBackground;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 670px;
    background-color: #f0f0f0;
    z-index: 101;
    font-family: Inter;
    border-radius: 10px;
    animation: 0.5s ease-in-out fadeInPopup;

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      fill: #a0a0a0;
      z-index: 1;

      &:hover {
        fill: #000000;
      }
    }

    .bannerSection {
      width: 100%;
      background-color: #232a6d;
      background-image: url('../../Assets/images/FreeCreditBackground.jpg');
      position: relative;
      overflow: hidden;
      padding: 0px 20px;

      .smallCoin,
      .mediumCoin,
      .bigCoin {
        position: absolute;
        top: 64px;
        left: 124px;
      }

      .mediumCoin {
        top: 91px;
        left: 53px;
      }

      .bigCoin {
        top: 141px;
        left: 68px;
      }

      .giftIcon {
        margin-top: 34px;
      }

      .subtitle {
        font-size: 16px;
        margin-top: 16px;
        color: #d0d0d0;

        b {
          color: white;
        }
      }

      .title {
        font-size: 40px;
        color: #ffd600;
        margin-top: 10px;
        margin-bottom: 32px;
        line-height: 40px;
      }
    }

    .contentSection {
      width: 100%;
      padding: 30px 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 20px;

      .title {
        font-size: 18px;
        font-weight: 400;
        color: #5a5a5a;
        max-width: 430px;
        line-height: 26px;
      }

      .howWorks {
        color: #000000;
        font-size: 22px;
        display: flex;
        align-items: center;
        column-gap: 30px;

        div {
          border-bottom: 1px solid #d0d0d0;
          width: 196px;
        }
      }

      ul {
        font-size: 16px;
        margin-left: 20px;
        text-align: start;

        li {
          margin-top: 6px;

          &:first-child {
            margin-top: unset;
          }
        }

        b {
          color: #4957d8;
        }
      }

      .copyInvButton {
        outline: none;
        border: none;
        background-color: #4957d8;
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
        border-radius: 25px;
        width: 180px;
        height: 50px;
        cursor: pointer;

        &:hover {
          background-color: lighten(#4957d8, 5%);
        }
      }
      .copyInvButtonBordered {
        background-color: transparent;
        border-width: 1px;
        border: solid 1px;
      }
    }
  }
}

@keyframes fadeInPopup {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInBackground {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.7);
  }
}

.hiddenOnMobile {
}
@media (max-height: 800px) {
  .hiddenOnMobile {
    display: none;
  }
}
