.container {
  height: 78px;
  display: inline-block;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
}

.input,
.error {
  height: 50px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 16px;
  padding-right: 16px;
}

.error {
  border: 1px solid red;
}

.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container input[type='number'] {
  -moz-appearance: textfield;
}

.input::placeholder {
  color: #7b8695;
}

.input:focus {
  outline: none;
}
