.container {
  flex: 1;
  min-height: 50px;
  display: flex;
  margin: 2px 0;

  .section {
    flex: 1;

    .setcion_item {
      max-width: 100%;
      margin: 8px 0;
      flex: 1;

      .section_item_contnent {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        flex: 1;
      }
    }

    &:hover {
      border: none;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='round'/%3e%3c/svg%3e");
    }
  }

  .placeholder {
    overflow: hidden;
    div {
      background-color: aquamarine;
      height: 100%;
      min-width: 100%;
    }
  }
}
