.domainName {
  min-width: 215px;
  @media only screen and (max-width: 900px) {
    width: 215px;
  }
}

.connectedFunnel {
  min-width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 900px) {
    width: 240px;
  }
  > div {
    > div {
      border: none;

      &:nth-child(2) {
        > div {
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
}

.domainItemStatus {
  color: #faa500;
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 225px;
  @media only screen and (max-width: 900px) {
    width: 225px;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &.success {
    color: #25bb73;
  }

  .reloadIcon {
    fill: #000000;
    cursor: pointer;

    &.loading {
      animation-name: rotate;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &:hover {
      fill: #3a3a3a;
    }
  }
}

.pointsBlock {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #5a5a5a;
  }
  height: 20px;
  width: 15px;
  margin: auto 0;
}

.dropdownBlock {
  width: 240px;
  position: relative;
  left: 20px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #3e3e3e;
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    bottom: 50px;
  }

  .item {
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #f0f0f0;
    }

    svg {
      width: 14px;
      height: 14px;
      margin-right: 10px;

      path {
        fill: #000000;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
