.container {
  width: 100%;
  max-width: 600px;
  padding: 40px 30px;
  display: flex;
  justify-content: center;

  .confirmationModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 380px;

    .deleteDomainText {
      color: #7d7c7c;
      max-width: 320px;
    }

    .deleteDomainText,
    .headerText {
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .headerText {
      line-height: 25px;
      font-size: 20px;
      margin-top: 10px;
    }

    .buttonsBlock {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
}
