.container {
  .wrapper {
    display: flex;
    position: relative;

    .contentWrapper {
      flex-grow: 1;
      width: calc(100% - 260px);
      background-image: url('../../../Assets/images/edit-book-bg.png');
      position: relative;
      height: calc(100dvh - 60px);
      overflow-y: auto;
      overflow-x: hidden;

      .modalContainer {
        position: fixed;
        left: calc(50% + 270px);
        z-index: 10;
        width: 380px;
      }

      .pageSettingsIcon {
        background-color: #000000;
        position: fixed;
        left: calc(50% + 570px);
        top: 70px;
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: #ffffff;
          }
        }
      }

      .contentContainer {
        width: 100%;
        max-width: 820px;
        margin: 0 auto;
        background-color: #ffffff;

        .coverCoverContainer {
          position: relative;
        }
      }

      .separator {
        background-color: #e8e8e8;
        height: 1px;
        border: none;
        margin: 40px 0;
      }
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    .sidebarTitle {
      color: #7d7c7c;
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 14px;

      svg {
        fill: currentColor;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}
