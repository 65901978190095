.container {
  display: flex;
  column-gap: 5px;
  align-items: center;

  .dropdown {
    .option {
      svg {
        display: none;
      }
    }
  }
}

.dropdownOptions {
  border: 1px solid #f0f0f0;

  li {
    height: 40px !important;
    border-bottom: 1px solid #e8e8e8;
  }
}

.option {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;

  &:hover {
    color: #5a5a5a;

    .removeIcon {
      display: block;

      &:hover {
        fill: #ff4f4f;
      }
    }
  }

  .removeIcon {
    display: none;
    fill: #a0a0a0;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.addOption {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  &:hover {
    background-color: #f0f0f0;
  }

  .input {
    height: 30px;

    & > div {
      right: 0 !important;
    }

    .addButton {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
