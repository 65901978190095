.container {
  padding: 40px;
  width: 600px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    max-width: 410px;
    color: #000000;

    .title {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .subtitle {
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: #7d7c7c;

      .paragraph {
        font-size: 14px;
        max-width: 380px;
        text-align: center;

        .url {
          color: #4957d8;

          &:hover {
            text-decoration: underline;
            color: #6674f4;
          }
        }
      }

      .domain {
        font-size: 18px;

        b {
          color: #000000;
        }
      }
    }
  }

  .buttonsWrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}
