.container {
  height: 100dvh;
  flex-grow: 1;
  overflow: auto;
  background-color: #f0f0f0;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentContainer {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    height: 100%;

    .items {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
