.container {
  height: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 140%;
  @media only screen and (max-width: 900px) {
    text-align: center;
    font-size: 26px !important;
    margin-bottom: 0 !important;
  }
}

.container input:focus {
  border-bottom: 1px solid #6c68ff;
  outline: none;
  padding-bottom: 3px;
}

.container input {
  text-overflow: ellipsis;
  border: none;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  @media only screen and (max-width: 900px) {
    text-align: center;
    font-size: 26px !important;
  }
}

.error {
  color: #ff5e5e;
  position: absolute;
  left: 0;
  font-size: 12px;
}
