.modalBlock {
  width: 840px;
  height: calc(100vh - 62px);
  max-height: 894px;
  max-width: 840px;
  background-color: #f0f0f0;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
}

.closeIcon {
  border: none;
  background-color: transparent;
  margin-left: 800px;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url('../../../Assets/icons/close.svg');
}

.closeIcon:hover {
  background-image: url('../../../Assets/icons/closeHover.svg');
}

.modalTitle {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  padding-left: 0;
  margin-top: -7px;
}

.tabs {
  // display: flex;
  // justify-content: center;
}

.modalBody {
  margin-top: 44px;
  max-height: 680px;
  // display: flex;
  // justify-content: center;
}
