.pageContainer {
  width: 100%;
  height: 100dvh;
  display: flex;

  .mainContent {
    padding: 30px 0 30px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
    flex: 1;
    height: 100%;

    &.initialPage {
      justify-content: unset;
      row-gap: 30px;
      background-color: #f0f0f0;

      .pageHeader {
        .title {
          font-size: 24px;
        }
      }
    }

    .dashboardLink {
      position: absolute;
      left: 30px;
      top: 30px;
    }

    .pageHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      max-width: 325px;
      text-align: center;

      .title {
        font-size: 22px;
        color: #000000;
        font-weight: 600;
      }

      .subtitle {
        font-size: 14px;
        color: #a0a0a0;
        font-weight: 300;
      }
    }

    .documentGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 11px;

      .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 18px;
        font-weight: 600;
      }

      .documentsList {
        display: flex;
        column-gap: 20px;
        max-width: 940px;
      }
    }

    .navButtons {
      display: flex;
      column-gap: 20px;
      justify-content: center;
      width: 100%;

      .rightIcon {
        transform: rotate(180deg);
      }
    }
  }
}
