.container {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  background-color: #fafafa;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }

  .infoContainer {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 30px;

    .image {
      height: 100%;
      width: 120px;
      min-width: 120px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .sentCount {
      display: flex;
      gap: 10px;
      align-items: center;

      .countItem {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-size: 12px;
          color: #a0a0a0;
        }
      }
    }
  }

  .settingsContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    .details {
      display: flex;
      align-items: center;
      gap: 40px;

      .detailItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        svg {
          path {
            fill: #a0a0a0;
          }
        }

        span {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }
}
