.modal {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 50px 20px 50px;
}

.mainImage {
  display: block;
  margin: 0 auto;
  width: 35px;
}

.header {
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 26px;
}

.additionalText {
  color: #000;
  text-align: center;
  font-size: 12px;
}

.buttonsBlock {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.frameModal {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 0;
}

.frame {
  border: none;
  padding: 0;
  margin: 0;
}

.frameModalPayment {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modalContainer {
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 670px;
  // background: #fff;
  border-radius: 12px;
  padding: 40px 50px;
}
