.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  width: 100dvw;
  background: linear-gradient(#303db4, #2433b6);
  display: flex;
  padding: 5px 20px;
  gap: 30px;
  color: white;
  font-size: 16px;
  justify-content: left;
  z-index: 100;

  transition: width 0.5s, left 0.5s;
}

.slideBanner {
  width: calc(100dvw - 300px);
  left: 300px;
}

.textContainer {
  margin: auto;
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  max-width: 700px;
}
.text {
  margin: auto;
  font-size: 24px;
}
.timer {
  width: 100px;
  font-size: 24px;
  margin: auto;
}

.timerText {
  margin: auto;
}

.giftIcon {
  align-self: center;
  // margin: auto;
}
.spacer {
  flex-grow: 1;
}
.buttonContainer {
  display: flex;
  column-gap: 12px;
  margin: auto;

  .button {
    outline: none;
    border: 1px solid #131313;
    background-color: #131313;
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    width: 140px;
    max-height: 40px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background-color: lighten(#04091e, 15%);
    }
  }

  .buttonClose {
    margin-top: 4px;
  }
}

@media (max-width: 1100px) {
  .timerText {
    display: none;
  }
}

@media (max-width: 600px) {
  .giftIcon {
    width: 30px;
    height: 30px;
  }
  .spacer {
    display: none;
  }
  .timerText {
    display: none;
  }
  .text {
    font-size: unset;
    font-size: 14px;
  }

  .timer {
    margin-top: -8px;
    margin-left: 0px;
    font-size: 22px;
  }
  .textContainer {
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    width: unset;
  }
  .banner {
    justify-content: center;
    padding: 10px;
    height: 70px;
    left: 0;
    gap: 10px;
    width: 100dvw;
    bottom: 59px;
  }
  .slideBanner {
    // width: calc(100dvw - 300px);
    left: 300px;
  }
  .button {
    font-size: 14px;
    max-width: 100px;
    height: 30px;
  }
  .buttonClose {
    padding-top: 3px;
  }
}
