@mixin animate {
  overflow: hidden;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #d9d9d9 2%, #ececec 18%, #d9d9d9 33%);
  background-size: 1300px;
}

.placeholderWrapper {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.placeholderTotalStats {
  height: 100px;
  flex-grow: 1;
  border-radius: 6px;
  @include animate;

  @media (max-width: 650px) {
    flex-grow: unset;
    width: 100%;
  }
}

.placeholderTableStats {
  height: 320px;
  width: 100%;
  border-radius: 6px;
  @include animate;
}

.placeholderGraphicStatsWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .placeholderGraphicStats {
    border-radius: 6px;
    height: 250px;
    flex-grow: 1;
    @include animate;

    @media (max-width: 650px) {
      flex-grow: unset;
      width: 100%;
    }
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
