.container {
  display: flex;
  align-items: center;
  gap: 1px;

  .button {
    background-color: #fafafa;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.disabled {
      cursor: default;
      background-color: #fafafa !important;

      svg {
        path {
          fill: #d0d0d0 !important;
        }
      }
    }

    &:hover {
      background-color: #ffffff;

      svg {
        path {
          fill: #5a5a5a;
        }
      }
    }

    svg {
      path {
        fill: #000000;
      }
    }

    &.undo {
      border-radius: 4px 0 0 4px;
    }

    &.redo {
      border-radius: 0 4px 4px 0;
    }
  }
}
