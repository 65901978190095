@import '../constants.scss';

.progressBar {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: $grey-600;
  overflow: hidden;

  .progressPercent {
    background-color: $accent-500;
    height: 100%;
  }
}
