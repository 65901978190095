.graphBlockWrapper {
  // max-width: 525px;
}
.graphBlockLabel {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 20px;
  > svg {
    margin-right: 10px;
    fill: currentColor;
  }
  > div {
    margin-left: 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}
.actualTotalValue {
  color: #4957d8;
}
.previousTotalValue {
  color: #a0a0a0;
}
.doubleArrowSvg {
  margin-right: 5px;
  margin-left: 5px;
}
.differencePercent {
  display: inline-block;
  padding: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  border-radius: 2px;
  margin: 0;
  margin-left: 10px;
  background-color: #d0d0d0;
}
.differencePercentRed {
  background-color: #ff4f4f;
}
.differencePercentGreen {
  background-color: #25bb73;
}

.graphBlock {
  position: relative;
  height: 155px;
  padding-top: 30px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
}
.xLabelBlock {
  position: absolute;
  bottom: -20px;
  left: -6px;
  width: calc(100% + 12px);
  padding-left: 3px;
  padding-right: 3px;
  height: 27px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  background-color: #fafafa;

  > div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #4957d8;

    span {
      color: #a0a0a0;
      margin-left: 6px;
    }
  }
}
.topActiveGraphTooltip {
  position: absolute;
  top: 10px;
  transform: translateX(-25%);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #5a5a5a;
  white-space: nowrap;
}
