.container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;

  .spacerContainer {
    display: flex;
    align-items: center;
    gap: 2px;

    .spacerButton {
      height: 50px;
      flex: 1;
      background-color: #2c2c2c;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d0d0d0;
      cursor: pointer;

      &:hover {
        background-color: #3e3e3e;
        color: #ffffff;
      }

      &.activeButton {
        background-color: #252c68;
        color: #6978ff;
      }
    }
  }

  .sizeFieldWrapper {
    margin-right: 0px;
    height: 50px;

    .inputIcon {
      path {
        fill: #d0d0d0;
      }

      rect {
        fill: #d0d0d0;
      }
    }

    .sizePostfix {
      display: flex;
    }

    .inputContainerClassName {
      background-color: transparent !important;

      & > div {
        width: 80px;
      }

      div {
        background-color: transparent !important;

        input {
          text-align: end;
          width: fit-content;
        }
      }
    }

    .sizePostfix {
      display: flex;
    }

    .sizeField {
      border-radius: 0;
      padding-left: 10px;
    }

    .rightSizeField {
      border-radius: 0 5px 5px 0;
    }

    .px {
      color: #5a5a5a;
      position: relative;
    }

    .arrows {
      top: 8px;
      right: 5px;
      gap: 10px;
    }

    .rightSizeField {
      border-radius: 0 5px 5px 0;
    }
  }
}
