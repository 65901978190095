@import '../constants.scss';

.container {
  width: 100%;
  position: relative;
  user-select: none;

  .leftArrow,
  .rightArrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    width: 50px;
    height: 50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $grey-600;

    svg {
      fill: $grey-500;
      width: 16px;
      height: 16px;
    }

    &:hover {
      border-color: $black;

      svg {
        fill: $black;
      }
    }
  }

  .leftArrow {
    left: -70px;
    transform: translateY(-50%) rotate(180deg);
  }

  .rightArrow {
    right: -70px;
  }

  .overflowWrapper {
    overflow: hidden;

    .sliderWrapper {
      display: flex;
      transition: transform 0.5s ease;
      will-change: transform;
    }

    .card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 5px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;

      &.loading {
        background-color: $grey-300;
        cursor: default;
      }

      &.selected {
        img {
          border: 2px solid $accent-500;
          padding: 2px;
        }
      }

      .label {
        height: 27px;
        color: $grey-500;
        font-weight: 300;
      }

      .removeCard {
        padding: 9.5px;
        border-radius: 8px;
        background-color: $grey-200;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5px;
        top: 34px;

        svg {
          fill: $grey-600;
        }
      }

      img {
        border-radius: 6px;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .removeCard {
          display: flex;
        }
      }
    }
  }

  .indicatorContainer {
    display: flex;
    justify-content: center;
    column-gap: 4px;
    margin-top: 15px;

    .currentSlideIndicator {
      margin: 0;
      padding: 0;
      width: 6px;
      height: 6px;
      background-color: $grey-800;
      border-radius: 50%;

      &.selected {
        background-color: $accent-500;
      }
    }
  }
}
