@import '../constants.scss';

.container {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .label {
    cursor: pointer;
    width: fit-content;
    border-bottom: 1px solid transparent;

    &.light {
      color: $black;

      &:hover {
        color: $grey-200;
        border-bottom-color: $grey-600;
      }
    }

    &.dark {
      color: $grey-500;

      &:hover {
        color: $white;
        border-bottom-color: $grey-600;
      }
    }
  }

  &.small {
    font-size: 14px;

    &:hover {
      .label {
        padding-bottom: 0px;
      }
    }

    .input {
      input {
        height: 23px;
        font-weight: 500;
        // padding-bottom: 4px;
      }
    }
  }

  &.medium {
    font-size: 16px;

    .input {
      input {
        font-size: 16px;
        padding-bottom: 4px;
      }
    }
  }

  &.large {
    font-size: 20px;

    .input {
      input {
        font-size: 20px;
        padding-bottom: 4px;
      }
    }
  }

  &.extra-large {
    font-size: 30px;

    &:hover {
      .label {
        padding-bottom: 9px;
      }
    }

    .input {
      input {
        padding-bottom: 9px;
      }
    }
  }
}
