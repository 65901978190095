.container {
  max-height: calc(100vh - 30px);
  overflow: hidden;

  .mobileHeader {
    display: none;
    justify-content: center;
    margin-bottom: 25px;

    .mobileHeaderSkeleton {
      max-width: 200px;
      height: 27px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pageName {
        width: 130px;
        height: 25px;
      }

      .addButton {
        width: 220px;
        height: 40px;
      }

      .sort {
        width: 166px;
        height: 17px;
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;

    .item {
      width: calc(30% - 70px);
      max-width: 270px;
      height: 368px;
    }
  }

  @media screen and (max-width: 900px) {
    .body {
      row-gap: 15px;

      .item {
        min-width: 150px;
        height: 225px;
      }
    }
  }

  @media screen and (max-width: 475px) {
    .mobileHeader {
      display: flex;
    }

    .header {
      display: none;
    }

    .body {
      justify-content: center;
      column-gap: 15px;
    }
  }
}
