.container {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  top: -50px;
  z-index: 4;

  .toolbarItems {
    display: flex;
    background-color: #000;
    position: relative;
    z-index: 4;

    .iconContainer {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover,
      &.activeButton {
        background-color: #4957d8;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      svg {
        path {
          fill: #d0d0d0;
        }
      }
    }
  }
}
