.container {
  max-height: 720px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  .backLink {
    position: absolute;
    left: 30px;
    top: 30px;
  }

  .header {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    align-items: center;
    max-width: 300px;

    svg {
      width: 32px;
      height: 32px;
      fill: #4957d8;
    }

    .title {
      font-size: 22px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      text-align: center;
      max-width: 230px;
    }
  }

  .form {
    width: 420px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .input {
      height: 50px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }
  }

  .buttonContainer {
    display: flex;
    column-gap: 20px;

    .arrowLeft {
      transform: rotate(180deg);

      path {
        fill: #3e3e3e;
      }
    }
  }
}
