.table {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    color: #000000;
    font-size: 12px;
    font-weight: 400;

    svg {
      fill: #a0a0a0;
    }

    &.pagesTitle {
      position: relative;
      top: 13px;
    }
  }

  .subTitle {
    color: #a0a0a0;
    font-size: 10px;
    font-weight: 300;
  }

  .tableCell {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-weight: 400;

    .currentValue {
      font-size: 12px;
      color: #000000;
    }

    .oldValue {
      font-size: 10px;
      color: #a0a0a0;
    }
  }

  .imageCell {
    padding: 0 !important;

    .previewImage {
      width: 100%;
      height: 69px;
    }
  }

  .columnHeader {
    border-bottom: none !important;
  }

  .tableRow {
    &:hover {
      background-color: darken($color: #fff, $amount: 10) !important;
    }
  }

  .pagesColumnColor {
    background-color: #fafafa !important;
  }

  .viewsColumnColor {
    background-color: #fef4d780 !important;
  }

  .leadsColumnColor {
    background-color: #daf5e480 !important;
  }

  .salesColumnColor {
    background-color: #e6e9f980 !important;
  }
}
