@import '../constants.scss';

.textareaWrapper {
  position: relative;

  .textArea {
    display: block;
    height: 100%;
    width: 100%;
    border: 1px solid;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    overflow-x: hidden;
    overflow-y: auto;
    outline: none;
    border-radius: 4px;
    background-color: transparent;

    &.light {
      color: $black;
      border-color: $grey-500;

      &::placeholder {
        color: $grey-500;
        font-weight: 400;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: $grey-500;
      }

      &:hover {
        border-color: $black;
      }
    }

    &.dark {
      color: $white;
      border-color: $grey-400;

      &::placeholder {
        color: $grey-400;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: $grey-400;
      }

      &:hover {
        border-color: $white;
      }
    }

    &.error {
      border-color: $error !important;
    }

    &:focus {
      border-color: $accent-500 !important;
    }
  }

  .errorText {
    position: absolute;
    left: 0;
    bottom: -17px;
    font-size: 12px;
    color: $error;
  }
}
