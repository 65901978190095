.editor {
  outline: none;

  .paragraph {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .tag {
    color: #4957d8;
  }
}
