.modalBlock {
  position: relative;
  top: -30px;
  width: 100vw;
  max-height: calc(100vh - 80px);
  height: 100%;
  min-height: 600px;
  background-color: #f0f0f0;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin: 40px;
}

.closeIcon {
  border: none;
  background-color: transparent;
  // margin-left: 985px;
  // margin-right: 20px;
  // margin-top: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url('../../Assets/icons/close.svg');
}

.closeIcon:hover {
  background-image: url('../../Assets/icons/closeHover.svg');
}

.modalBody {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  flex-grow: 1;
  overflow-y: hidden;

  .loader {
    align-self: center;
  }

  .tabsContent {
    height: calc(100% - 50px);
    padding-top: 50px;
  }
}

.iframeModalWrapper {
  width: 100vw;
  height: calc(100vh + 31px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -31px;
}

.iframeWrapper {
  position: relative;
  width: 100%;
  height: calc(100vh + 31px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  iframe {
    border: none;
  }
}

.iframeSpinnerBlock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
