.itemBlock {
  display: flex;
  justify-content: space-between;
  height: 70px;
  min-height: 70px;
  min-width: 900px;
  align-items: center;
  border-radius: 4px;
  padding-right: 15px;
  background-color: #fafafa;
  font-size: 14px;
  color: #000000;

  > div {
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    &:nth-child(1) {
      width: 33.2%;
      padding-left: 20px;
    }
    &:nth-child(3) {
      width: 290px;
    }
    &:nth-child(4) {
      display: flex;
      justify-content: end;
      > div {
        &:nth-child(1) {
          button {
            background-color: white;
          }
        }
      }
    }
  }

  &:hover {
    background-color: #ffffff;
  }
}
