.modal {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 50px 20px 50px;
}

.header {
  text-align: center;
}

.infoText {
  text-align: center;
}

.button {
  width: 220px;
  height: 50px;
  background-color: #4957d8;
  border-radius: 25px;
  border: none;
  display: block;
  margin: 10px auto;
  color: #fff;
  font-size: 16px;
}

.spinnerBlock {
  width: 100px;
  margin: 0 auto;
}
