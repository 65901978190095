.content {
  margin: 0 auto;
  width: 400px;
  text-align: center;

  .header {
    padding-top: 80px;
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
  }

  .spinnerBlock {
    width: 300px;
    margin: 0 auto;
  }
}
