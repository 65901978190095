.container {
  display: flex;

  .textContainer {
    width: 100%;
    min-height: 100px;

    .textArea {
      color: #ffffff !important;
      border: none !important;
      resize: none;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: flex-end;

    .arrow {
      transform: rotate(-90deg);
      position: relative;
      top: 3px;
    }
  }

  .loadingBar {
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
    border-radius: 4px;
    width: 100%;

    .cancelButton {
      color: #fff;
      text-wrap: nowrap;
      border: none;
      background-color: #000;
      border-radius: 4px;
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: #3e3e3e;
      }
    }

    .progressBar {
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background-color: #5a5a5a;
      overflow: hidden;
    }

    .progressPercent {
      background-color: #4957d8;
      height: 100%;
    }
  }
}
