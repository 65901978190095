@keyframes pulseAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.booksContentRight {
  padding: 0px;
  width: 100%;
  padding-left: 280px;
  transition: all;
  transition-duration: 0.5s;
  overflow: auto;
}

.booksContent {
  padding: 0px;
  width: 100%;
  transition: all;
  transition-duration: 0.5s;
}
.booksPage {
  height: 100vh;
  height: 100dvh;
  background-color: #f0f0f0;
}

.booksTitle {
  text-align: center;
  padding-top: 15px;
}

.pageBooksNavigationWrapper {
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(0%);
  transition: all;
  transition-duration: 0.5s;
  width: 280px;
  z-index: 10;
}
.moveLeft {
  transform: translateX(-300px);
}
.sidebarToggleBtnBlock {
  cursor: pointer;
  position: absolute;
  right: -60px;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: #000000;
  display: none;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 1000;

  img {
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 1000px) {
    display: flex;
  }
}

.loadingCards {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  max-width: 1180px;
  margin: 0 auto;
  padding: 10px 30px 0;
}

.cards {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 30px;
  max-width: 1180px;
  margin: 0 auto;

  .booksContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  .booksTitle {
    margin-bottom: 0px;
    top: 50px;
  }

  .headerCart {
    position: relative;
    padding: 0 20px;
    top: 10px;
    margin-bottom: 10px;

    .searchBlock {
      display: flex;
      border: 1px solid #a0a0a0;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      position: absolute;
      right: 20px;
      top: -49px;

      &:hover {
        border-color: #4957d8;
      }

      &:focus-within {
        border-color: #4957d8;
      }

      .input {
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        margin-left: 10px;
      }
    }

    .searchButton {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .searchOpen {
      width: 280px;
    }
  }

  @media (max-width: 1000px) {
    .booksTitle {
      margin-top: 30px;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #d0d0d0;
  }
  .searchResultBlock {
    display: flex;
    margin: 15px 0 10px;

    .searchResult {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #a0a0a0;
      width: fit-content;
      min-height: 30px;
      padding-left: 10px;
      padding: 5px 12px;
      border-radius: 4px;

      .close {
        margin-left: 8px;
        margin-right: -5px;
        cursor: pointer;
        fill: black;
        &:hover {
          fill: #a0a0a0;
        }
      }
    }

    .result {
      color: #a0a0a0;
      display: flex;
      align-items: center;
      margin: auto 10px;
      width: fit-content;
    }
  }
}

.card {
  display: inline-block;
  margin: 18px 20px;
  width: 280px;
  height: 402px;
  background: #fafafa;
  border-radius: 2px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  &:hover {
    .shadowBlock {
      opacity: 0.25;
    }
  }
}

.bookShadow {
  position: absolute;
  height: 100%;
  left: 0;
}

.cardPlusIcon {
  position: absolute;
  top: 100px;
  left: calc(140px - 75px);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  z-index: 2;
}

.cardPlusText {
  position: absolute;
  width: 100%;
  top: 300px;
  font-size: 20px;
  text-align: center;
  line-height: 140%;
  color: #a0a0a0;
}

.shadowBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  border-radius: 2px;
  z-index: 1;
}

.cardsContainer {
}

.freeCreditsBlock {
  display: none;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media (max-width: 1280px) {
  .card {
    width: 210px;
    height: 315px;
    margin: 9px 10px;
  }
  .cardPlusIcon {
    top: 50px;
    left: 30px;
  }
  .cardPlusText {
    font-size: 16px;
    top: 220px;
  }
}

@media (max-width: 768px) {
  .bookTitleHide {
    opacity: 0;
  }
  .searchBlockExtended {
    width: calc(100% - 40px);
  }
  .sidebarToggleBtnBlockHidden {
    right: -10px;
  }
}

@media (max-width: 650px) {
  .booksContainer {
    gap: 10px !important;
  }

  .card {
    width: 150px;
    height: 225px;
    margin: 5px 7px;
  }
  .cardPlusIcon {
    width: 76px;
    height: 76px;
    top: 40px;
    left: 37px;

    .bookPlus {
      width: 30px;
      height: 30px;
    }
  }
  .cardPlusText {
    font-size: 14px;
    top: 140px;
  }
  .searchBlockHidden {
    display: none;
    opacity: 0;
  }

  .freeCreditsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: black;
    z-index: 100;
    padding: 0 15px;

    .creditsInfo {
      display: flex;
      column-gap: 12px;
      font-size: 16px;
      color: #d0d0d0;
    }

    .freeCreditsButtonBlock {
      display: flex;
      column-gap: 10px;

      .plusButton {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        padding-left: 1px;
        max-width: 30px;
        max-height: 30px;
        background: #3e3e3e;
      }

      .creditsButton {
        display: flex;
        justify-content: center;
        background: #3e3e3e;
        color: #d0d0d0;
        max-width: 130px;
        max-height: 30px;
        font-size: 14;
      }
    }
  }

  .moveLeftFreeCreditsBlock {
    transform: translateX(300px);
    transition: transform 0.525s ease;
  }
  .moveRightFreeCreditsBlock {
    transform: translateX(0);
    transition: transform 0.525s ease;
  }
}

.sidebarOpen {
  right: -40px;
}
