.modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 31px;
  animation: fadein 0.33s;
  overflow-y: hidden;
}

.modalContent {
  display: flex;
  justify-content: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
