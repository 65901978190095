.betaLogo {
  position: relative;
  bottom: 18px;
}

.container {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.innerContainer {
  position: relative;
  bottom: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-top: 100px;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 300px;
  height: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.header {
  margin-top: 5px;
  max-width: 410px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
}
.header p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 15px;
}
.header p:last-child {
  margin-bottom: 0;
}
.passwordResetLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 20px;
}

.field1 {
  margin-top: 19px;
}

.field2 {
  margin-top: -8px;
}

.field3 {
  margin-top: -8px;
}

.field4 {
  margin-top: -8px;
}

.submitButton {
  margin-top: 2px;
}

.footer p {
  margin-top: 27px;
}

.link a {
  color: #6c68ff;
}

.link:hover a {
  color: #8960ff;
}

.link {
  margin-top: -12px;
}

.userInfo {
  display: flex;
  flex-direction: row;
}
