.option {
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3e3e3e;
  padding: 0 10px 0 30px;

  .label {
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  .input {
    width: fit-content;

    overflow: hidden;

    input {
      max-width: 260px;
    }
  }

  .editable {
    width: 100%;
  }

  &:hover {
    .icons {
      display: flex;
    }
  }

  .icons {
    display: none;
    align-items: center;
    gap: 10px;

    .close,
    .edit {
      path {
        fill: #a0a0a0;
      }
    }

    .edit {
      width: 16px;
      height: 16px;
    }

    .close {
      width: 13px;
      height: 13px;
    }
  }
}
