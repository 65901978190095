@import '../constants.scss';

.dropdown {
  position: relative;
  width: 100%;
  user-select: none;

  .selected {
    display: flex;
    align-items: center;
    width: 100%;
    border-style: solid;
    border-width: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 50px;

    .label {
      color: $grey-500;
    }

    .value {
      color: $black;
    }

    .openIcon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s ease;
    }

    &.error {
      border-color: $error !important;
    }

    &.light {
      color: $black;
      border-color: $grey-500;

      svg {
        fill: $black;
      }

      &:hover {
        border-color: $black;
      }
    }

    &.dark {
      color: #7d7c7c;
      background-color: #2c2c2c;
      border-color: #3e3e3e;

      svg {
        fill: $grey-400;
      }

      &:hover {
        color: #a0a0a0;
        background-color: #3e3e3e;
      }
    }

    &.border-one-line {
      border-bottom-width: 1px;
    }

    &.border-stroke {
      svg {
        right: 16px;
      }

      &.small {
        height: 30px;
        padding-right: 10px;
        padding-left: 10px;
        svg {
          right: 10px;
        }
      }

      &.medium {
        height: 40px;
        padding-right: 15px;
        padding-left: 15px;
      }
      &.large {
        height: 50px;
        padding-right: 15px;
        padding-left: 15px;
      }

      border-width: 1px;
      border-radius: 4px;
    }

    &.openState {
      border-color: $accent-500 !important;

      .openIcon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.optionsList {
  box-shadow: 0 7px 17px rgba(0, 0, 0, 0.08);
  display: none;
  border-radius: 4px;
  position: absolute;
  list-style: none;
  margin-top: 2px;
  margin-bottom: 0;
  cursor: pointer;
  z-index: 1001;
  padding: 0;
  overflow-y: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 5px;
    padding-left: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-700;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey-500;
  }

  .dropdownOption {
    height: 50px;
  }

  &.small {
    .dropdownOption {
      height: 30px;
      padding-left: 25px;
    }
  }

  &.light {
    background-color: $white;

    .dropdownOption {
      color: $black;

      &:hover {
        background-color: $grey-700;
      }
    }
  }

  &.dark {
    background-color: $grey-300;

    .dropdownOption {
      color: $grey-500;

      &:hover {
        color: $white;
        background-color: $grey-200;
      }
    }
  }

  &.open {
    display: block;
  }

  .dropdownOption {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 10px;

    font-size: 14px;
    font-weight: 500;
    line-height: 100%;

    .checkMarkIcon {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .optionSplit {
    height: 1px;
    width: 100%;
    background-color: $grey-600;
  }
}
