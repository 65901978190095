.container {
  width: 100%;
  height: 100%;
  max-height: 575px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  background: #d2d5d9;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #b8babf;
}

.buttonBlock {
  padding-top: 16px;
  margin-top: auto;
}

.buttonBlock button {
  margin-left: 310px;
}

.container .input:nth-of-type(2) {
  margin-top: 30px;
}

.input {
  margin-left: 40px;
}

.textArea {
  margin-left: 40px;
  margin-top: 30px;
}

.container .note:nth-of-type(2) {
  padding-top: 30px;
}

.note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  margin-left: 40px;
}

.note p {
  line-height: 17px;
}

.note a {
  height: 17px;
  margin-left: 6px;
  color: #6c68ff;
}

.note a:hover {
  color: #8960ff;
}
