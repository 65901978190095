.container {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  &:hover {
    color: #5a5a5a;

    .overlay {
      display: block;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    opacity: 0.15;
    display: none;
  }

  img {
    width: 140px;
    height: 210px;
    object-fit: cover;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      // box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.6);
    }
  }
}
