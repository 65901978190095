@import '../constants.scss';

.container {
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  &.light {
    color: $black;
    border-color: $grey-600;

    &:hover {
      border-color: $grey-500;
    }
  }

  &.dark {
    color: $grey-600;
    border-color: $grey-100;

    &:hover {
      border-color: $grey-500;
    }
  }

  &.selectable {
    cursor: pointer;

    &.selected {
      border-width: 2px;

      &.light {
        border-color: $accent-500;
      }

      &.dark {
        border-color: $white;
      }
    }
  }

  .metadata {
    display: flex;
    align-items: center;
    column-gap: 15px;
    font-size: 14px;
    pointer-events: none;

    .image {
      width: 50px;
      height: 50px;
      border-radius: 4px;
    }
  }

  .audioControls {
    display: flex;
    align-items: center;

    .loader {
      div {
        height: 1px;
      }
    }

    .dotsIcon {
      margin-left: 15px;
      margin-right: 19px;
      fill: $grey-600;
      cursor: pointer;

      &:hover {
        fill: $grey-100;
      }
    }

    .playButton {
      border-radius: 50%;
    }
  }
}
