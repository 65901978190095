.wrapper {
  text-align: center;
  font-size: 16px;

  .genresContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-width: 320px;
    margin-top: 15px;
    font-size: 14px;
  }

  .errorMessage {
    font-size: 12px;
    color: #ff4f4f;
    text-align: start;
    margin-top: 10px;
  }
}
