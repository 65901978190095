.root {
  width: 100%;

  input {
    border: none;
    border-bottom: 1px solid #a0a0a0;
    outline: none;
    padding: 15px 0;
    width: 100%;
    font-weight: 500;
    color: black;

    &:hover {
      border-bottom: 1px solid black;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a0a0a0;
      opacity: 1; /* Firefox */
    }

    &.error {
      border-bottom: 1px solid rgb(255, 69, 69);
      color: 1px solid rgb(255, 69, 69);
    }

    &:focus {
      border-bottom: 1px solid #4957d8;
    }
  }
}
