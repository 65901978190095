.screenContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modalContainer {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 500px;
  background: #f0f0f0;
  border-radius: 12px;
  padding: 40px;
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #5d5d5dde;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  user-select: none;
  border-radius: 12px;
  color: white;
  pointer-events: none;
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.5;
  }
}

.title {
  font-size: 30px;
  margin: 0;
}
.subTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5a5a5a;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 140%;
  margin-bottom: 30px;
}

.titleCurrentPlan {
  font-size: 18px;
  color: black;
  margin: 5px 0;
  display: flex;
  gap: 5px;
  align-items: center;
}
.upgrade {
  margin: 7px 0 15px 0;
  color: #5a5a5a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.hrRight,
.hrLeft {
  width: 465px;
  background: #c8cae5;
  height: 1px;
  border: none;
  top: 167px;
  position: absolute;
}
.hrLeft {
  left: 0px;
}
.hrRight {
  right: 0px;
}

.timeBlock {
  background: #f0f0f0;
  width: 309px;
  height: 30px;
  border-radius: 4px;
  padding: 0 11px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;

  .salePercent {
    font-size: 12px;
    position: absolute;
    font-weight: 100;
    line-height: 15px;
    right: 32px;
    top: -24px;
    color: #ffffff;
    background-color: #25bb73;
    padding: 4px 10px;
    border-radius: 15px 15px 0 0;
  }
  span {
    margin: 1px;
    cursor: pointer;
    text-align: center;
    width: 50%;
    padding: 2px;
    border-radius: 4px;
    &:hover {
      color: #5a5a5a;
    }
  }
  .active {
    color: white;
    background: #4957d8;
    transition: 0.3s ease-in-out;
    &:hover {
      color: white;
    }
  }
}
.plansContainer {
  display: flex;
  gap: 10px;
  max-height: 600px;
}
.bookSize {
  display: flex;
  justify-content: flex-start;
  width: 85%;
  gap: 144px;
  margin-bottom: 20px;
  & div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: fit-content;
  background: #fafafa;
  border-radius: 10px;
  padding: 22px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    scale: 1.05;
    background: #ffffff;
  }

  .planTitle {
    color: #4957d8;
    font-size: 14px;
  }
  .planSubTitle {
    color: #a0a0a0;
    font-size: 12px;
    margin-top: 5px;
  }
  .planName {
    font-size: 34px;
    span {
      font-size: 16px;
    }
  }
  .planCreditsGet {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    margin-bottom: 20px;
    span {
      color: #a0a0a0;
    }
  }
  .planDescription {
    font-size: 12px;
    color: #5a5a5a;
    margin: 20px 0 15px 0;
  }

  button {
    width: 200px;
    height: 40px;
    border-radius: 20px;
    background: #4957d8;
    border: none;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .planActive {
    background: transparent;
    border: 1px solid #e8e8e8;
    color: #3e3e3e;
    &:hover {
      background-color: transparent;
    }
  }

  .planButtonFree {
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid #a0a0a0;
    color: #3e3e3e;
    cursor: pointer;
  }

  .planFeatures {
    margin: 20px 0 15px 0;
  }

  .planButton {
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background: #4957d8;
    border: none;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #6674f4;
    }
  }

  .planCurent {
    &:hover {
      background: #ffffff;
      transform: scale(1.03);
    }
  }
}

.infoSvg {
  &:hover {
    opacity: 0.5;
  }
}

.contentBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratulationBlock {
  // height: 580px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  h3 {
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    text-align: center;
    padding-bottom: 20px;
  }
  span {
    font-size: 16px;
    padding-bottom: 20px;
    text-align: center;
    max-width: 440px;
  }
}

.secondaryButton {
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 15px;
  margin-top: 20px;
  border-radius: 4px;
  &:hover {
    background-color: #5a5a5a;
    color: white;
    text-decoration: unset;
  }
}

.successDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spiner {
  // height: 100%;
  padding: 20px;
  margin: auto 0;
}

// @media (max-height:850px) {
//   .modalContainer{
//     height: 100%;
//   }
//   .contentBlock{
//     height: 100%;
//   }

// }

.loadingContainer {
  text-align: center;
}
.free {
  margin: auto;
  background-size: cover;
  background-image: url('../../../Assets/images/FreeCreditBackground.jpg');
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: fit-content;
  border-radius: 10px;
  padding: 22px 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  gap: 20px;
  & svg {
    height: 40px;
    width: 40px;
  }
  &:hover {
    scale: 1.05;
  }
  .freeCover {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .freeText {
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      font-weight: 400;
    }
    .freeCredits {
      color: #ffe600;
      font-size: 30px;
      font-family: 'Inter', sans-serif;
      line-height: 120%;
      margin: 0;
    }
  }
  .hr {
    width: 74px;
    color: #ffffff;
    opacity: 0.2;
    margin: 0;
  }
  .freeContainer {
    display: flex;
    flex-direction: column;
    gap: 19.5px;
    align-items: center;
    .freeWorks {
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
    }
    .freeInf {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding: 0 0 0 20px;
      gap: 5px;
      margin: 0;
      .freeYellow {
        color: #ffe600;
      }
    }
    .freeButton {
      width: 230px;
      height: 40px;
      border-radius: 4px;
      background: #4957d8;
      border: none;
      color: white;
      cursor: pointer;
      &:hover {
        background-color: #6674f4;
      }
    }
  }
}

@media (max-width: 1240px) {
  .loadingContainer {
    position: fixed;
    top: 60px;
  }
  .bookSize {
    display: none;
  }
  .hrRight,
  .hrLeft {
    width: inherit;
    top: 131px;
  }
  .modalContainer {
    width: 100%;
  }

  .title {
    font-size: 20px;
  }

  .plansContainer {
    flex-direction: column;
    // overflow-y: auto;
    // overflow-x: hidden;
    width: 100%;
    max-height: unset;
  }
  .congratulationBlock {
    height: calc(100dvh - 30px);
  }

  .contentBlock {
    width: 100%;
  }
  .plan {
    margin: auto;
  }
  .close {
    position: fixed;
  }

  .screenContainer {
    position: absolute;
    height: unset;
  }
  .modalContainer {
    padding: 20px;
    border-radius: 0px;
  }
}
