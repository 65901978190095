.container {
  padding: 40px;
  width: 600px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    max-width: 380px;
    color: #000000;

    .title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .subtitle {
      font-size: 14px;
      text-align: center;
    }
  }

  .buttonsWrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}
