@import '../../constants.scss';

.textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  min-height: 42px;
  padding: 10px;
}

.lightMode {
  background-color: $white;
  color: $black;

  &::placeholder {
    color: $grey-500;
    opacity: 1;
  }

  &:hover {
    background-color: $grey-900;
  }

  &:focus {
    border: 1px solid $grey-600;
    background-color: $white;
    padding: 9px;
  }
}

.darkMode {
  background-color: #2c2c2c;
  color: #ffffff;

  &::placeholder {
    color: #5a5a5a;
    opacity: 1;
  }

  &:hover {
    background-color: $grey-200;
  }
}
