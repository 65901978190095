@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-350%);
    opacity: 0;
  }
}

.creditsContainer {
  display: flex;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: 0px;
  bottom: 100px;
  gap: 13px;
  z-index: 11;

  &::before {
    content: '';
    position: absolute;
    background-color: #3e3e3e;
    height: 1px;
    width: 184px;
    top: 0;
  }

  .creditsInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 180px;
    .credit {
      flex-grow: 1;
    }
    span {
      margin-right: 0px;
    }
    .addSvgBackground {
      position: relative;
      text-align: center;
      width: 30px;
      height: 30px;
      padding: 6px 5px 5px 6px;
      background-color: #3e3e3e;
      border-radius: 4px;
      fill: #d0d0d0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .freeCreditsButton {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    width: 180px;
    background: #3e3e3e;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    span {
      svg {
        margin-top: 6px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .creditsContainer {
    position: absolute;
    max-height: 60px;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    left: 0px;
    background: black;
    padding: 20px;
    z-index: 1000;

    &::before {
      display: none;
    }

    .creditsInfo {
      width: calc(100% - 130px);
    }

    .freeCreditsButton {
      width: 130px;
      height: 30px;
      font-size: 14px;
    }

    .addSvgBackground {
      display: flex;
      align-self: end;
      justify-self: end;
    }
  }

  .creditsContainerCloseNawbar {
    left: 300px;
    z-index: 1000;
  }
}

@media (min-width: 600px) {
  .creditsContainer {
    animation: fadeIn 1s ease-out forwards;
  }
  .creditsContainerHidden {
    animation: slideOutLeft 1s ease-out forwards;
  }
}
