.paginationContainer {
  max-width: 350px;
  margin: 20px auto;
  display: flex;
  column-gap: 8px;
  justify-content: center;

  .pageNumber,
  .pageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
    border-color: transparent;
    cursor: pointer;

    .arrow {
      fill: #d0d0d0;
    }
  }

  .pageButton {
    border-color: #d0d0d0;
    background-color: #000000;

    &:hover {
      border-color: black;
    }

    &.disableButton {
      background-color: transparent !important;
      cursor: default;

      &:hover {
        border-color: #d0d0d0;
        .arrow {
          fill: #d0d0d0;
        }
      }
    }
  }

  .currentPage {
    background-color: black;
    color: white;
    &:hover {
      background-color: black;
      .arrow {
        fill: white;
      }
    }
  }
}
