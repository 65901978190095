.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 420px;
  min-height: 400px;
  justify-content: center;

  .firstRow {
    display: flex;
    column-gap: 10px;

    .bookDropdown {
      flex-grow: 1;
    }

    .priceInput {
      width: 60px;
      height: 50px;
    }

    .currencyDropDown {
      width: 50px;
    }
  }

  .toggleSwitch {
    width: 300px;
    border: 1px solid #d0d0d0;
    margin: 0 auto 30px auto;
  }

  .stylePreview {
    align-self: center;
    width: 570px;
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
  }
}
