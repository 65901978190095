.logoThumbnailWrapper {
  width: 280px;
  height: 220px;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 7px 0 rgba(0, 0, 0, 0.19);

  &:hover {
    .overlay {
      animation: fadeInOverlay 0.5s;
      opacity: 0.5;
    }

    .cameraIcon {
      animation: fadeInCamera 1.2s;
      opacity: 1;
    }
  }

  .imagePlaceholder {
    background-color: #2c2c2c;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    row-gap: 16px;
    color: #7c7c7c;
    font-size: 15px;
    text-align: center;
    padding: 30px;

    svg {
      fill: currentColor;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #3e3e3e;
  }

  .cameraIcon {
    fill: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fadeInCamera {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
