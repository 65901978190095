.container {
  background-color: white;
  width: 220px;
  height: 300px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover,
  &.openedMenu {
    .dots {
      display: inline;
    }
  }

  .separator {
    height: 1px;
    margin: 0px 5px;
    background-color: #d9d9d9;
  }

  .dots {
    background-color: #fff;
    padding: 5px 7px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
  }

  &:hover {
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.2);
  }

  .thumbnail {
    height: 250px;
    overflow: hidden;

    .customImage {
      height: 100%;
      background-color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100px;
        height: 100px;

        path {
          fill: #d0d0d0;
        }
      }
    }
  }

  .thumbnailImage {
    width: 100%;
  }

  .thumbnailPlaceholder {
    background-color: rgb(210, 210, 210);
    height: 100%;
  }

  .label {
    align-content: center;
    text-align: center;
    margin: auto;
    flex-grow: 1;
    padding: 0px 10px;
  }

  .thumbnailLabel {
    background-color: rgb(149, 149, 149);
    left: 10px;
    bottom: 60px;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
  }
}
