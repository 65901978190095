.wrapper {
  top: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  position: fixed;
}
.allDomainPopupWrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
}
.confirmArchiveFunnelPopupWrapper {
  width: 100vw;
}
.addDomainWrapper {
  top: 0;
  left: 0;
  width: calc(100%);
  height: 100vh;
  position: fixed;
}
.removeDomainWrapper {
  top: 0;
  left: 0;
  z-index: 1;
  width: calc(100%);
  height: 100vh;
  position: fixed;
}
.сancelAccountPopupWrapper {
  top: 0;
  left: 0;
  z-index: 1;
  width: calc(100%);
  height: 100vh;
  position: fixed;
}
.modal {
  position: absolute;
  width: 600px;
  height: 450px;
  bottom: 200px;
  left: calc(50% - 300px);
  background: #fff;
  z-index: 4;
  padding: 10px;
  padding-right: 25px;
  padding-left: 25px;
  border: #777 1px solid;
  border-radius: 5px;
  text-align: center;

  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 700px) {
    top: 0px;
    left: calc(0);
    width: 100%;
    height: 100%;
    border: none;
    padding: 30px;
    align-items: center;
    padding-top: 200px;
    form {
      width: 100%;
    }
  }
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.worldIcon {
  width: 50px;
  height: 50px;
  margin: 30px auto 10px auto;
}

.text {
  margin: 10px auto;
  max-width: 290px;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}

.buttonsBlock {
  width: 480px;
  display: flex;
  margin: 20px auto 50px auto;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: space-between;
    justify-content: center;
    margin: 0 auto;
    gap: 20px;
  }
}
.addDomainInputBlock {
  div {
    input {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 700px) {
    position: absolute;
    top: 460px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 315px;
  }
}
.allDomainsPopupWithoutDomains {
  h2 {
    font-size: 26px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
  }
}
.allDomainsPopupWithoutDomainsBtn {
  margin-top: 70px;
  button {
    color: #000000;
  }
}
.headerText {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 140%;
  color: #000000;
  @media only screen and (max-width: 700px) {
    font-size: 26px;
    margin-bottom: 30px;
  }
}
.addDomainText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 320px;
  margin: 0 auto;
  flex-grow: 1;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.alreadyExistsText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 360px;
  margin: 0 auto;
  flex-grow: 1;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.addCnameText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 450px;
  margin: 0 auto;
  flex-grow: 1;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.notAtAllText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 464px;
  margin: 0 auto;
  flex-grow: 1;
  a {
    margin-left: 5px;
  }
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.deleteDomainText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 326px;
  margin: 0 auto;
  flex-grow: 1;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.cancelAccountPopupText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  max-width: 418px;
  margin: 0 auto;
  margin-top: 20px;
  flex-grow: 1;
  @media only screen and (max-width: 700px) {
    min-height: 170px;
    margin-bottom: 10px;
    flex-grow: 0;
  }
}
.domainHasBeenDeletePopup {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50px;
  right: -300px;
  width: 270px;
  height: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  animation-fill-mode: forwards;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  p {
    margin: 0;
    margin-left: -20px;
  }

  img {
    top: 10px;
    right: 10px;
  }
}

.domainHasBeenDeletePopup.show {
  right: 50px;
  opacity: 1;
}

.mailchimpIntegrationSuccesfullPopup {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  top: 50px;
  right: -100px;
  width: 270px;
  height: 110px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  animation-fill-mode: forwards;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  padding-left: 10px;

  p {
    margin: 0;
  }

  img {
    top: 10px;
    right: 10px;
  }
}

.mailchimpIntegrationSuccesfullPopup.show {
  right: 50px;
  opacity: 1;
}
.mailchimpIntegrationSuccesfullPopupMainText {
  font-size: 18px;
}
.wooHooPopupSelectFunnel {
  @media only screen and (max-width: 700px) {
    position: absolute;
    top: 460px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 300px;
  }
}
.addDomainInput {
  margin-bottom: 20px;
}
.allDomainsBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  max-height: 65%;
  overflow-y: auto;
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4957d8;
    border-radius: 20px;
  }
}
.allDomainsPopupHeaderText {
  align-items: center;
  font-style: normal;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
  margin-top: 15px;
}
.allDomainsPopupItem {
  min-height: 50px;
  width: 90%;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    border: 1px solid #4957d8;
    color: #4957d8;
    .hoverText {
      display: block;
    }
  }

  .hoverText {
    display: none;
    position: absolute;
    background: #f0f0f0;
    z-index: 10;
  }
}

.usedDomain {
  background-color: #f0f0f0;
  color: #666;
  cursor: unset;
}

.error {
  color: red;
}

.domainErrorPopup {
  background: #fff;
  padding: 20px;
  height: 100px;
  border: #000 1px solid;
  border-radius: 5px;

  p {
    margin: 0;
    text-align: center;
  }

  img {
    top: 10px;
    right: 10px;
  }
}
