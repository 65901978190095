.container {
  width: 400px;
  height: 300px;
  display: block;
  position: relative;
  top: 20px;

  &:hover {
    transform: scale(1.06);
    z-index: 1;
  }
  @media (max-width: 1210px) {
    width: 315px;
    height: 230px;
  }
}

.image {
  width: 400px;
  height: 266px;
  margin-top: -71px;
  position: relative;
  @media (max-width: 1210px) {
    width: 315px;
    height: 210px;
  }

  .aiBadge {
    position: absolute;
    top: 2px;
    left: 3px;
  }
}

.image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: auto;
  border-radius: 6px;
  transition: 4s linear;
  object-fit: contain;
  &:hover {
    transform: translateY(calc(-100% + 266px));
  }
  @media (max-width: 1210px) {
    width: 315px;
    height: auto;
  }
}
.imageCover {
  width: inherit;
  height: inherit;
  overflow: hidden;
  position: relative;
}
.templateDescription {
  visibility: hidden;
  min-height: 27px;
  width: 400px;
  background-color: #ffffff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  @media (max-width: 1210px) {
    width: 315px;
  }
}

.templateDescription p:nth-of-type(1) {
  width: 400px;
  padding-left: 20px;
  padding-top: 17px;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  color: #000000;
  @media (max-width: 1210px) {
    width: 275px;
    font-weight: 500;
    font-size: 18px;
  }
}

.templateDescription p:nth-of-type(2) {
  position: relative;
  top: -12px;
  margin-left: 20px;
  width: 400px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #5a5a5a;
  margin-bottom: 7px;
  @media (max-width: 1210px) {
    width: 275px;
    font-size: 14px;
  }
}

.withAIBadgeBorder {
  .imageCover {
    border: 4px solid #4957d8;
    border-radius: 6px;
  }
  .container:hover {
    border-bottom: none;
    border-radius: 6px 6px 0 0;
  }
  .templateBlock:hover,
  .container:hover {
    .templateDescription {
      border-bottom: 4px solid #4957d8;
      border-right: 4px solid #4957d8;
      border-left: 4px solid #4957d8;
    }
  }
}

.templateBlock {
  margin-top: -40px;
  position: relative;
  width: 400px;
  @media (max-width: 1210px) {
    width: 315px;
  }
}

.container:hover {
  .image {
    box-shadow: 0px 15px 20px rgba(42, 49, 57, 0.35);
    cursor: pointer;
  }
  .templateDescription {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    visibility: visible;
    box-shadow: 0px 15px 20px rgba(42, 49, 57, 0.35);
  }
}

.spinner {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.spinner img {
  display: block;
  position: relative;
  top: 47px;
}

.templateBlock:hover {
  .imageCover {
    border-radius: 6px 6px 0 0;
    border-bottom: none;
  }
}
