.modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 31px;
  animation: fadein 0.33s;
  overflow-y: hidden;
}

.modalContent {
  width: 400px;
  position: fixed;
  top: 100px;
  left: calc(50% - 200px);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.closeIcon {
  position: absolute;
  top: 105px;
  right: calc(50% - 193px);
  z-index: 11;
  cursor: pointer;
}
