.popup {
  display: flex;
  width: 100vw;
  left: 0;
  top: 0;
  background: rgba(31, 31, 31, 0.59);
  position: absolute;
  height: 100vh;
  align-items: center;
  z-index: 16;
  justify-content: center;

  .popupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 584px;
    border-radius: 4px;
    background: #f0f0f0;
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;

    .phoneInput {
      input:focus {
        border-color: #40a9ff;
        outline: none;
      }

      input::placeholder {
        color: #c7bfbf;
      }

      :global {
        .PhoneInputCountry {
          display: none;
        }
      }
    }

    .select {
      flex: 1;
      width: 100%;
    }

    .close {
      position: absolute;
      margin-left: 539px;
      cursor: pointer;

      & svg {
        width: 20px;
        height: 20px;

        & g path {
          fill: #a0a0a0;
        }
      }
    }

    h2 {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      margin-bottom: 29px;
    }

    p {
      width: 194px;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        padding-left: 15px;
        width: 322px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #a0a0a0;
        margin-bottom: 20px;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      button {
        width: 158px;
        border: none;
        height: 50px;
        color: #ffffff;
        border-radius: 4px;
        background: #000000;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}

.open {
  display: none;
}

.addTagButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: #000000;
}

@media only screen and (max-width: 768px) {
  .popup {
    .popupContent {
      width: 70vw;
      .close {
        margin-left: 62vw;
      }
      & form input {
        width: 50vw;
      }
    }
  }
}
