.WidthDropdownLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: #a0a0a0;
}

.selectedValue {
  color: #fff;
}
