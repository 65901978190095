.container {
  position: relative;

  .popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50px;
    width: 90%;
    width: 420px;
    background-color: #000000;
    border-radius: 4px;
    padding: 8px 10px;
    z-index: 10;

    .textAreaContent {
      background-color: #3a3a3a;
      position: relative;

      .textArea {
        color: #ffffff;
        border: none;
        outline: none;
        height: 140px;
        resize: none;
        flex: 1;
        padding: 10px;
        padding-right: 50px;
      }
      .submitButton {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: #292929;
        cursor: pointer;

        svg {
          transform: rotate(-90deg);
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .imagesContainer {
      margin-top: 10px;
    }
  }
}
