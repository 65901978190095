.https {
  position: absolute;
  top: 2px;
  transform: translate(0%, 50%);
  left: 16px;
}

.mech {
  position: absolute;
  top: 0;
  right: 0;
  height: 49px;
  padding: 19px 10px;
  background: #f7f8fa;
  display: flex;
  align-items: center;
}

.container {
  height: 78px;
  display: inline-block;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill {
    border-color: #5b74a04d;
  }
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
}

.input,
.inputSubdomain {
  height: 50px;
  border: none;
  border-bottom: 1px solid #ced2d9;
  box-sizing: border-box;
  background-color: transparent;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
}

.inputSubdomain {
  padding-left: 70px;
}

.input::placeholder,
.inputSubdomain::placeholder {
  color: #7b8695;
}

.input:focus,
.inputSubdomain:focus {
  outline: none;
}

.error {
  color: #ff5e5e;
  position: absolute;
  left: 0;
  font-size: 12px;
}

.whiteBackground {
  background: #fff;
  position: relative;
}

.errorInline {
  display: block;
  padding-left: 20px;
  color: #ff5e5e;
  font-size: 12px;
}
