.container {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  background-color: #fafafa;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #ffffff;
  }

  .infoContainer {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 30px;

    .image {
      height: 100%;
      width: 120px;
      min-width: 120px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .info {
      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .episodes {
        font-size: 14px;
        font-weight: 400;
        color: #a0a0a0;
      }
    }
  }
}
