.container {
  .tabsWrapper {
    .tabsHeader {
      margin-bottom: 10px;
    }
    .titleContainer {
      min-width: 160px;
      color: #ffffff;
    }
  }
}
