.addNewFormButton {
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 1;
  transform: translate(0px);
  transition: transform 0.3s ease-in-out;

  &.hiddenSidebar {
    visibility: hidden;
  }

  &.openSidebarButton {
    left: 10px;
  }

  &.settingsButton {
    right: 20px;

    .settingsIcon {
      path {
        fill: #ffffff;
      }
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  .sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: absolute;
    transform: translate(10px, 10px);
    z-index: 2;

    &.closed {
      transform: translate(-100px, 10px);
    }
  }
}

.aiNodesFlowEditorButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}
