.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .uploadImageInput {
    display: none;
  }

  .loader {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bannerContainer {
    height: 220px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .bannerCover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(40, 40, 40, 0.4);
      backdrop-filter: blur(60px);
      z-index: 1;
    }

    .bannerInfo {
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: 2;
      display: flex;
      gap: 30px;
      width: 100%;

      .podcastCover {
        border-radius: 4px;
        width: 220px;
        height: 220px;
        object-fit: cover;
        cursor: pointer;
      }

      .podcastInfo {
        margin: 0;
        padding: 0;

        .back {
          color: rgba(255, 255, 255, 0.5);
          font-size: 14px;
          margin-bottom: 30px;
        }

        .podcastName {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }

        .episodesAmount {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  .itemsContainer {
    max-width: 1180px;
    padding: 0 30px 30px;
    margin: 0 auto;

    .episodesBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .episodeSectionName {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .episodes {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
