.modalBlock {
  width: 840px;
  height: calc(100vh - 62px);
  background-color: #eef1f7;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  position: relative;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background-color: #cdcdcd87;
  }
}

.modalBody {
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 180px);
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  padding: 0 40px;

  .tagsContainer {
    display: flex;
    gap: 10px;

    .tags {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .tag {
        border: 1px solid #000;
        padding: 5px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        display: flex;
        gap: 5px;

        .removeTag {
          width: 10px;
          height: 10px;
          cursor: pointer;

          path {
            fill: #000;
          }
        }
      }
    }
  }
}

.modalBody::-webkit-scrollbar {
  width: 10px;
}

.modalBody::-webkit-scrollbar-track {
  background: #ffffff;
}

.modalBody::-webkit-scrollbar-thumb {
  background: #d2d5d9;
}

.modalBody::-webkit-scrollbar-thumb:hover {
  background: #b8babf;
}

.closeIcon {
  border: none;
  background-color: transparent;
  margin-left: 800px;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: url('../../../Assets/icons/close.svg');
}

.closeIcon:hover {
  background-image: url('../../../Assets/icons/closeHover.svg');
}

.modalTitle {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  padding-left: 0;
  margin-top: -7px;
}

.textArea {
  margin-top: 30px;
}

.buttonBlock {
  padding-top: 32px;
  margin-top: auto;
}

.buttonBlock button {
  margin: 0 auto;
}

.tagMenu {
  max-height: 320px;
  width: 212px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px 4px 0 0;
  .tagDisplay {
    height: 40px;
    color: var(--000000, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .disabled {
    color: #d0d0d0;
  }
}
.tagMenu::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.tagMenu::-webkit-scrollbar {
  background-color: #fafafa;
  width: 5px;
}

.tagMenu::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
}

.secondMenu {
  .popupInput {
    display: flex;

    & > div {
      width: 188px !important;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #a0a0a0;
      padding: 10px 0 10px 10px;
      display: flex;
      align-items: center;

      input {
        border: none;
        background: transparent;
        outline: none;
        width: 143px;
      }
      .inputButton {
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        border-radius: 0px 4px 4px 0px;
        background: #000000;
        display: flex;
        justify-content: center;
        transition: 0.3s ease-in-out;
        align-items: center;
        margin-left: 9px;
        border: none;
        &:hover {
          background: #3e3e3e;
        }
        & svg g g path {
          fill: #ffffff;
        }
      }
    }
  }
}

.addTag {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #000;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inputWrapper:focus-within {
  border-color: #6674f4 !important;
}
