.modalBody {
  background-color: #fafafa;
  border-radius: 4px;
  width: 600px;
  padding: 40px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .personIcon {
    margin-bottom: 20px;
  }

  .popupHeader {
    font-size: 24px;
    text-align: center;
  }

  .popupText {
    max-width: 320px;
    margin: 18px 0;
    font-weight: 400;
    font-size: 14px;
    color: #7d7c7c;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 380px;

    input {
      height: 50px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  .createButton {
    margin-top: 24px;
  }
}
