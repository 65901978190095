.container {
  position: relative;
  display: flex;
  flex-direction: column;

  .draggedBlock {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #4957d8;
    opacity: 0.4;
  }

  .variable {
    position: absolute;
    right: 2px;
    bottom: 2px;
    color: #4957d8;
    // background-color: rgba(123, 123, 123, 0.2);
    padding: 0px 8px 0px 8px;
    border-radius: 5px;
    // opacity: 0.4;
  }

  .contentContainer {
    outline: 1px solid transparent;

    &:hover,
    &.activeBlock {
      outline-color: #4957d8;
    }
  }
}
