.sizeInputCustomArrows {
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 2px;
  top: 10px;
  right: 5px;
  cursor: pointer;

  .arrowUp {
    transform: rotate(180deg);
  }
}
