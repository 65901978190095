.panel {
  position: absolute;
  left: 60px;
  top: 0;
  width: 250px;
  border-radius: 4px;
  z-index: inherit;
  background-color: #fff;

  .userAccountt {
    border-bottom: 1px solid #e8e8e8;
  }
}

.accountsList {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #000;

  .agency {
    max-height: 500px;
    overflow: auto;
  }
}

.account {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 6px 10px;

  span {
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.yImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #4957d8;
  border-radius: 50%;
}

.thumbnailBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  .activeAccount {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 187, 115, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0 !important;

      rect {
        fill: #ffffff;
      }
    }
  }
}

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.photoPlaceholder {
  width: 20px;
  height: 20px;
}

.manage {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  border-top: 1px solid #e8e8e8;

  .manageIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid#d0d0d0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.littleClocks {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #faa500;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 15px;
    height: 15px;
  }
}
