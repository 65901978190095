.calendar {
  width: 250px;
  position: absolute;
  z-index: 999;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 20px rgba(23, 40, 60, 0.13));
  border-radius: 4px;
  margin-top: 2px;
  height: fit-content;
  left: 0;
  bottom: 0;
  transform: translateY(105%);
}

.calendar th {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #7b8695;
  position: relative;
  top: -1px;
}

.calendar td {
  position: relative;
  top: -4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.calendar header {
  background: #f0f0f0;
  height: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: -2px;
}

.calendar header button,
.calendar header select {
  margin: 10px 10px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: none;
  border-radius: 4px;
  position: relative;
}

.calendar header button:hover {
  cursor: pointer;
}

header {
  border-radius: 4px 4px 0 0;
}

header:hover {
  cursor: auto;
}

.calendar .day.prev {
  color: #ced2d9;
}

.calendar .day.next {
  color: #ced2d9;
}

.calendar .day.prev:hover {
  color: #ffffff;
}

.calendar .day.next:hover {
  color: #ffffff;
}

header p {
  width: 110px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-top: 18px;
  margin-left: 2px;
  padding-right: 2px;
}

header button:nth-of-type(1) img {
  transform: rotate(90deg);
  position: relative;
  bottom: 2px;
}

header button:nth-of-type(2) img {
  transform: rotate(270deg);
  position: relative;
  bottom: 2px;
}

.calendar table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin: auto;
}

.calendar:hover {
  cursor: auto;
}

.calendar th {
  vertical-align: middle;
  text-align: center;
  height: 36px;
}

.calendar .day {
  position: relative;
  vertical-align: middle;
  text-align: center;
  border: 2px solid transparent;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  top: -6px;
}

.week {
  height: 30px;
}

.calendar .day:hover {
  cursor: pointer;
  background-color: rgba(108, 104, 255, 0.2);
  border-radius: 4px;
  color: #000000;
  width: 30px;
  height: 30px;
}

.calendar .day.selected {
  background: #6c68ff;
  border-radius: 4px;
  color: #ffffff;
  width: 30px;
  height: 30px;
}

.calendar .day.between {
  background: rgba(73, 87, 216, 0.2);
  border-radius: 4px;
  color: #000000;
  width: 30px;
  height: 30px;
}

.calendar .day.today {
  border: 1px solid #6c68ff;
  border-radius: 4px;
}
