.head {
  display: flex;
  justify-content: space-between;

  .headLeft {
    display: flex;
  }

  .headRight {
    display: flex;
    align-items: center;
    height: 40px;

    & > div > button:not(:first-child):not(:last-child) {
      display: none;
    }

    & > div div > button:not(:first-child):not(:last-child) {
      display: none;
    }

    & > div:nth-child(4) div > span {
      display: none;
    }

    & > div:nth-child(4) > span {
      display: none;
    }

    .total {
      color: #7d7c7c;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 8px;
      margin-right: 15px;
      align-items: center;

      .listPage {
        border-radius: 4px 4px 0px 0px;
        background: transparent;
        width: 120px;

        & span {
          height: 20px !important;
          border: none;

          & svg path {
            fill: #a0a0a0;
          }
        }

        & ul {
          width: 100px;
          margin-top: 10px;
          border: 1px solid #f0f0f0;
        }
      }

      .hr {
        width: 1px;
        height: 30px;
        background: #d0d0d0;
        margin-right: 15px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    .headRight {
      width: 100%;
      justify-content: flex-end;
      & div > div {
        display: none;
      }
      & > div:nth-child(4) div > span {
        display: none;
      }
      & > div > button:not(:first-child):not(:last-child) {
        display: block;
      }
      .hr,
      .totalColumn {
        display: none;
      }
      .total {
        margin: 0;
      }
      .total:nth-child(3) {
        display: none;
      }
    }
    .headLeft {
      width: 100%;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 768px) {
  .headRight {
    width: 100%;
    justify-content: flex-end;
    & div > div {
      display: none;
    }
    & > div:nth-child(4) div > span {
      display: none;
    }
    & > div > button:not(:first-child):not(:last-child) {
      display: block;
    }
    .hr,
    .totalColumn {
      display: none;
    }
    .addContacts {
      width: 40px;
      p {
        display: none;
      }
    }
    .total {
      margin: 0;
    }
    .total:nth-child(3) {
      display: none;
    }
    .smartCover {
      top: 160px;
    }
  }
}
