.productItem {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }

  .productTitleContainer {
    .productTitle {
      font-size: 16px;
      font-weight: 500;
    }

    .productSubtitle {
      font-size: 14px;
      color: #a0a0a0;
    }
  }

  .productInfoContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    .productTypes {
      display: flex;
      align-items: center;
      gap: 20px;

      svg {
        path {
          fill: #a0a0a0;
        }
      }
    }

    .price {
      font-size: 14px;

      .month {
        color: #a0a0a0;
      }
    }
  }
}
