.popupContainer {
  width: 600px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .warningIcon {
    margin-bottom: 30px;
    fill: #000000;
  }

  .popupTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .popupInfo {
    font-size: 14px;
    font-weight: 400;
    max-width: 320px;
  }

  .buttonsContainer {
    display: flex;
    column-gap: 20px;
    margin-top: 30px;

    .button {
      width: 150px;
    }
  }
}
