.container {
  position: relative;
  height: 100vh;
  overflow: auto;
  background-color: #f0f0f0;

  .contentContainer {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px;
  }

  .loader {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
  }
}
