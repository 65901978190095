.betaLogo {
  position: relative;
  bottom: 18px;
}

.container {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.logoContainer {
  margin: 50px auto;
  text-align: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innerContainer {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;

  &.fullContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-height: 800px) {
  .innerContainer {
    height: 100%;
  }
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  font-style: normal;
  margin-top: 5px;
  max-width: 410px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
}

.header h3 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}

.header p {
  font-style: normal;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 15px;
}

.field1 {
  margin-right: 10px;
  margin-top: 21px;
}

.field2 {
  margin-left: 10px;
  margin-top: 21px;
}

.field3 {
  margin-top: -8px;
}

.field4 {
  margin-top: -8px;
}
@media (max-width: 600px) {
  .field4 {
    display: none;
  }
}

.field5 {
  margin-top: -8px;
}

.field6 {
  margin-top: -8px;
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 40px;
}

.link a {
  color: #6c68ff;
}

.link:hover a {
  color: #8960ff;
}

.footer .logInBlock {
  font-size: 16px;
  font-style: normal;
}

.link {
  margin-top: -12px;
}

.userInfo {
  display: flex;
  flex-direction: row;
}

.additionalInfo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  color: #a0a0a0;
  p {
    font-size: 12px;
    margin-top: -12px;
    span {
      text-decoration: underline;
    }
  }
}

.validatePasswordContainer {
  position: absolute;
  right: -300px;
  top: 270px;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #a0a0a0;
  font-size: 16px;
  p {
    margin-bottom: 6px;
  }
  .valiedateOptions {
    display: flex;
    flex-direction: column;
    align-items: start;
    div {
      display: flex;
      margin-top: 6px;
      gap: 8px;
    }
  }
}

.iconForValidate {
  margin-top: 2px;
}

@media (max-width: 820px) {
  .validatePasswordContainer {
    margin-top: -5px;
    margin-right: -15px;
    margin-bottom: 20px;
    position: static;
    width: 100%;
  }

  .valiedateOptions {
    display: flex;
    flex-wrap: wrap;
    max-height: 80px;
    div {
      display: flex;
      width: 180px;
      flex-wrap: wrap;
    }
  }
}
