@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.startVideoBlockWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 20px;
  cursor: pointer;
}

.startVideoBlockWrapper.fadeIn {
  animation: fadeIn 1s ease;
}

.startVideoBlockWrapper.fadeOut {
  animation: fadeOut 1s ease;
}
