.container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-content: center;
  background-color: #000000;
}

.thumbnails {
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;

  scrollbar-width: 10px;

  @media (max-width: 600px) {
    display: none;
  }
}

.thumbnails::-webkit-scrollbar {
  width: 10px;
}

.thumbnails::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.thumbnails::-webkit-scrollbar-thumb {
  background: #5a5a5a;
  border-radius: 2px;
}

.thumbnails::-webkit-scrollbar-thumb:hover {
  background: #818181;
}

.thumbnail {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 4px;
  }

  &:last-child {
    margin-bottom: 4px;
  }
}

.colorScheme {
  display: flex;
  padding: 10px;
  padding-top: 20px;
  justify-content: center;
  column-gap: 10px;

  @media (min-width: 600px) {
    overflow: auto;
    justify-content: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.note {
  color: #7d7c7c;
  font-size: 12px;
  text-align: center;
}

.selectedFrame {
  position: absolute;
  border: 2px solid #4957d8;
  border-radius: 8px;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
}

.loadingContainer {
  width: 400px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loadingText {
  color: #d0d0d0;
  text-align: center;
  padding: 15px;
  margin-bottom: 10px;
}

.zoomFadeAnimate {
  animation-name: zoomFadeAnimateKeyframes;
  animation-duration: 1s;
  background-color: #7481fb;
  border: none;
  animation-fill-mode: forwards;
}

@keyframes zoomFadeAnimateKeyframes {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.1);
    opacity: 0;
  }
}
