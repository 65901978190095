.tabsList div:first-of-type > div {
  border-radius: 4px 0px 0px 4px;
}

.tabsList div:last-of-type > div {
  border-radius: 0px 4px 4px 0px;
}

.tabsList > div {
  margin-right: 2px;
}

.tabsList > div:last-of-type {
  margin-right: 0px;
}

.tabContent {
  width: 100%;
  height: calc(100% - 470px);
}

.tabsList {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
  margin: 0 auto;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 2px;
  }
  @media (max-width: 850px) {
    display: block;
    height: 210px;
  }
}
