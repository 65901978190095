.container {
  width: 100%;

  min-width: fit-content;

  .button {
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: #ffffff;
      }
    }

    &:hover {
      background-color: #3a3939;
    }
  }
}
