.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 420px;
  min-height: 400px;
  justify-content: center;

  .customToneInput {
    input {
      padding: 10px 0;
    }
  }

  .stylePreview {
    align-self: center;
    width: 570px;
  }
}
