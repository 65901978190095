@import '../constants.scss';

.radioButton {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .check {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border: 1px solid $grey-500;
    border-radius: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      display: none;
    }

    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $white;
    }
  }

  &:hover {
    input ~ .check {
      border-color: $grey-100;
    }

    input:checked ~ .check {
      background-color: $green-400;
    }
  }

  input:checked ~ .check {
    background-color: $green-500;
    border: none;
  }

  input:checked ~ .check:after {
    display: block;
  }
}
