.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    width: 220px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4957d8;
    overflow: hidden;

    .textEditor {
      width: 100%;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
