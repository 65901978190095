.modal {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 40px 50px 20px 50px;
  & svg {
    width: 35px;
    height: 35px;
  }
}
.mainImage {
  display: block;
  margin: 0 auto;
  & path {
    fill: #000000;
  }
}

.header {
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 26px;
}

.additionalText {
  color: #000000;
  text-align: center;
  font-size: 12px;
}

.buttonsBlock {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
