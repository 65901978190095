.domainsWrapper {
  height: 100%;
}
.domainsContent {
  position: relative;
  display: inline-block;
  width: calc(100% - 280px);
  background: #f0f0f0;
  height: 100vh;
  padding: 20px 40px;
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .domainsContentHeader {
    font-size: 34px;
    font-style: normal;
    line-height: 140%;
    color: #0a0303;
  }

  .addNewDomainBtnBlockMobile {
    display: none;
    width: 100%;

    button {
      width: 100%;
      max-width: 315px;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;

    .addNewDomainBtnBlock {
      display: none;
    }

    .domainsContentHeader {
      text-align: center;
    }

    .addNewDomainBtnBlockMobile {
      display: block;
    }
  }
}

.pageProjectsNavigation {
  display: inline-block;
  width: 280px;
  vertical-align: top;
}
.addFirstDomainBtnBlock {
  .addFirstDomainBtn {
    border-radius: 25px;
    font-size: 16px;
  }
}

.addNewDomainBtnBlockMobile {
  display: none;
  button {
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 900px) {
    display: block;
  }
}

.domainItemsBlockWrapper {
  height: 90%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 1px;
  }
}
.domainItemHeaderBlock {
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 40px;
  padding-right: 15px;
  white-space: nowrap;
  div {
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #a0a0a0;

    &:nth-child(1) {
      width: 33.2%;
      min-width: 215px;
      padding-left: 20px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 225px;
    }
    &:nth-child(3) {
      width: 290px;
    }
    &:nth-child(4) {
      width: 100%;
      max-width: 190px;
      display: flex;
      justify-content: end;
    }
  }
}

.addFirstDomainWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addFirstDomainContainer {
  width: 100%;
  max-width: 310px;
  max-height: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 40px;
  }
}

.domainsContentText {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 40px;
}
