.container {
  width: 100%;
  max-width: 600px;
  padding: 40px 20px;
  display: flex;
  justify-content: center;

  .modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    max-width: 380px;

    .header,
    .additionalText {
      margin: 0;
    }

    .additionalText {
      max-width: 200px;
      color: #7d7c7c;
      text-align: center;
    }

    .dropdown {
      margin: 35px 0 45px;
    }

    .buttonsBlock {
      display: flex;
      gap: 20px;
      margin-top: 10px;
    }

    .button {
      width: 180px !important;
    }
  }
}
