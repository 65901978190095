.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  width: 100%;
  padding-right: 30px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #ffffff;
  }

  .content {
    display: flex;
    gap: 30px;
    height: 100%;

    .icon {
      background-color: #e5f4ed;
      height: 100%;
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .topicInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 80%;

      .line {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 5px 0;

        .prompt {
          font-size: 14px;
          color: #a0a0a0;
        }

        .promptText {
          font-size: 14px;
          color: #000000;
        }

        .name {
          font-size: 20px;
          font-weight: 600;
        }

        .type {
          background-color: #e5f4ed;
          color: #25bb73;
          border-radius: 8px;
          padding: 2px 4px;
          font-size: 12px;
        }
      }
    }
  }
}
