.modalWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  width: 600px;
  height: 450px;
  z-index: 200;
  border-radius: 12px;
  background: #f0f0f0;
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.worldIcon {
  width: 50px;
  height: 50px;
  margin-bottom: 30px;
}

.headerText {
  font-size: 34px;
  line-height: 140%;
  margin-bottom: 30px;
}

.subHeaderText {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 60px;
}

.selectFunnelBlock {
  width: 100%;
  max-width: 500px;
  min-height: 50px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  border-bottom: 1px solid #a0a0a0;
  cursor: pointer;
  overflow: hidden;
}
.selectFunnelArrowSvg {
  fill: #000000;
}
.selectFunnelArrowSvgRotate {
  fill: #000000;
  transform: rotate(180deg);
}
.selectFunnelOptionsBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 500px;
  background-color: white;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  position: relative;
  left: 0;
  top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 250px;
  div {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid #d0d0d0;
  }
}
.selectFunnelOptionItem {
  min-height: 50px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    width: 16px;
    height: 16px;
  }
}

.buttonsBlock {
  display: flex;
  justify-content: space-between;
  width: 380px;
}
