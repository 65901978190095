/*  COLORS
========================================== */

$color: #758dfa;

/*  MIXINS
========================================== */

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin keyframes($keyframes) {
  @-webkit-keyframes #{$keyframes} {
    @content;
  }
  @-moz-keyframes #{$keyframes} {
    @content;
  }
  @-o-keyframes #{$keyframes} {
    @content;
  }
  @keyframes #{$keyframes} {
    @content;
  }
}

@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -o-animation: #{$animation};
  animation: #{$animation};
}

@mixin animation-delay($animation-delay) {
  -webkit-animation-delay: #{$animation-delay};
  -moz-animation-delay: #{$animation-delay};
  -o-animation-delay: #{$animation-delay};
  animation-delay: #{$animation-delay};
}

/*  RESET
========================================== */

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*  LOADER
========================================== */

.loader {
  padding-left: 30px;
  padding-right: 40px;
  display: flex;
  flex-flow: column;
  align-self: center;

  //   position: fixed;
  //   top: 50%;
  //   left: 50%;
  //   @include transform(translate(-50%, -50%));

  .line {
    width: 30px;
    height: 2px;
    margin-bottom: 12px;
    background-color: lighten($color, 15%);
    @include animation(expand 1s ease-in-out infinite);

    &:nth-child(2) {
      @include animation-delay(180ms);
    }

    &:nth-child(3) {
      @include animation-delay(360ms);
    }

    &:nth-child(4) {
      @include animation-delay(540ms);
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

/*  KEYFRAMES
========================================== */

@include keyframes(expand) {
  0% {
    @include transform(scale(1));
  }
  25% {
    @include transform(scale(2));
  }
}
