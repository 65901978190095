@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Ropa';
  src: url('./Assets/fonts/RopaSans-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Audiowide';
  src: url('./Assets/fonts/Audiowide-Regular.ttf') format('truetype');
  font-weight: normal;
}

hr {
  border: 1px solid darkgray;
  margin: 10px 0;
}

body {
  margin: 0;
  font-family: 'Inter', arial !important;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}
html,
body,
.App {
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  padding-left: 15px;
}
#nav-custom-icon {
  margin-right: 0px;
}
.button-container {
  display: none;
  position: absolute;
}
.ant-table-cell:hover .button-container {
  display: inline-block;
  padding-left: 5px;
  margin-top: -5px;
}
.ant-menu-item:hover .button-container {
  display: inline-block;
  right: 20px;
}

.custom .ant-dropdown-menu {
  border-radius: 8px;
  padding: 6px 0px;
}

.nav-custom-button .ant-btn-primary {
  font-size: 45px;
  background-color: #112e48;
  border-color: #1466b1;
}

/* text editor  */
.align-left-item div {
  text-align: left;
}

.align-center-item div {
  text-align: center;
}

.align-right-item div {
  text-align: right;
}
