.container {
  width: 100%;
  background-color: #fafafa;
  padding-left: 35px;
  padding-right: 30px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #ffffff;
  }

  .productData {
    flex-grow: 1;
    display: flex;
    align-items: center;
    column-gap: 30px;

    svg {
      fill: #4957d8;
    }

    .info {
      flex-grow: 1;

      .firstRow {
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-bottom: 12;
        font-size: 20px;
        color: #000000;

        .price {
          color: #a0a0a0;
        }
      }

      .description {
        font-size: 14px;
        color: #a0a0a0;
        font-weight: 300;
      }
    }
  }
}
