.container {
  height: 100%;
  background-color: #000000;
  color: #d0d0d0;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  flex: 1;
  overflow: auto;

  .musicTitle {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    color: #7d7c7c;
  }

  .musicContainer {
    padding: 50px 0;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: auto;

    .audioContainer {
      width: 100%;
      max-width: 500px;
      margin-top: 10px;
    }
  }
}
