.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;

  .urlInput {
    margin-bottom: 20px;
  }

  .radiusButtonsContainer {
    display: flex;
    align-items: center;
    gap: 2px;

    .radiusButtonBlock {
      flex: 1;
      background-color: #2c2c2c;
      padding: 15px 20px;
      cursor: pointer;

      &:hover {
        background-color: #3e3e3e;

        .radiusButton {
          background-color: #d0d0d0;
        }
      }

      &.activeRadiusButton {
        background-color: #252c68;

        .radiusButton {
          background-color: #4957d8;
        }
      }

      .radiusButton {
        height: 20px;
        width: 100%;
        background-color: #7d7c7c;
      }
    }
  }

  .sizeFieldWrapper {
    margin-right: 0px;
    height: 50px;

    .inputIcon {
      path {
        fill: #d0d0d0;
      }

      rect {
        fill: #d0d0d0;
      }
    }

    .sizePostfix {
      display: flex;
    }

    .inputContainerClassName {
      background-color: transparent !important;

      & > div {
        width: 80px;
      }

      div {
        background-color: transparent !important;

        input {
          text-align: end;
          width: fit-content;
        }
      }
    }

    .sizePostfix {
      display: flex;
    }

    .sizeField {
      border-radius: 0;
      padding-left: 10px;
    }

    .rightSizeField {
      border-radius: 0 5px 5px 0;
    }

    .px {
      color: #5a5a5a;
      position: relative;
    }

    .arrows {
      top: 8px;
      right: 5px;
      gap: 10px;
    }

    .rightSizeField {
      border-radius: 0 5px 5px 0;
    }
  }
}
