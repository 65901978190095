.blockWrapper {
  padding-top: 12px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      color: #000000;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 24px;
    }

    .subtitle {
      font-size: 14px;
      color: #7d7c7c;
      font-weight: 400;
    }
  }

  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

  .addFirstSenderWrapper {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .tipText {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  .sendersBlockWrapper {
    margin-top: 24px;
    &::-webkit-scrollbar {
      height: 1px;
    }

    .sendersHeaderBlock {
      display: flex;
      justify-content: space-between;
      height: 20px;
      align-items: center;
      margin-bottom: 15px;
      padding-right: 15px;
      white-space: nowrap;

      div {
        text-align: start;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #7d7c7c;
        display: flex;
        align-items: center;
        gap: 8px;

        &:nth-child(1) {
          width: 33.2%;
          min-width: 215px;
          padding-left: 20px;
        }

        &:nth-child(2) {
          min-width: 225px;
        }

        &:nth-child(3) {
          width: 290px;
        }

        &:nth-child(4) {
          width: 15px;
        }

        svg {
          width: 16px;
          height: 16px;

          path {
            fill: #7d7c7c;
          }
        }
      }
    }

    .sendersWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      max-height: 35vh;
      overflow: auto;
    }
  }
}
