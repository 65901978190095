.container {
  padding: 40px;
  padding-top: 45px;
  width: 600px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
    max-width: 380px;
    color: #000000;

    .title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .subtitle {
      font-size: 14px;
      text-align: center;
      color: #7d7c7c;
    }

    .formBlock {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      font-size: 14px;

      .domainInput {
        margin-top: 40px;
        height: 50px;
        width: 380px;
      }

      .tipMessage {
        font-size: 12px;
        color: #a0a0a0;
        font-weight: 400;
      }
    }
  }

  .buttonsWrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}
