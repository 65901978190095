.container {
  height: 100vh;
  background-color: #f0f0f0;

  .loadingCards {
    padding-top: 10px;
    padding-left: 280px;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    max-width: 1350px;
    margin: 0 auto;
  }

  .pageNavigationWrapper {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0%);
    transition: all;
    transition-duration: 0.5s;
    width: 280px;
    z-index: 1;

    &.moveLeft {
      transform: translateX(-280px);
    }

    .sidebarToggleBtnBlock {
      cursor: pointer;
      position: absolute;
      right: -40px;
      top: 50px;
      width: 40px;
      height: 40px;
      background-color: #000000;
      display: none;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      @media only screen and (max-width: 1000px) {
        display: flex;
      }
    }
  }

  .containerContent {
    height: 100%;
    transition-duration: 0.5s;
    overflow: auto;
    padding: 30px;

    &.contentRight {
      padding: 0px;
      width: 100%;
      padding-left: 280px;
      transition: all;
      transition-duration: 0.5s;
    }

    .overflowContainer {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
      padding: 30px;
      height: 100%;

      .generationTemplateElements {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, auto));
        justify-content: space-between;
        gap: 20px;
      }
    }
  }
}
