.mainContent {
  display: grid;
  grid-template-columns: 280px auto;
  height: 100vh;

  .pageContent {
    height: 100vh;
    overflow: auto;
    background-color: #e8e8e8;
    position: relative;
    padding-top: 0 !important;

    .contentContainer {
      max-width: 1180px;
      width: 100%;
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;

      .tabBar {
        position: initial;
      }

      .contentClassName {
        overflow: initial;
      }

      .customersContainer {
        margin-top: 20px;

        .customers {
          display: flex;
          align-items: center;
          column-gap: 10px;
          margin-bottom: 20px;
        }
      }
    }

    .bannerContainer {
      height: 220px;
      margin-bottom: 60px;
      position: relative;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;

      .banner {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(40, 40, 40, 0.4);
        backdrop-filter: blur(60px);
        z-index: 1;
      }

      .bannerInfo {
        position: relative;
        padding-left: 30px;
        top: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        z-index: 1;

        & > div:first-child {
          background-color: var(--side-bar-background);
          padding: 20px;
        }
      }

      .autosaasInfo {
        font-size: 24px;
        color: #ffffff;
        margin: 0;
        padding: 0;
        position: relative;
        bottom: 30px;

        .customers {
          font-size: 12px;
          opacity: 0.5;
        }

        .domainUrl {
          font-size: 14px;
          margin-top: 10px;

          .linkIcon {
            transform: rotate(-60deg);
            margin-right: 5px;

            path {
              fill: #ffffff;
              opacity: 0.5;
            }
          }
        }

        .title {
          .editableLabel {
            font-weight: 500;

            div {
              color: #ffffff;
            }

            input {
              min-width: 180px;
            }
          }
        }
      }
    }
  }
}
