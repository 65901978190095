.container {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  padding: 110px 0px;

  .card {
    min-width: 170px;
    width: 170px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.1);

    .textWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
      text-align: center;

      .title {
        margin-bottom: 6px;
        font-size: 14px;
        color: #000000;
      }

      .subtitle {
        font-size: 12px;
        color: #a0a0a0;
        font-weight: 400;
      }
    }

    .iconWrapper {
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 40px;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 10px;
      -webkit-box-shadow: 25px 0px 30px -13px rgba(0, 0, 0, 0.38);
      box-shadow: 25px 0px 30px -13px rgba(0, 0, 0, 0.38);

      svg {
        transform: rotate(180deg);
        fill: #a0a0a0;
      }
    }
  }
}
