.wrapper {
  width: 100%;
  max-width: 600px;

  .container {
    padding: 40px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 24px;
        margin-top: 20px;
      }

      .subtitle {
        font-size: 14px;
        margin-top: 15px;
        max-width: 250px;
        text-align: center;
        color: #7d7c7c;
      }

      .info {
        font-size: 12px;
        max-width: 250px;
        text-align: center;
        color: #7d7c7c;
        margin-top: 20px;
      }

      .submitButton {
        height: 40px !important;

        span {
          display: flex;
          align-items: center;
          gap: 8px;

          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .emailForm {
      .emailIcon {
        width: 60px;
        height: 50px;

        path {
          fill: #a0a0a0;
        }
      }

      .emailInput {
        max-width: 300px;
        margin-top: 18px;
        height: 40px;
      }

      .submitButton {
        margin-top: 60px;
      }
    }
  }

  .successForm {
    .successIcon {
      width: 50px;
      height: 50px;

      path {
        fill: #25bb73;
      }
    }

    .emailContainer {
      height: 70px;
      width: 100%;
      max-width: 380px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      margin: 18px 0 5px;
      font-size: 18px;
    }

    .submitButton {
      margin-top: 30px;
    }

    .backButton {
      margin-top: 45px;
    }
  }
}
