.sidebar {
  height: 100%;
}

.container {
  height: 100%;
  width: 300px;
  background-color: #000000;
  max-width: 80px;
  border-radius: 5px;
  padding-bottom: 12px;

  .itemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    cursor: grab;

    .itemText {
      margin: 0;
      color: #d0d0d0;
      font-size: 12px;
    }

    .itemImage {
      margin: 12px 0 14px;
    }
  }
}
