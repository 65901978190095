.toolbar {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;

  .unActiveButton {
    color: rgb(204, 204, 204);
  }

  .button {
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    color: #000000;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 4px;

    svg {
      width: 14px;
      path {
        fill: #000000;
      }
    }
  }

  .activeButton {
    color: #ffffff;
    background-color: #000000;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}

.textEditor {
  min-height: 300px !important;
  padding: 20px;
  overflow: hidden;

  p {
    margin: 0;

    span[data-slate-placeholder='true'] {
      top: 20px !important;
      font-style: italic;
      max-width: 96% !important;
    }
  }
}

.arrows {
  top: 4px !important;
}
