.wrapper {
  position: relative;
  color: #000000;
  user-select: none;

  .header {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 5px;
    min-width: 180px;
    background-color: #ffffff;
    border: 1px solid #a0a0a0;
    cursor: pointer;

    .openListArrow {
      transform: rotate(180deg);
    }

    span {
      display: flex;
      margin-right: 10px;
      width: 10px;
    }

    &.dark {
      color: #d0d0d0;
      background-color: #2c2c2c;
    }
  }

  .headerTitle {
    white-space: nowrap;
    overflow-x: clip;
    margin: 2px 10px 2px 7px;
  }

  .headerWithFont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .list {
    position: absolute;
    top: 35px;
    z-index: 100000;
    width: 230px;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-radius: 3px;
    box-shadow: 0 5px 20px -1px rgba(23, 40, 60, 0.3);
    background-color: #ffffff;
    text-align: left;
    -webkit-overflow-scrolling: touch;

    .scrollList {
      overflow-y: auto;
      max-height: 315px;
      padding: 5px 0;

      &::-webkit-scrollbar {
        width: 5px;
        background: #ffffff;

        box-shadow: 0 5px 20px -1px rgb(0, 0, 0);
      }

      &::-webkit-scrollbar-track {
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      .listItemCheck {
        margin: -2px 5px 5px 0px;
        height: 16px;
        width: 16px;
      }

      .listItemPreview {
        height: 30px;
      }

      .listItem {
        align-items: center;
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 1px 8px;
        height: 30px;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;

        &.noResult {
          font-weight: normal;
          cursor: default;
        }

        &:hover {
          background-color: #f0f0f0;

          & > span > svg > path {
            fill: #ffffff;
          }
        }
      }
    }

    .listSearchContainer {
      padding-left: 8px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dfdfdf;
    }

    .listSearchBar {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      border: none;
      font-size: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgb(200, 200, 200);
      }
    }

    &.searchable {
      overflow-y: hidden;
      padding: 0;

      .scrollList {
        max-height: calc(415px - 40px);
      }
    }
  }
}
