.aiImageToolbar {
  position: absolute;
  right: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 20px;

  .arrowsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #000000;

    .next {
      transform: rotate(180deg);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    background-color: #000000;

    .checkMark {
      rect {
        fill: #ffffff;
      }
    }
  }
}
