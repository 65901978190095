.container {
  padding: 40px;
  width: 670px;
  max-width: 90%;

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .title {
      color: #000000;
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }

    .formBlock {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      font-size: 14px;

      .asterisk {
        color: #ff4f4f;
      }

      & > div:last-child {
        height: 50px;
      }

      &.textareaBlock {
        position: relative;

        & > div:last-child {
          height: unset;
        }

        .charactersAmount {
          position: absolute;
          right: 0;
          top: 5px;
          color: #a0a0a0;
          font-size: 12px;
          font-weight: 300;

          .error {
            color: #ff4f4f;
          }
        }
      }
    }

    .secondRow {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;

      .priceInput {
        width: 265px;
        max-width: 100%;
      }

      .currencyDropdown {
        width: 100px;
      }
    }
  }

  .buttonsWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}
