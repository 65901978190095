.mainContent {
  .closeMenu {
    transform: translateX(-300px);
  }

  .menu {
    transition: all;
    transition-duration: 0.5s;
  }

  .content {
    display: grid;
    grid-template-columns: 280px auto;
    transition: all;
    transition-duration: 0.5s;
  }

  .sidebarClosed {
    grid-template-columns: 0 auto !important;
  }

  .sidebarToggleBtnBlock {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50px;
    width: 40px;
    height: 40px;
    background-color: #000000;
    display: none;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 1000;
    transition: all;
    transition-duration: 0.5s;

    img {
      width: 16px;
      height: 16px;
    }

    @media only screen and (max-width: 1000px) {
      display: flex;
    }
  }

  .sidebarOpen {
    left: 280px !important;
  }
}
