.Select {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  .back {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .selector {
    width: max-content;
    background-color: #fff;
    border: 1px #a0a0a0 solid;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    left: 15px;
    z-index: 2;
    max-height: 100px;
    overflow-y: auto;

    .item {
      padding: 5px 15px;
      cursor: pointer;
    }
  }

  .selected {
    width: 100%;
    height: 35px;
    border-bottom: 1px #a0a0a0 solid;
    padding: 10px;
    position: relative;
    cursor: pointer;
    font-size: 16px;

    > img {
      position: absolute;
      right: 0px;
      top: 15px;
      width: 12px;
    }

    .rotated {
      rotate: 180deg;
    }

    .prefix {
      color: #a0a0a0;
      display: inline-block;
      margin-right: 10px;
    }

    .disabled {
      color: #888;
    }
  }
}

.selectGroup {
  display: grid;
  grid-template-columns: 70px auto;
  align-items: top;
  margin-top: 10px;

  & > label {
    color: #a0a0a0;
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
  }
}

.promptSelectCluster {
  display: grid;
  grid-template-columns: 200px auto;
}

.closeButton {
  width: 30px;
  height: 30px;
  background-color: #fafafa;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
