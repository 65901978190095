.container {
  display: flex;
  height: 100dvh;
  min-height: 540px;
  width: 100%;
  position: relative;

  .leftContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .scrollContainer {
      overflow-y: auto;
      flex-grow: 1;

      .backButton {
        width: fit-content;
        font-weight: 400;
        margin: 30px 0 30px 30px;
      }

      .sections {
        flex-grow: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 40px 30px 30px;

        @media (max-width: 600px) {
          padding: 30px 10px;
          margin-top: 40px;
        }

        .label {
          color: #a0a0a0;
        }

        .title {
          font-size: 20px;
          padding-bottom: 25px;
        }

        .loadingContainer {
          margin: 10px 0;
          display: flex;
          align-items: center;
          column-gap: 15px;
          color: #a0a0a0;
          font-size: 14px;
          font-weight: 400;

          .loaderContainer {
            border: 1px solid #d0d0d0;
            border-radius: 6px;
            width: 40px;
            height: 40px;
            display: grid;
            place-items: center;

            div {
              div {
                height: 1px;
              }
            }
          }
        }

        .addButton {
          margin-top: 10px;
        }
      }
    }

    .buttonsContainer {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-top: 1px #fafafa solid;
    }
  }

  .cover {
    width: 50%;
    background-color: black;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;

    .colorScheme {
      display: flex;
      padding: 10px;
      padding-top: 20px;
      justify-content: center;
      column-gap: 10px;

      @media (max-width: 600px) {
        overflow: auto;
        justify-content: flex-start;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .thumbnails {
      overflow-y: auto;
      padding-left: 20px;
      padding-right: 20px;

      scrollbar-width: 10px;

      @media (max-width: 600px) {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #2c2c2c;
      }

      &::-webkit-scrollbar-thumb {
        background: #5a5a5a;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #818181;
      }

      .thumbnail {
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;

        &:first-child {
          margin-top: 4px;
        }

        &:last-child {
          margin-bottom: 4px;
        }
      }

      .selectedFrame {
        position: absolute;
        border: 2px solid #4957d8;
        border-radius: 8px;
        left: -4px;
        top: -4px;
        right: -4px;
        bottom: -4px;
      }

      .zoomFadeAnimate {
        animation-name: zoomFadeAnimateKeyframes;
        animation-duration: 1s;
        background-color: #7481fb;
        border: none;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes zoomFadeAnimateKeyframes {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.1);
    opacity: 0;
  }
}
