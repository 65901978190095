.pageWrapper {
  display: flex;
  overflow-x: hidden;
}

.projectContent {
  display: inline-block;
  width: calc(100% - 280px);
  transition: all;
  transition-duration: 0.5s;
  margin-left: 280px;
  min-width: 375px;
  background-color: #f0f0f0;
}

.pageProjectsNavigation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: inline-block;
  width: 280px;
  vertical-align: top;
  transform: translateX(0%);
  transition: all;
  transition-duration: 0.5s;
}
.sidebarToggleBtnBlock {
  cursor: pointer;
  position: absolute;
  right: -60px;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: #000000;
  display: none;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  @media only screen and (max-width: 1000px) {
    display: flex;
  }
}
.moveLeft {
  transform: translateX(-300px);
}
.contentMoveLeft {
  margin-left: 0px;
  width: 100%;
}
.mask {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: #ffffff;
}

.loading {
  margin-top: 6px;
  display: inline-block;
  width: 100%;
  height: calc(100% - 12px);
  animation: fadein 0.33s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 70px);
  position: absolute;
  color: #a0a0a0;
}

.spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.emptyProjectContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  animation: fadein 0.5s;
  // opacity: 0;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.emptyProjectText {
  width: 230px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #a0a0a0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
