.outerContainer {
  &:hover {
    cursor: default;
  }

  .container {
    display: flex;
    height: 120px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    margin-bottom: 4px;
    &:hover {
      .infoWrapper {
        background-color: #ffffff;
      }
      .funnelImageHoverTransparentBgc {
        display: block;
      }
    }
    .moveSvg {
      opacity: 0;
      margin-left: 10px;
    }

    &:hover {
      .moveSvg {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 1280px) {
      width: calc(100% + 5px);
    }

    .infoWrapper {
      flex-grow: 1;
      background-color: #fafafa;
      display: flex;
      height: 100%;
      border-radius: 4px;
      cursor: pointer;
      align-items: center;
      margin-left: 7px;
      border-bottom: 1px solid #e8e8e8;
    }
    .funnelPreview,
    .funnelPreviewSmall {
      position: relative;
      display: inline-block;
      vertical-align: top;
      min-width: 210px;
      width: 210px;
      height: 120px;
      min-height: 120px;
      object-fit: cover;
      transition: all;
      transition-duration: 0.5s;
      border-radius: 4px;
      &:hover {
        cursor: grab;
      }

      @media only screen and (max-width: 1280px) {
        min-width: 50px;
        width: 100%;
      }

      .funnelPreviewImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    .funnelPreviewSmall {
      min-width: 210px;
      width: 210px;
    }
    .funnelImageHoverTransparentBgc {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.3;
      background: #6674f4;
    }
    .funnelName {
      vertical-align: top;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      margin-left: 19px;
      position: relative;
      > div {
        @media only screen and (max-width: 900px) {
          input {
            text-align: start;
          }
        }

        input {
          text-overflow: unset;
        }
      }
    }
    .subheader {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #6c68ff;
      width: min-content;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 1500px) {
        // width: 210px !important;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .generatingProgressText {
      color: #a0a0a0;
      font-size: 14px;
      line-height: 15px;
      font-weight: 400;
    }

    .funnelSettings {
      display: flex;
      justify-content: center;
      width: 100px;
      height: 100%;

      p {
        margin-top: 11px;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ced2d9;
      }
    }
  }
}

.statistic {
  display: flex;
  height: 100%;
  width: 360px;
  div {
    flex-grow: 1;
    border-left: 1px solid #f0f0f0;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      position: absolute;
      left: 12px;
      top: 10px;
    }
    &:last-child {
      border-right: 1px solid #f0f0f0;
    }
  }
}

.dots {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
