.container {
  min-height: 100vh;
  position: relative;
  padding: 30px 30px 0 30px;

  @media only screen and (max-width: 900px) {
    padding-top: 30px;
  }
}

.statusButton {
  position: relative;
  bottom: 3px;
}

.icon {
  width: 20px;
  height: 20px;
}

.infoName {
  font-size: 20px;
  font-style: normal;
  line-height: 140%;
  color: #000000;
}

.infoData {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0;
}

.infoDataLink {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #6c68ff;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoDataLink:hover {
  color: #8960ff;
}

.funnel {
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}
.funnelTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.funnelTopLeft {
  flex-grow: 1;
  margin-right: 20px;
  @media only screen and (max-width: 900px) {
    margin-right: 0px;
  }

  div {
    input {
      margin-bottom: unset;
      padding-bottom: unset;
    }
  }
}
.funnelInfo {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 30px;
}

.funnelInfo .icon {
  margin-right: 10px;
  position: relative;
}

.funnelInfo .infoName {
  margin-right: 20px;
  margin-bottom: 0;
  min-width: 85px;
}

.funnelInfoUrl {
  overflow: auto;
  display: flex;
  align-items: center;
  height: 70px;
  position: relative;
  max-width: max-content;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 2px solid #e8e8e8;
  & .icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    position: relative;
  }
  & .infoName {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    min-width: 100px;
    color: #5a5a5a;
  }
}
.funnelInfoLeft {
  min-width: 260px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 15px;

  > div {
    position: relative;
    top: 2px;
    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
.disconnectBtn {
  display: inline-block;
  margin-left: 10px;
}
.buttonContainer {
  padding-top: 25px;
  padding-bottom: 25px;
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 220px;
  height: 70px;
  cursor: pointer;
  border-radius: 0 12px 0 0;
  & button:hover {
    background: #6674f4;
  }
}
.btnFunnelSettingsBlock {
  display: block;
  @media only screen and (max-width: 900px) {
    display: none;
  }
}
.btnNewPageBlock {
  display: block;
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.question {
  user-select: none;
  padding: 2px 6px 3px 6px;
  border-radius: 10px;
  background-color: #9d9d9d;
  color: white;
}

.headers {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: right;
  color: #ced2d9;
  margin-right: 120px;
}

.headers p {
  position: relative;
  top: 12px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ced2d9;
  width: 100px;
}

.archieveFunnelButton {
  padding: 10px 40px;
  margin-top: 10px;
  width: 260px;
  @media only screen and (max-width: 900px) {
    padding: 10px 30px;
  }
  > div {
    button {
      // background-color: transparent;
      border: 1px solid #000000;
    }
  }
}
.archiveAndDuplicateButtonsBlock {
  display: flex;
  gap: 20px;
  align-items: center;
}
.duplicateFunnelButton {
  width: fit-content;
  padding: 10px 0px;
  margin-top: 10px;
  > div {
    button {
      border: 1px solid #000000;
    }
  }
}
.loadingProjectDuplicateSpinner {
  padding: 10px 0px;
  margin-top: 10px;
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
}

.domainBlock {
  border-radius: 4px;
  background: #fafafa;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  margin-bottom: 15px;
  // overflow-x: auto;
  &::-webkit-scrollbar {
    height: 0px;
  }
}

.statsBlock {
  background-color: #fafafa;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  margin-bottom: 40px;
  @media only screen and (max-width: 1100px) {
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
}
.statsBlockTop {
  height: 70px;
  width: 100%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #e8e8e8;
  min-width: 770px;
}
.statsBlockBottom {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  min-width: 770px;
}
.statsBlockTopLeft {
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  z-index: 0;
}
.statsName {
  font-size: 22px;
  line-height: 140%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 100%;
}
.statsDecor {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.differencePercent {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  display: none;
  color: #a0a0a0;
  margin-left: 10px;
}
.differencePercentGreen {
  color: #25bb73;
  display: inline-block;
}
.differencePercentRed {
  color: #ff4f4f;
  display: inline-block;
}
.statsBlockItem {
  width: 33%;
  height: 100%;
  border-right: 2px solid #e8e8e8;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;

  &:last-child {
    border-right: none;
  }
}
.statsBlockItemQuantity {
  display: flex;
  align-items: center;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 10px;
  img {
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }
}
.statsLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #a0a0a0;
}
.pagesHeader {
  height: 70px;
  background-color: #fafafa;
  border-bottom: 3px solid #e8e8e8;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
}
.pagesHeaderLeft {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 30px;
  font-size: 22px;
  line-height: 140%;
}
.pagesImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
