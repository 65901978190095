.screenContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.stripeContainer,
.stripeContainerError {
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding: 13px 5px 13px 15px;
  margin-top: 5px;
  min-height: 50px;
}

.stripeContainerError {
  border-color: #ff4f4f;
}

.modalContainer {
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 670px;
  height: 550px;
  background: #fff;
  border-radius: 12px;
  padding: 40px 50px;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.5;
  }
}

.title {
  font-size: 30px;
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
}
.subTitle {
  width: 100%;
  font-size: 22px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.line {
  background: #d0d0d0;
  width: 100%;
  height: 1px;
}

.button,
.successButton {
  width: 250px;
  height: 50px;
  border-radius: 25px;
  background: #4957d8;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.userInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.fieldName,
.fieldSecondName,
.fieldEmail {
  display: flex;
  flex-direction: column;
}

.blockTitle {
  font-size: 16px;
  margin-bottom: 7px;
}

.submitButton {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 60px;
  text-align: center;
}

.congratulationBlock {
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  h3 {
    font-size: 30px;
    line-height: 30px;
    margin: 0;
  }
  span {
    font-size: 14px;
  }
}
.successButton {
  background: #000;
  width: 180px;
  margin-top: 10px;
}
.successDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameBlock {
  margin-bottom: 10px;
}

.inputContainer {
  position: relative;
}

.error {
  color: #ff4f4f;
  font-size: 12px;
  position: absolute;
}

.gratitude {
  font-size: 18px;
}

@media (max-width: 640px) {
  .modalContainer {
    width: 100%;
    height: unset;
  }
  .userInfo {
    flex-direction: column;
  }
}
