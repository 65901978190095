.modalBody {
  background-color: #f0f0f0;
  border-radius: 10px;
  width: 600px;
  padding: 50px;
}

.thumbnailHolder {
  background-color: #a0a0a0;
  width: 130px;
  height: 130px;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .thumbnail {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }

  .cameraIcon {
    position: absolute;
    top: calc(130px / 2 - 50px / 2);
    left: calc(130px / 2 - 50px / 2);
    fill: white;
  }
}

.inputBlock {
  position: relative;
  margin-top: 30px;

  input {
    width: 100%;
    border: none;
    line-height: 2;
    background-color: #f0f0f0;
    border-bottom: 2px #a0a0a0 solid;
    padding-left: 30px;
    outline: none;

    &.error {
      border-bottom-color: red;
    }

    &:focus {
      border-bottom: 2px #030303 solid;
    }
  }

  .icon {
    position: absolute;
    top: 7px;
    left: 5px;
  }
}

.textareaBlock {
  textarea {
    margin-top: 20px;
    width: 100%;
    resize: vertical;
    border: 2px #a0a0a0 solid;
    border-radius: 5px;
    min-height: 160px;
    background-color: #f0f0f0;
    padding: 5px 10px;

    &:focus {
      border-color: #030303;
    }
  }
}

.button {
  border: none;
  display: block;
  margin: 40px auto 0 auto;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  width: 180px;
  height: 50px;
  border-radius: 25px;
}

.personIcon {
  display: block;
  margin: 0 auto 20px auto;
}

.popupHeader {
  font-style: normal;
  font-size: 34px;
  text-align: center;
}

.popupText {
  width: 320px;
  margin: 20px auto 50px auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 2;
  text-align: center;
}

.errorBlock {
  height: 20px;
  font-size: 14px;
  color: red;
  line-height: 1;
}
