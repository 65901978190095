.addGroupPopup {
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .popupTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .popupSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #7d7c7c;
    max-width: 250px;
  }

  .form {
    text-align: start;
    margin-top: 15px;
    margin-bottom: 30px;
    width: 400px;

    .label {
      font-size: 14px;
      margin-bottom: 5px;
    }

    input {
      height: 50px;
    }
  }
}
