.container {
  padding: 40px;
  width: 600px;
  max-width: 90%;

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 325px;
    margin: 0 auto;

    .title {
      color: #000000;
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }

    .formBlock {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      font-size: 14px;

      .domainBlock {
        display: flex;
        border: 1px solid #a0a0a0;

        input {
          border-width: 0;
        }

        .selectedDomain {
          background-color: #e8e8e8;
          color: #a0a0a0;
          display: flex;
          align-items: center;
          padding: 0 14px;
        }
      }

      & > div:last-child {
        height: 50px;
      }

      .dropdownBlock {
        border: 1px solid #a0a0a0;
        border-radius: 4px;

        ul {
          li {
            &:first-child {
              border-bottom: 1px solid #e8e8e8;
            }
          }
        }

        .addOption,
        .disabledOption {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          padding-left: 30px;
          padding-right: 30px;
        }

        .disabledOption {
          color: #d0d0d0;
          cursor: default;
        }

        > span {
          border-width: 0;
          padding: 0 15px;

          svg {
            right: 10px;
          }
        }
      }
    }
  }

  .buttonsWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}
