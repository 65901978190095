.container {
  height: 100%;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}

.nodeCanvas {
  position: relative;
  background-color: #e8e8e8;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.generationPreview {
  width: 100%;
  position: relative;

  &.animatedOpenLayout {
    -webkit-animation: openFromRight 0.5s ease-in-out forwards;
    animation: openFromRight 0.5s ease-in-out forwards;
  }

  &.animatedLayout {
    -webkit-animation: closeFromRight 0.5s ease-in-out forwards;
    animation: closeFromRight 0.5s ease-in-out forwards;
  }
}

.workbench {
  display: flex;
  height: calc(100% - 60px);
  overflow: hidden;
}

.generationButton {
  right: 10px;
  bottom: 10px;
  z-index: 10;
  position: absolute;
}

.trapezoidButton {
  position: absolute;
  width: 15px;
  height: 60px;
  background-color: #fafafa;
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 50%;
  z-index: 10;
  transform: rotate(180deg) translate(0, 50%);
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: #ffffff;
  }
}

.hideLayoutButton {
  left: -15px;
}

.customHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrowIcon {
  width: 12px;
  height: 12px;

  &.leftArrow {
    transform: rotate(180deg);
  }
}

.hiddenButton {
  opacity: 0;
}

@keyframes closeFromRight {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

@keyframes openFromRight {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
