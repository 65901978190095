.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  width: 100%;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;

  .backButton {
    font-weight: 400;
    position: absolute;
    left: 40px;
    top: 40px;
  }
  .funnelTypeSelector {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20dvh;
  }
  .mainHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 8px;

    .title {
      font-size: 22px;
      color: #000000;
    }

    .subtitle {
      font-size: 16px;
      color: #a0a0a0;
      font-weight: 300;
    }
  }

  .funnelTypeCards {
    display: flex;
    column-gap: 20px;
    margin-top: 110px;
  }

  .funnelFormWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .formTitle {
        margin-top: 14px;
        font-size: 22px;
        color: #000000;
      }

      .formSubtitle {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 300;
        color: #a0a0a0;
      }
    }

    .formWrapper {
      width: 420px;
    }

    .navButtons {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      width: 100%;

      .rightIcon {
        transform: rotate(180deg);
      }
    }
  }
}
