.table {
  .tableHeaderWrapper {
    & > div {
      justify-content: flex-start;
      & > span:first-child {
        flex: unset;
      }
      & > span:nth-child(2) {
        & > span {
          :global {
            .active {
              color: #000000;
            }
          }
        }
      }
    }

    .tableHeader {
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: #7d7c7c;
      font-weight: 400;
    }
  }
}

.footer,
.pagination {
  ul {
    display: flex;

    li:last-child {
      order: -1;
      margin-left: 0;
      margin-right: 16px;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuItem {
  .checkbox {
    min-width: 20px;
    min-height: 20px;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #a0a0a0;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.header {
  margin-bottom: 20px;

  .searchContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .total {
      color: #a0a0a0;
      font-size: 14px;
    }

    .search {
      width: 220px;
      gap: 12px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      input {
        background: transparent;

        &:focus {
          background-color: #ffffff;
        }
      }
    }
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .avatar {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.option {
  max-width: 40px;
  margin: 0 auto;
}
