@mixin animate {
  overflow: hidden;
  background: #000;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #d9d9d9;
  background: linear-gradient(to right, #bfbfbf 2%, #ececec 18%, #bfbfbf 33%);
  background-size: 1300px;
}
.placeholderWrapper {
  width: 100%;
  padding-left: 60px;
  padding-right: 40px;
  padding-top: 50px;
  height: 100vh;
}
.placeholderItemWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.placeholderTopLine {
  height: 24px;
  max-width: 288px;
  width: 100%;
  border-radius: 16px;
  @include animate;
}
.placeholderTopRectangle {
  height: 142px;
  max-width: 100%;
  width: 100%;
  border-radius: 12px;
  @include animate;
}
.placeholderCenterRectangle {
  height: 192px;
  max-width: 100%;
  width: 100%;
  border-radius: 12px;
  @include animate;
}
.placeholderItemWithRectangleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.placeholderItemRectangle {
  height: 120px;
  max-width: 210px;
  width: 100%;
  border-radius: 12px;
  margin-right: 30px;
  @include animate;
}
.placeholderItemRightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.placeholderItemBigLine {
  height: 20px;
  max-width: 210px;
  width: 100%;
  border-radius: 13px;
  margin-bottom: 16px;
  @include animate;
}
.placeholderItemSmallLine {
  height: 16px;
  max-width: 83px;
  width: 100%;
  border-radius: 10px;
  @include animate;
}
@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
