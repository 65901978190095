@import '../constants.scss';

.nonBinarySwitchContainer {
  border-radius: 6px;
  background-color: $grey-900;
  display: flex;
  column-gap: 4px;
  padding: 2px;
  font-size: 14px;
  width: 100%;

  &:hover {
    background-color: $white;
  }

  .option {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $black;
    font-weight: 400;
    border-radius: 4px;
    user-select: none;
    position: relative;
    &:hover {
      color: $grey-100;
    }

    &.active {
      color: $white;
      background: $accent-500;
      &:hover {
        color: $white;
      }
    }
  }

  .hintText {
    font-size: 10px;
    position: absolute;
    line-height: 15px;
    top: -2px;
    transform: translateY(-100%);
    height: fit-content;
    color: $white;
    background-color: $green-500;
    padding: 2px 6px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
}

.binarySwitchContainer {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $grey-500;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;

    &:hover {
      background: $grey-600;
    }

    &::before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background: $green-500;

    &:hover {
      background: $green-400;
    }

    &::before {
      transform: translateX(20px);
    }
  }
}
