.sidebar {
  width: 290px;
  padding-top: 20px;
  background-color: #000000;
  height: calc(100dvh - 60px);
  display: flex;
  flex-direction: column;

  .elementsWrapper {
    overflow: auto;

    .loadingContainer {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      column-gap: 15px;
      color: #7d7c7c;
      font-size: 14px;
      font-weight: 400;

      .loaderContainer {
        border: 1px solid #5a5a5a;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;

        div {
          div {
            height: 1px;
          }
        }
      }
    }

    .addButton {
      margin-top: 10px;
      margin-left: 15px;

      &.round {
        border-radius: 50%;
      }

      svg {
        fill: #5a5a5a;
      }

      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
