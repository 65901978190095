.container {
  width: 100%;
  position: relative;
  padding-top: 67px;
  padding-bottom: 70px;
  top: -6px;
  overflow-y: auto;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1480px) {
    padding-left: 90px;
    padding-right: 90px;
  }
  @media (max-width: 1410px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1410px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  > div {
    &:first-child {
      display: none;
    }
  }
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  background: #d2d5d9;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #b8babf;
}
