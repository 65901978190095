.smartCover {
  padding: 20px;
  display: flex;
  position: absolute;
  top: 90px;
  right: 20px;
  z-index: 4;
  width: 315px;
  height: 270px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: space-between;

  & div > div {
    display: flex;
    gap: 10px;
  }

  & div div svg g path {
    fill: black;
  }

  .close {
    display: none;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;

    g {
      path {
        fill: #a0a0a0;
      }
    }
  }

  .filterText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .filterTopText {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-top: -7px;
    color: #000000;
  }

  .filterBottomtext {
    color: #a0a0a0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .hr {
    width: 315px;
    height: 1px;
    background: #e8e8e8;
    margin: 20px 0;
    margin-left: -20px;
  }

  & p {
    margin: 20px 0 15px 0;
    font-size: 14px;
    font-style: normal;
    color: #000000;
    display: flex;
    padding: 0;
    font-weight: 400;

    & span {
      margin: 0 0 0 4px;
      color: #ff4f4f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .filterInputTags,
  .filterInput {
    padding: 15px;
    width: 275px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #a0a0a0;
    outline: none;
    color: #000000;
  }

  .filterInputTags {
    position: absolute;
    top: 150px;
  }

  .filterInputTags:focus,
  .filterInput:focus {
    border: 1px solid #6674f4;
  }

  .filterBtns {
    display: flex;
    gap: 15px;

    & button {
      height: 40px;
    }
  }

  .whiteBtn {
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    border: 1px solid #a0a0a0;
    width: 130px;
    height: 40px;

    &:hover {
      background-color: #ffffff;
    }
  }

  .blackBtn {
    background-color: #000000;
    color: #ffffff;
    border-radius: 4px;
    width: 130px;
    height: 40px;

    &:hover {
      background-color: #000000;
    }
  }
}

.menuItem span {
  display: flex;
  align-items: center;
  gap: 12px;
  & .back {
    rotate: -90deg;
    & g g path {
      fill: #000000;
    }
  }
}

@media only screen and (max-width: 600px) {
  .close {
    display: block !important;
  }
}
