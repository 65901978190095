.container {
  height: 120px;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 30px;
  gap: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .image {
    flex: 1;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(232, 232, 232) 3%,
      rgb(255, 255, 255) 45%,
      rgb(232, 232, 232) 81%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    max-width: 220px;

    .funnelPreviewImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .logo {
      path {
        fill: #000000;
      }
    }
  }

  .info {
    flex: 1.3;
    max-width: 425px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .line {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;

      .name {
        font-size: 20px;
        font-weight: 600;
      }

      .url {
        color: #4957d8;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .stats {
    flex: 1.2;
    display: flex;
    justify-content: space-between;

    .stat {
      display: flex;
      flex-direction: column;
      justify-content: center;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: #a0a0a0;
        }
      }

      .statAmount {
        font-size: 14px;
        text-align: center;
      }

      .emptyStat {
        color: #a0a0a0;
      }
    }
  }

  .options {
    flex: 0.2;
    max-width: 40px;
  }

  &:hover {
    background-color: #ffffff;
  }
}
