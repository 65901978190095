.ColorSelectionBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #2c2c2c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #3e3e3e;

    .colorSelectorLabel {
      color: #fff;
    }
  }

  .colorSelectorLabel {
    color: #d0d0d0;
  }

  .colorPicker {
    width: 80px;
  }
}
