@import '../constants.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  .input {
    width: inherit;
    height: inherit;
    display: flex;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    background-color: transparent;

    &:focus {
      border-color: $accent-700 !important;
      outline: none;
    }

    &.hideArrows {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &.error {
      border-color: $error !important;
    }
  }

  .prefixIcon,
  .postfixIcon {
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
    line-height: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  .postfixIcon {
    right: 0;
    cursor: pointer;
  }

  &.border-stroke {
    .input {
      border: 1px solid;
      border-radius: 4px;
      padding-left: 15px;
      padding-right: 15px;

      &.addPrefixIcon {
        padding-left: 40px;
      }

      &.addPostfixIcon {
        padding-right: 40px;
      }
    }

    .prefixIcon {
      left: 15px;
    }

    .postfixIcon {
      right: 15px;
    }

    &.dark {
      &:focus-within {
        .prefixIcon {
          svg {
            fill: currentColor;
          }
        }
      }
    }
  }

  &.border-one-line {
    .input {
      border: none;
      border-bottom: 1px solid;
      border-radius: 0;

      &.addPrefixIcon {
        padding-left: 24px;
      }

      &.addPostfixIcon {
        padding-right: 24px;
      }
    }

    &:focus-within {
      .prefixIcon {
        svg {
          fill: currentColor;
        }
      }
    }
  }

  &.light {
    color: $black;

    .input {
      border-color: $grey-500;

      &:hover {
        border-color: $black;
      }

      &::placeholder {
        color: $grey-500;
      }
    }

    .prefixIcon {
      svg {
        fill: $grey-500;
      }
    }

    .postfixIcon {
      fill: $grey-500;

      &:hover {
        fill: currentColor;
      }
    }
  }

  &.dark {
    color: $white;

    .input {
      background-color: transparent;
      border-color: $grey-100;

      &:hover {
        border-color: $white;
      }

      &::placeholder {
        color: $grey-100;
      }
    }

    .prefixIcon {
      svg {
        fill: $grey-100;
      }
    }

    .postfixIcon {
      fill: $grey-100;

      &:hover {
        fill: currentColor;
      }
    }
  }

  .errorMessage {
    position: absolute;
    font-size: 12px;
    color: $error;
  }
}
