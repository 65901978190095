@import '../constants.scss';

.container {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  color: $grey-500;
  background-color: transparent;
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:hover {
    color: $white;
    background-color: $grey-300;
    cursor: pointer;

    .indicator {
      .defaultIcon {
        display: none;
      }

      .hoverIcon {
        display: block;
      }

      svg {
        fill: $grey-100;
      }
    }

    .moreIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .input {
    color: #ffffff;
  }

  &.selected {
    color: $white;
    background-color: $accent-300;

    .indicator {
      color: $white;
      border: none;
      background-color: $accent-500;

      svg {
        fill: $white;
      }

      &:hover {
        border: none;
        background-color: $accent-700;

        svg {
          fill: $accent-400;
        }
      }
    }

    .moreIcon {
      background-color: $accent-300;
    }
  }

  .indicator {
    min-width: 40px;
    min-height: 40px;
    display: grid;
    place-items: center;
    border: 1px solid $grey-100;
    border-radius: 6px;
    background-color: transparent;
    color: $grey-600;

    svg {
      fill: $grey-600;
    }

    .defaultIcon {
      display: block;
    }

    .hoverIcon {
      display: none;
    }

    &:hover {
      border: 1px solid $grey-400;
      color: $grey-400;

      svg {
        fill: $grey-400;
      }
    }
  }

  .rounded {
    border-radius: 50%;
  }

  .textContainer {
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .moreIcon {
    display: none;
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: $grey-300;

    svg {
      fill: $grey-500;

      &:hover {
        fill: $white;
      }
    }
  }
}

.tooltipContainer {
  .tooltipElement {
    color: $black;
    background-color: $white;
    padding: 12px;
    font-size: 14px;
    width: 190px;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;

    svg {
      fill: $grey-500;
    }

    &:hover {
      color: $grey-200;
      background-color: $grey-700;

      svg {
        fill: $grey-200;
      }
    }
  }
}
