.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;

  .faviconUpload {
    width: 100%;
    max-width: 760px;
    margin: 0 auto;

    .faviconUploadTitle {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .faviconPlaceholder {
        width: 16px;
        height: 16px;
        background-color: #d0d0d0;
      }

      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }

    .uploadButtonWrapper {
      display: flex;
      align-items: center;
      column-gap: 16px;

      .infoText {
        max-width: 200px;
        color: #a0a0a0;
        font-size: 12px;
        font-weight: 200;
      }
    }
  }
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  background: #d2d5d9;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #b8babf;
}

.buttonBlock {
  margin-top: auto;
}

.buttonBlock button {
  margin-left: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  margin-left: 40px;
}

.textArea {
  margin-left: 40px;
}

.note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  margin-left: 40px;
}

.note p {
  line-height: 17px;
}

.note a {
  height: 17px;
  margin-left: 6px;
  color: #6c68ff;
}

.note a:hover {
  color: #8960ff;
}
