.container {
  font-family: 'Inter';

  .statisticHeader {
    margin-bottom: 20px;
    @media only screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      align-items: center;
    }
  }
  .backToFunnelBtn {
    width: fit-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #a0a0a0;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    svg {
      margin-right: 10px;
      fill: currentColor;
    }

    &:hover {
      color: #000000;
    }
  }
  .projectName {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 140%;
    color: #000000;
    @media only screen and (max-width: 650px) {
      max-width: 250px;
      word-wrap: break-word;
    }
  }
  .pageNavigationBlockWrapper {
    position: relative;
  }
  .pageNavigationBlockUnderLine {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #d0d0d0;
  }
  .pageNavigationBlockUnderLineLeft {
    min-width: 40px;
    border-bottom: 2px solid #d0d0d0;
  }
  .pageNavigationBlock {
    height: 40px;
    margin-bottom: 30px;
    display: flex;
    gap: 5px;
    max-width: fit-content;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: #f0f0f0;
    position: relative;
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 4px;
      background-color: #f0f0f0;
    }

    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: #a0a0a0;
    }
  }
  .pageNavigationItem {
    height: 40px;
    min-width: 160px;
    max-width: 160px;
    white-space: nowrap;
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    border-bottom: 2px solid #d0d0d0;

    &:hover {
      border-bottom: 2px solid #5a5a5a;
    }
  }
  .pageNavigationItemActive {
    border-bottom: 2px solid #4957d8;

    &:hover {
      border-bottom: 2px solid #6674f4;
    }
  }

  .totalStatsWrapper {
    margin-bottom: 22px;
  }

  .textStatisticsInfo {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: #a0a0a0;
    }
  }
  .textStatisticsInfoBlock {
    width: 33%;
    min-width: 150px;
    height: 100px;
    border-radius: 5px;
    background: #fafafa;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);

    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 1300px) {
      align-items: center;
      height: 150px;
      padding-left: 0px;
    }
  }
  .datePickerBlockAndComparePreviousPeriodBlock {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    // flex-wrap: wrap;
    background-color: #fafafa;
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    min-width: 860px;
  }
  .comparePreviousPeriodBlock {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    padding-right: 30px;
    color: #a0a0a0;

    > div {
      margin-left: 13px;
      color: #5a5a5a;
    }
  }
  .textStatisticsInfoQuantityBlock {
    font-size: 26px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
      fill: currentColor;
    }
    @media only screen and (max-width: 1300px) {
      flex-direction: column;
      align-items: center;
      font-size: 22px;
      svg {
        margin-right: 0;
      }
    }
  }
  .textStatisticsInfoBlockLabel {
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: #a0a0a0;
    @media only screen and (max-width: 1300px) {
      text-align: center;
    }
  }

  .graphsBlockWrapper {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .graphsBlock {
    padding: 30px;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 40px;
    background-color: #fafafa;
    min-width: 860px;
    > div {
      width: 31.5%;
    }
    @media only screen and (max-width: 1500px) {
      > div {
        width: 100%;
        column-gap: 0;
      }
    }
  }
  .stateHeaderLeftBlock {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 20px;
  }
  .stateHeaderLabel {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 140%;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .statsGraphWrapper {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0px;
    }
  }
}
