.container {
  height: 78px;
  display: inline-block;
  position: relative;
}

.container input:-webkit-autofill {
  border-color: #5b74a04d;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 11px;
}

.input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #ced2d9;
  box-sizing: border-box;
  background-color: transparent;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
}

.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container input[type='number'] {
  -moz-appearance: textfield;
}

.input::placeholder {
  color: #7b8695;
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.error {
  color: #ff5e5e;
  position: absolute;
  left: 0;
  font-size: 12px;
}
