.container {
  max-width: 370px;
  background-color: #000000;
  border-radius: 4px;
  padding: 20px;

  .title {
    font-size: 14px;
    color: #7d7c7c;
    margin-bottom: 10px;
  }

  .paddingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2c2c;
    border-radius: 4px;
    margin-bottom: 1px;
    padding: 15px;

    .paddingTitle {
      color: #d0d0d0;
      font-size: 14px;
    }

    .paddingInputContainer {
      display: flex;
      gap: 5px;

      .paddingInput {
        width: 30px;
        height: 20px;
        background-color: #3e3e3e;
        border-width: 0;
        color: #d0d0d0;
        border-radius: 4px;

        input {
          border: none;
          padding: 5px;
        }
      }
    }
  }

  .marginContainer {
    background-color: #2c2c2c;
    padding: 15px;

    .vertical {
      flex-direction: column;
    }

    .marginInput {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .customInput {
        width: 20px;
        height: 20px;
        border-width: 0;
        background-color: transparent;
        color: #d0d0d0;

        input {
          border: none;
        }
      }

      .arrowLeft {
        transform: rotate(90deg);
      }

      .arrowTop {
        transform: rotate(180deg);
      }

      .arrowRight {
        transform: rotate(-90deg);
      }

      .arrow {
        width: 14px;
        height: 14px;
        cursor: pointer;

        rect {
          fill: #7d7c7c;
        }
      }
    }

    .horizontalMargin {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .image {
        flex: 1;
        background-color: #7d7c7c;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 10px;
      }
    }
  }
}
