.container {
  position: relative;
  background-color: rgb(51, 51, 51);
  overflow: hidden;
  border-radius: 4px;
  // filter: brightness(0.0);
  // animation: bookCoverFadeIn 0.5s ease-out;
  // animation-fill-mode: forwards;
}

.canvas {
  transform-origin: left top;
}

// @keyframes bookCoverFadeIn{
//   from{
//     filter: blur(6px) brightness(1.0);
//   }
//   to{
//     filter: blur(0px) brightness(1.0);
//   }
// }
