@import '../constants.scss';

.container {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  color: $black;
  background-color: transparent;
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:hover {
    color: $black;
    background-color: $grey-900;
    cursor: pointer;

    .indicator {
      .defaultIcon {
        display: none;
      }

      .hoverIcon {
        display: block;
      }

      svg {
        fill: $grey-600;
      }
    }

    .moreIcon {
      display: flex;
    }
  }

  &.selected {
    color: $white;
    background-color: $accent-300;

    .indicator {
      color: $white;
      border: none;
      background-color: $accent-500;

      svg {
        fill: $white;
      }

      &:hover {
        border: none;
        background-color: $accent-700;

        svg {
          fill: $accent-400;
        }
      }
    }

    .moreIcon {
      // background-color: $accent-300;
    }
  }

  .indicator {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border: 1px solid $grey-600;
    border-radius: 6px;
    background-color: transparent;
    color: $black;

    svg {
      fill: $black;
    }

    .defaultIcon {
      display: block;
    }

    .hoverIcon {
      display: none;
    }

    &:hover {
      border: 1px solid $grey-600;
      color: $grey-600;

      svg {
        fill: $grey-600;
      }
    }
  }

  .textContainer {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .moreIcon {
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    right: 10px;
    z-index: 1;
    background-color: $grey-900;

    svg {
      fill: $grey-600;

      &:hover {
        fill: $white;
      }
    }
  }
}

.notes {
  margin-left: 65px;
  display: none;
}
.notesVisible {
  display: block;
}
