.container {
  margin: 20px 0;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleText {
      .title {
        font-size: 18px;
        font-weight: 500;
      }

      .subtitle {
        color: #7d7c7c;
        font-size: 14px;
      }
    }

    .addButton {
      width: 300px;
    }
  }

  .stripePopup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    padding: 30px;

    .stripePopupTitle {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .stripePopupSubtitle {
      font-size: 14px;
      color: #a0a0a0;
      font-weight: 400;
      max-width: 300px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .products {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
