.scrollbar {
  overflow: auto;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #fafafa;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar {
  background-color: #fafafa;
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #d0d0d0;
}
