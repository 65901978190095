$black: #000;
$white: #fff;

$grey-100: #5a5a5a;
$grey-200: #3e3e3e;
$grey-300: #1f1f1f;
$grey-400: #7d7c7c;
$grey-500: #a0a0a0;
$grey-600: #d0d0d0;
$grey-700: #f0f0f0;
$grey-800: #d9d9d9;
$grey-900: #fafafa;

$accent-300: var(--accent-dark-accent);
$accent-400: var(--accent-dark-accent-hover);
$accent-500: var(--accent-accent);
$accent-700: var(--accent-accent-hover);

$green-400: var(--alerts-success-hover);
$green-500: var(--alerts-success);

$error: var(--alerts-error);
