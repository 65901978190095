.container {
  height: 100%;
  overflow: auto;

  .overflowContainer {
    width: 100%;
  }

  .contentContainer {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px;
    height: 100%;
  }

  .loader {
    max-width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
  }

  .emptyPodcasts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 30px;

    .podcastTitle {
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 22px;
    }

    .podcastDescription {
      text-align: center;
      color: #5a5a5a;
      font-size: 16px;
      margin: 0;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
