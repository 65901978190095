.person {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  .tagDisplay {
    display: flex;
    width: 100%;
    gap: 20px;

    & div {
      width: 20vw;
    }

    & div:nth-child(2) {
      width: 14vw;
    }
  }
}

.dots {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: absolute;
  z-index: 200;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  .popupInside {
    border-radius: 4px;
    width: 600px;
    height: 470px;
    background: var(--f-0-f-0-f-0, #f0f0f0);
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      text-align: center;
      margin-bottom: 40px;
    }
    .Btns {
      display: flex;
      gap: 20px;
      .blueBtn,
      .whiteBtn {
        position: unset;
        width: 180px !important;
        height: 40px !important;
      }
      .whiteBtn {
        background: #f0f0f0;
        border: 1px solid #000000;
        color: black;
        width: 130px;
        height: 40px;
      }
    }
    & p {
      color: #000000;
      text-align: center;
      margin: 20px;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }
    & button {
      border: none;
      position: relative;
      margin-top: 20px;
      left: 275px;
      & svg {
        width: 20px;
        height: 20px;
        & g path {
          fill: #a0a0a0;
        }
      }
    }
    .tag {
      width: 380px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 4px;
      display: flex;
      border: 1px solid #d0d0d0;
      align-items: center;
      margin-bottom: 20px;
      justify-content: center;
      & span {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
