.cardContent {
  background: transparent;
  position: relative;
  display: inline-block;
  width: 23.5%;
  height: 25dvw;
  max-height: 365px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  .imageLessCard {
    width: 100%;
    max-height: 365px;
    background: #fafafa;
    border-radius: 2px;
    position: relative;

    .bookShadowLessCard {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }

    > h5 {
      position: absolute;
      width: 100%;
      top: 300px;
      font-size: 20px;
      text-align: center;
      color: #a0a0a0;
    }

    > img {
      position: absolute;
      top: 125px;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
    }
  }

  @media (max-width: 1345px) {
    width: 31%;
    height: 32dvw;
    max-height: none;

    .imageLessCard {
      > h5 {
        font-size: 16px;
        top: 218px;
      }
      > img {
        top: 65px;
      }
    }
  }

  @media (max-width: 1200px) {
    height: 30dvw;
  }

  @media (max-width: 1000px) {
    height: 40dvw;
    width: 28dvw;
  }

  @media (max-width: 650px) {
    width: 41dvw;
    height: 60dvw;

    .overlay {
      opacity: 1;
    }
    .shadowBlock {
      opacity: 0;
    }
  }

  @media (max-width: 425px) {
    min-width: 85dvw;
    height: 122vw;
  }

  .bookShadow {
    position: absolute;
    height: 100%;
    left: 0;
    position: absolute;
  }

  .progress {
    position: absolute;
    bottom: 20px;
    left: 40px;
    background-color: #fff;
    width: 200px;
    height: 18px;
    border-radius: 3px;

    > span {
      display: inline-block;
      position: absolute;
      left: 80px;
      bottom: 20px;
      border-radius: 5px;
      width: max-content;
      background-color: #fff;
      padding: 2px 10px;
      text-align: center;
      color: #000;
      font-size: 12px;
    }
  }
}

.overlay {
  opacity: 0;
  transition: opacity 0.25s;
}

.hovered {
  opacity: 1;
}

.cardImage {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  vertical-align: unset;
}

.editIcon {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.dots {
  position: absolute;
  bottom: 10px;
  right: 8%;
  opacity: 1;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.cardDropList {
  position: absolute;
  top: 320px;
  left: 270px;
  width: 200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 2;
}

.cardDropItem {
  display: flex;
  justify-content: start;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 10px;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  > img {
    margin-right: 10px;
  }
}

.shadowBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #000;
  border-radius: 2px;
  z-index: 1;
}

.bookTitle {
  max-width: 280px;
  max-height: 30px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
