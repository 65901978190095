.selectDateRangeBlockWrapper {
  display: flex;
  width: 330px;
  align-items: center;
  position: relative;
  z-index: 10;
}
.selectDateRangeBlockSmallWrapper {
  display: flex;
  width: 100px;
  align-items: center;
  position: relative;

  > div:nth-child(2) {
    right: 0;
    left: auto;
  }
  .selectDateRangeBlock {
    width: 100px;
    background-color: #f0f0f0;
    margin-right: 0;
    padding-left: 0;
    font-size: 12px;
    height: 20px;
    .arrow {
      right: 10px;
    }
    svg {
      fill: #5a5a5a;
    }
  }

  .selectDateRangeItems {
    right: 0;
    left: auto;
  }
  .calendar {
    right: 0;
    left: auto;
  }
}
.selectDateRangeBlock {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  background: #fafafa;
  border-radius: 4px;
  margin-right: 10px;
  color: #5a5a5a;
  svg {
    fill: currentColor;
  }
  cursor: pointer;
}
.selectDateRangeBlockInfo {
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #a0a0a0;
}
.arrow {
  transition-duration: 0.3s;
  transition-property: transform;
}

.arrow {
  pointer-events: none;
}

.arrow.open {
  transform: rotate(180deg);
  pointer-events: auto;
}

.selectDateRangeItems {
  position: absolute;
  left: 0;
  top: 40px;
  border-radius: 4px;
  margin-top: 4px;
  width: 180px;
  div {
    width: 180px;
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-left: 28px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    background-color: #fff;

    &:hover {
      background-color: #f0f0f0;
    }

    svg {
      position: absolute;
      left: 6px;
      top: 12px;
      width: 16px;
      height: 16px;
    }
  }
}
.openDropDown {
  color: #6674f4;
  svg {
    fill: #6674f4;
  }
}
