.tagMenu {
  max-height: 320px;
  width: 212px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px 4px 0 0;

  .tagDisplay {
    height: 40px;
    color: var(--000000, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.tagMenu::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.tagMenu::-webkit-scrollbar {
  background-color: #fafafa;
  width: 5px;
}

.tagMenu::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
}

.secondMenu {
  .popupInput {
    display: flex;
    gap: 8px;
    .setupBtn,
    .addTag {
      width: 90px;
      border-radius: 4px;
      height: 30px;
      gap: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .addTag {
      background: #000000;
      color: #ffffff;
      padding: 0 19.5px;
      border: none;
      & svg g g path {
        fill: #ffffff;
      }
    }
    .setupBtn {
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: #000000;
      background: transparent;
      padding: 0 12.5px;
      & svg g path {
        fill: #000000;
      }
    }
    & > div {
      width: 188px !important;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #a0a0a0;
      padding: 10px 0 10px 10px;
      display: flex;
      align-items: center;

      input {
        border: none;
        background: transparent;
        outline: none;
        width: 143px;
      }
      .inputButton {
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        border-radius: 0px 4px 4px 0px;
        background: #000000;
        display: flex;
        justify-content: center;
        transition: 0.3s ease-in-out;
        align-items: center;
        margin-left: 9px;
        border: none;
        &:hover {
          background: #3e3e3e;
        }
        & svg g g path {
          fill: #ffffff;
        }
      }
    }
  }
}

.person {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  gap: 20px;

  .name {
    outline: none;
    border: none;
    min-width: 170px;
    max-width: 170px;
    min-height: 30px;
    border-bottom: transparent;
    transition: 0.1s ease-in-out;
    &:hover {
      border-bottom: 1px solid #d0d0d0;
    }
    &:focus {
      border-bottom: 1px solid #6674f4;
    }
  }
  .country,
  .phone {
    outline: none;
    border: none;
    min-width: 140px;
    max-width: 140px;
    min-height: 30px;
    border-bottom: transparent;
    transition: 0.1s ease-in-out;
    &:hover {
      border-bottom: 1px solid #d0d0d0;
    }
    &:focus {
      border-bottom: 1px solid #6674f4;
    }
  }
  .email {
    outline: none;
    border: none;
    min-width: 220px;
    max-width: 220px;
    min-height: 30px;
    border-bottom: transparent;
    transition: 0.1s ease-in-out;
    &:hover {
      border-bottom: 1px solid #d0d0d0;
    }
    &:focus {
      border-bottom: 1px solid #6674f4;
    }
  }
  .address {
    outline: none;
    border: none;
    max-width: 550px;
    min-width: 550px;
    min-height: 30px;
    border-bottom: transparent;
    transition: 0.1s ease-in-out;
    &:hover {
      border-bottom: 1px solid #d0d0d0;
    }
    &:focus {
      border-bottom: 1px solid #6674f4;
    }
  }
  .created {
    width: 140px;
    min-height: 30px;
  }

  .tagsCover {
    display: flex;
  }

  .tags {
    display: flex;
    width: max-content;

    .tag {
      min-width: fit-content;
      border-radius: 4px;
      background: #f0f0f0;
      padding: 10px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      gap: 5px;
      height: 30px;

      button {
        display: flex;
        align-items: center;
        border: none;
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .dropdown {
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    .blackPlus {
      width: 30px;
      height: 30px;
      padding: 7px;
      border-radius: 4px;
      cursor: pointer;
      background: #f0f0f0;
      & path {
        fill: black;
      }
    }
  }
}
input[type='checkbox']:checked {
  content: url('../../../Assets/icons/done.svg');
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
