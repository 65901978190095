.personTable {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  gap: 20px;

  & div,
  svg {
    cursor: pointer;
  }
  .tagName {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    width: 20vw;
    color: #7d7c7c;

    &.tagContacts {
      width: 14vw;
    }
  }
}

.points {
  display: flex;
  flex-direction: column;
  .rotated {
    rotate: 180deg;
  }
}
