.more {
  padding: 12px;
  border: 1px solid #a0a0a0;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    border-color: #000000;
  }

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: #000000;
    }
  }
}

.menu {
  li {
    min-width: 160px;
    padding: 10px 15px;

    path {
      fill: #000000;
    }
  }
}
