.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 420px;
  justify-content: center;

  .fieldContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    font-size: 14px;
    color: #000000;
    font-weight: 400;

    .input {
      height: 50px;
    }
  }
}
