.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    animation: 0.5s ease-in-out fadeInBackground;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    width: 100%;
    height: 450px;
    max-width: 600px;
    background-color: #f0f0f0;
    z-index: 101;
    border-radius: 10px;
    animation: 0.5s ease-in-out fadeInPopup;

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      fill: #a0a0a0;

      &:hover {
        fill: #000000;
      }
    }

    .mainContent {
      margin-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 30px;

      svg {
        fill: #000000;
      }

      .title {
        color: #000000;
        font-size: 34px;
        font-weight: 500;
        line-height: 34px;
      }

      .hintMessage {
        color: #2c2c2c;
        font-size: 14px;
        max-width: 300px;
      }

      .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;

        .loadingMessage {
          color: #a0a0a0;
          font-size: 14px;
        }
      }

      .exportContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;

        .downloadButton {
          width: 180px;
          height: 50px;
          color: #ffffff;
          font-size: 16px;
          background-color: #4957d8;
          border-radius: 25px;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: darken(#4957d8, 15%);
          }
        }

        .or {
          font-size: 12px;
          color: #a0a0a0;
        }

        .exportLink {
          font-size: 14px;
          color: #000000;
          max-width: 580px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .noteMessage {
      font-size: 12px;
      color: #a0a0a0;
    }
  }
}

@keyframes fadeInPopup {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInBackground {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.7);
  }
}
