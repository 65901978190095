.container {
  display: flex;
  border: 1px solid #5a5a5a;
  height: 38px;
  width: min-content;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: #a0a0a0;
  }

  &:active {
    border-color: #333333;
  }

  &.selected {
    border-width: 3px;
    padding: 7px;
    border-color: #4957d8;

    &:hover {
      border-color: #5d6dff;
    }
  }

  .color {
    width: 18px;
    height: 18px;

    &:first-child {
      border-radius: 3px 0px 0px 3px;
    }

    &:last-child {
      border-radius: 0px 3px 3px 0px;
    }
  }
}
