.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 580px;
  max-height: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.сancelAccountHeader {
  font-size: 34px;
  font-style: normal;
  line-height: 140%;
  padding: 0;
}
.balance {
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-bottom: 10px;
  .creditsCount {
    color: #25bb73;
    margin: 0 5px;
  }
  .zeroCreditsCount {
    color: #ff4f4f;
    margin: 0 5px;
  }
}

.planName {
  margin-bottom: 8px;
  font-size: 18px;
}
.creditsPerPlan {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 5px;
}
.info {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}
