.popup {
  width: 100%;
  max-width: 670px;
  max-height: 95vh;
  overflow: auto;

  .container {
    padding: 40px 40px 30px;

    .title {
      font-size: 24px;
      text-align: center;
      font-weight: 500;
    }

    .tabs {
      margin-top: 35px;
    }

    .tabBar {
      position: initial;
    }

    .contentClassName {
      overflow: initial;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      margin-top: 70px;
    }
  }
}
