.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: initial;
  text-align: right;
  align-items: center;
  z-index: 1003;

  &.darkContainer {
    background-color: #2c2c2c;
    padding-left: 10px;

    &:hover {
      background-color: #3e3e3e;

      div {
        background-color: #3e3e3e;
        color: #ffffff;
      }
    }
  }

  .inputContainer {
    height: 100%;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 15px;

    .title {
      color: #d0d0d0;
    }
  }

  .input {
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    width: 50px;
    height: 30px;

    .postfixIconClassName {
      right: 20px;
    }

    input {
      border-width: 0 !important;
      padding: 0;
      margin-right: 10px;
      padding-left: 5px;
      width: 35px !important;
    }

    &.dark {
      color: #d0d0d0;
      background-color: #2c2c2c;
      border-color: #3e3e3e;
      width: 70px;
      height: 100%;
      padding-left: 5px !important;

      input {
        border-bottom: none !important;
        padding-left: 0;
      }
    }
  }
}

.spacer {
  margin-left: 10px;
}
