@import '../constants.scss';

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  border: 1px solid $grey-500;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  color: $black;

  &:hover {
    border-color: $grey-100;
    color: $grey-200;
  }

  &.dark {
    border-color: $grey-100;

    &:hover {
      border-color: $white;
    }
  }

  &.selected {
    background-color: $accent-500;
    border: none;
    color: $white;

    &:hover {
      background-color: $accent-700;
    }
  }

  svg {
    fill: currentColor;
  }
}
