.popup {
  width: 100%;
  max-width: 600px;
  color: #000000;
  max-height: 95%;
  overflow: initial;

  .container {
    padding: 40px 40px 30px;

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin-bottom: 20px;
    }

    .inputContainer {
      margin: 10px 0;

      .inputLabel {
        margin-bottom: 5px;
      }

      .nameInputContainer {
        display: flex;
        gap: 20px;

        .nameInput {
          height: 50px;
          flex: 1;
        }
      }

      .textArea {
        min-height: 150px;
      }
    }

    .deleteContainer {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #5a5a5a;
      cursor: pointer;
      margin-top: 20px;

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #5a5a5a;
        }
      }
    }

    .buttonContainer {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
    }
  }
}

.optionsClassName {
  li {
    padding-left: 15px !important;
  }
}

.option {
  display: flex;
  gap: 15px;
  align-items: center;
  color: #000000;

  svg {
    path {
      fill: #000000;
    }
  }
}
