.container {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: 2px transparent solid;

  user-select: none;

  transition-property: box-shadow;
  transition-duration: 0.2s;

  &:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  }

  .variables {
    margin-top: 4px;
    margin-left: 15px;
    display: flex;
    color: #4957d8;

    div {
      color: #4957d8 !important;
    }
  }

  .operation {
    height: 70px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    column-gap: 10px;

    .operationLabel {
      white-space: nowrap;
    }

    .operationsDropdown {
      width: 130px;
    }

    border-bottom: 1px #d0d0d0 solid;
  }
}

.header {
  width: 100%;
  height: 50px;
  gap: 10px;
  border-radius: 4px 4px 0 0;
  background-color: #25bb73;
  color: white;
  user-select: none;
  display: flex;
  align-items: center;
  padding-left: 20px;

  svg {
    path {
      fill: white;
    }
  }
}

.content {
  width: 100%;
  // height: calc(100% - 30px);
  background-color: #fafafa;
  padding: 5px;
}

.resizeArea {
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.textEditor {
  overflow-y: auto;
  margin: 15px;
  margin-top: 0px;
  padding: 9px;
  height: calc(100% - 15px);
  border: 1px #a0a0a0 solid;
  border-radius: 5px;

  // &:focus {
  //   background-color: #f0f0f0;
  //   // user-select: auto;
  // }
}

.outputHandler {
  position: absolute;
  bottom: -11px;
  left: 14px;
  background-color: #a0a0a0;
  width: 22px;
  height: 22px;
  border-radius: 12px;
  display: flex;

  transition-property: transform;
  transition-duration: 0.2s;

  svg {
    fill: white;
    align-self: center;
    margin: auto;
  }

  &:hover {
    background-color: #6674f4;
  }

  &:active {
    background-color: #a0aaff;
  }
}

.inputHandler {
  position: absolute;
  top: 18px;
  left: -6px;
  background-color: white;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  border: 2px #a0a0a0 solid;
}

.footerArea{
  display: flex;
  justify-content: right;
  padding: 0px 20px 10px 0px;
}

.optionSelection{
  display: flex;
  gap: 10px;
  // size
  // padding: 0px 20px 10px 0px;
}

.menuButtonContainer {
  flex-grow: 1;
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.menuButton {
  // padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 30px;
  height: 30px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.menu {
  li {
    path {
      fill: #000000;
    }
  }
}
