.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 580px;
  max-height: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoPanel {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d0d0d0;
}

.contentHeader {
  font-size: 34px;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 40px;
}
.zapierLogo {
  width: 112px;
  height: 30px;
  margin-bottom: 20px;
}
.keyHeader {
  font-size: 22px;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 55px;
}
.keyBlock {
  font-size: 22px;
  font-style: normal;
  line-height: 140%;
  display: flex;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    fill: black;
    cursor: pointer;
  }
}
