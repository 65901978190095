.container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow: auto;
  max-height: 380px;
  width: 100%;
  max-width: 500px;
}

.errorMessage {
  font-size: 12px;
  color: #ff4f4f;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
}
