.container {
  padding: 30px;
  max-width: 1180px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;

  .statsSort {
    display: flex;
    align-items: center;
    gap: 6px;

    .statsTitle {
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
    }

    .datePicker {
      .dropDownContainer {
        width: fit-content;
        padding-right: 10px;
        border-right: 1px solid #d0d0d0;

        .dropdown {
          color: #000000 !important;
          white-space: nowrap;
          padding: 5px 10px !important;
          border-radius: 0;
          height: initial;
          justify-content: space-between;
          width: fit-content;

          &:hover {
            background-color: #ffffff;
          }
        }
      }

      div {
        font-size: 14px;
      }
    }
  }

  .loader {
    width: calc(100% - 60px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
  }

  .emptyProjectContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadein 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: both;

    .emptyProjectText {
      width: 230px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #a0a0a0;
    }

    button {
      text-transform: capitalize;
    }
  }

  .itemsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
