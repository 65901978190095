.container {
  max-height: calc(100vh - 30px);
  overflow: hidden;

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pageName {
        width: 130px;
        height: 25px;
      }

      .addButton {
        width: 220px;
        height: 40px;
      }

      .sort {
        width: 166px;
        height: 17px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .item {
      width: 100%;
      height: 60px;
    }
  }
}
