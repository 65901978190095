.main {
  width: 100%;
  border-radius: 4px;
  background-color: #fafafa;
  height: fit-content;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #ffffff;
  }
}

.episodeContainer {
  display: flex;
  // align-items: center;
  width: 100%;

  cursor: pointer;
  padding: 15px;
}

.uploadImageInput {
  display: none;
}

.episodeCoverContainer {
  position: relative;
  cursor: pointer;

  .loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .episodeCover {
    height: 150px;
    min-width: 150px;
    border-radius: 3px;
    max-width: 150px;
    object-fit: cover;
  }

  .episodeCoverNames {
    width: 100%;
    position: absolute;
    top: 65px;
    z-index: 0;
    background: rgba(27, 37, 55, 0.8);
    color: #ffffff;
    padding: 5px 0;
    max-width: 75%;
    font-family: Audiowide;

    .episodeCoverNumber {
      padding: 0 10px;
      font-weight: 600;
      width: fit-content;
      font-size: 12px;
    }

    .episodePodcastsName {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      padding: 2px 10px;

      width: fit-content;
    }

    .episodeSeriesName {
      text-transform: capitalize;
      padding: 4px 10px;
      font-size: 10px;
      font-weight: 600;
      width: fit-content;
    }
  }
}

.episodeContent {
  display: flex;
  // height: 150px;
  // background-color: red;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px;
}

.topPlaceEpisod {
  display: flex;
  column-gap: 15px;
  width: 100%;
}
.playPauseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #4957d8;
  cursor: pointer;
  &:hover {
    background-color: #6674f4;
  }

  &.disabled {
    background-color: #d0d0d0;
    cursor: default;

    &:hover {
      background-color: #d0d0d0;
    }
  }
}
.edit {
  height: 20px;
  align-items: center;
  margin-left: auto;
  order: 1;
  display: none;
}
.episodeContainer:hover .edit {
  display: flex;
}
.waveFormSceleton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #f0f0f0;

  .loaderText {
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 400;
  }

  .loader {
    margin-right: 20px;

    & > div {
      height: 1px;
    }
  }
}

.hiddenWaveForm {
  display: none;
}

.hasError {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  gap: 10px;
  background-color: #ffdddd;
  border-radius: 4px;
  flex-wrap: wrap;

  .errorMessage {
    display: flex;
    height: 30px;
    align-items: center;
    column-gap: 10px;
    color: #ff4f4f;
  }
}

.episodeNumber {
  font-size: 12px;
  color: #a0a0a0;
}

.episodeName {
  font-size: 16px;
  text-transform: capitalize;
}

.bottomPlaceEpisod {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;

  .episodeOption {
    font-size: 14px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .episodeOptionTranscription,
    .episodeRegenerate {
      display: flex;
      border: 1px solid #a0a0a0;
      padding: 6px 16px;
      border-radius: 4px;
      column-gap: 10px;
      cursor: pointer;
      align-content: center;
      justify-content: center;
      color: #3e3e3e;
      .icon {
        fill: #3e3e3e;
        margin-top: 3px;
        &:hover {
          fill: #a0a0a0;
        }
      }
      &:hover {
        color: #a0a0a0;
      }
      .iconRotate180 {
        transform: rotate(180deg);
      }
    }
    .episodeOptionTranscription:hover .icon {
      fill: #a0a0a0;
    }
    .downloading {
      opacity: 0.2;
    }
  }
  .timeBlock {
    @media (max-width: 768px) {
      margin-top: auto;
      margin-bottom: 5px;
    }
  }
}

.transcriptionBlock {
  margin: 0px 20px 20px 180px;
  border-radius: 4px;
  max-height: 300px;
  background-color: #f0f0f0;
  position: relative;
  overflow-y: scroll;
  padding: 20px 4%;
}

.transcriptionBlock::-webkit-scrollbar {
  width: 10px;
}

.transcriptionBlock::-webkit-scrollbar-track {
  background: clear;
}

.transcriptionBlock::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #d0d0d0;
}

.episodeRegenerate {
  display: flex;
  border: 1px solid #a0a0a0;
  padding: 6px 16px;
  height: 30px;
  border-radius: 4px;
  column-gap: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #3e3e3e;
  .icon {
    fill: #3e3e3e;
    margin-top: 3px;
    &:hover {
      fill: #a0a0a0;
    }
  }
  &:hover {
    color: #a0a0a0;
  }
}

.episodeRegenerate:hover .icon {
  fill: #a0a0a0;
}

.dropdownElement {
  font-size: 16px;
  padding: 8px;
  width: 200px;
}
