.mainPodcastPageGeneratorContainer {
  display: flex;
  height: 100%;
  min-height: 540px;
  width: 100%;
  position: relative;
}

.podcastPageContent {
  padding-top: 35px;
  margin: auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: green;
  padding: 50px;
  @media (max-width: 600px) {
    padding: 30px 10px;
    margin-top: 40px;
  }
}

.spinter {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-25%);
}

.podcastPageLogoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.backButton {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  font-size: 16px;
  left: 40px;
  top: 40px;
  cursor: pointer;
  color: #a0a0a0;
}

.podcastPageHeader {
  margin: 40px 0 20px 0;
  font-size: 22px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
}

.inputsBlock,
.inputsBlockTexter {
  padding: 10px;
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 30px;
    text-align: center;
  }
  //  @media (max-width:600px) {
  //   padding: 40px;

  //  }
}

.inputsBlockTexter {
  max-width: 100%;
}

.caruselBlock {
  padding: 0 125px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    margin-bottom: 30px;
    text-align: center;
  }
}

.caruselBlockContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1030px;
}

.contentInputBlock {
  margin-bottom: 30px;
}

.textAreaWrapper {
  margin-top: 50px;
  margin-bottom: 60px;
}
.buttonBlock {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 60px;
  width: 100%;
  @media (max-width: 720px) {
    margin: 0;
  }
  // button {
  //   width: 160px;
  //   margin: 0 auto;
  // }
}

.buttonsBlock {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 160px;
    margin: 0 auto;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.bookPageGeneratorWrapper {
  width: 100%;
  display: flex;
  &::-webkit-scrollbar {
    height: 0px;
  }
}
.bookInfoBlock {
  min-width: 500px;
  background-color: #fff;
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  // z-index: 100;
}

.bookSelectImgBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #f0f0f0;
  padding-left: 25px;
  padding-right: 25px;
  min-width: 590px;
}

.bookImgBlock {
  margin: auto 0;
  width: 100%;
}

.bookImgBlockText {
  margin-top: 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  // margin-bottom: 50px;

  div {
    font-size: 12px;
    color: #a0a0a0;
  }
}

.bookInfoBlockInner {
  width: 100%;
}

.bookEditorWrapper {
  display: flex;
  // grid-template-columns: max-content auto;
  height: 100vh;
  overflow: hidden;
}

.bookEditorSideMenu {
  margin-top: 60px;
  width: 340px;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
  padding-top: 10px;
  background-color: #000000;
  position: relative;
  z-index: 1499;
}

.bookEditorView {
  position: relative;
  margin-top: 60px;
  flex-grow: 1;
}

.bookEditorViewSpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.bookCoverBack {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 4px 10px;
  font-weight: 700;
  border-radius: 5px;
  display: none;
  background-color: #c6c6c6;
}
.bookCoverThumbnail {
  padding: 2px;
  background-color: #c6c6c6;
  position: absolute;
  flex-direction: column;
  gap: 5px;
  width: 73px;
  font-size: 10px;
  text-align: center;

  justify-content: center;
  font-weight: 900;
  height: 125px;
  border-radius: 4px;
  // left: calc(50% - 82px);
  right: 7px;
  top: 5px;
  // z-index: 100;
  display: none;
}

.bookCoverSlide {
  transform: translateX(-50%);
}

.bookCoverThumbnailImage {
  border-radius: 3px;
}

.showMenuButton {
  position: absolute;
  padding: 10px 10px 5px 10px;
  background-color: black;
  // border-radius: 3px;
  top: 65px;
  left: 265px;
  z-index: 1499;
  transition: transform 0.5s;
  &.hover {
    background-color: #2f2f2f;
  }
}

.moveRight {
  // transform: translateX(0);
}

.bookInfoAndEditorContainer {
  display: flex;
}

@media (max-width: 600px) {
  .moveRight {
    transform: translateX(-260px);
  }

  .mainBookPageGeneratorContainer {
    overflow: hidden;
  }
  .bookCoverBack {
    display: block;
  }
  .bookCoverThumbnail {
    display: flex;
  }
  .bookPageHeader {
    margin-bottom: 20px;
    font-size: 25px;
  }
  .bookPageLogoBlock {
    display: none;
  }
  .buttonBlock {
    margin-top: 10px;
  }
  .backButton {
    color: #a0a0a0;
    top: 15px;
    left: 15px;
  }
  .bookInfoBlock {
    position: relative;
    min-width: 100dvw;
    width: 200dvw;
  }
  .bookSelectImgBlockWrapper {
    position: relative;
    // display: none;
    width: 100%;
    min-width: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .bookPageGeneratorWrapper {
    transition: all;
    transition-duration: 0.5s;

    width: 100%;
    // display: block;
  }

  .bookEditorSideMenu {
    position: absolute;
    width: 260px;
    height: calc(100dvh - 60px);
    overflow-x: hidden;
    transition: transform 0.5s;
  }
  .bookEditorWrapper {
    height: 100dvh;
    overflow-x: hidden;
  }

  .doubleWidth {
    width: 200dvw;
  }
}

.podcastSubTitle {
  color: #a0a0a0;
  font-size: 14px;
  text-align: center;
  margin: 0;
  margin-top: 22px;
}

.podcastDescription {
  color: #a0a0a0;
  font-size: 14px;
  text-align: center;
  margin: 0;
  margin: 0 0 40px;
}

.inputSelectBlock {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.voiceList {
  // background-color: green;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 10px;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  padding: 0 2px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    cursor: pointer;
    min-height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      border: 2px solid #4957d8;
    }
  }
  .selectedSpeaker {
    border: 2px solid #4957d8;
  }
}

.voiceList::-webkit-scrollbar {
  width: 10px;
}

.voiceList::-webkit-scrollbar-track {
  background: clear;
}

.voiceList::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #d0d0d0;
}

.voiceItemCointainer {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.avatatarVoice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
}
.previewButtonVoice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #4957d8;
}

.ToggleSwitch {
  border: 1px solid #d0d0d0;
  &:hover {
    border-color: #a0a0a0;
  }
}

.episodeNameAndTone {
  margin-top: 30px;
}

.input {
  height: 50px;
}

.pageContainer {
  height: 100%;
  display: flex;

  .content {
    padding: 30px;
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .contentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      max-height: 600px;
    }

    .pageContent {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .error {
        color: red;
        margin-top: 10px;
      }
    }

    .goBackLink {
      position: absolute;
      left: 30px;
      align-self: flex-start;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 306px;

      .title {
        font-size: 22px;
        font-weight: 600;
        margin-top: 10px;
      }

      .subtitle {
        font-size: 14px;
        color: #a0a0a0;
        font-weight: 300;
        text-align: center;
      }
    }

    .buttonContainer {
      display: flex;
      column-gap: 20px;
    }
  }
}
