.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}

.header {
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .metadata {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .label {
      color: #a0a0a0;
      font-weight: 400;
    }
  }
}

.wrapper {
  display: flex;
  position: relative;

  .confirmLoading {
    position: absolute;
    z-index: 100;
    background-color: rgba(250, 250, 250, 0.98);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .loaderText {
      font-size: 16px;
      color: #a0a0a0;
    }
  }

  .sidebarTitle {
    color: #7d7c7c;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: 15px;
    margin-bottom: 10px;

    svg {
      fill: currentColor;
      width: 18px;
      height: 18px;
    }
  }

  .contentWrapper {
    flex-grow: 1;
    height: calc(100dvh - 60px);
    overflow: auto;
    padding-top: 10px;

    .sectionContent {
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 20px;

      .indexIndicator {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #a0a0a0;
        border-radius: 4px;
        color: #a0a0a0;
        margin-top: 10px;
      }

      .textWrapper {
        max-width: 600px;
        flex-grow: 1;
      }
    }

    .separator {
      background-color: #e8e8e8;
      height: 1px;
      border: none;
      margin: 40px 0;
    }
  }
}
