.container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  align-content: center;
  user-select: none;
  overflow-x: auto;

  .form {
    flex-grow: 1;
    padding: 12px;
    min-width: 280px;

    &.rightBorder {
      border-right: 1px solid #d0d0d0;
    }

    .formName {
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .dragAndDropContainer {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .fieldContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .fieldInfo {
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;

          .fieldTag {
            color: #4957d8;
            display: flex;
            align-items: center;

            div {
              color: #4957d8 !important;
            }

            .deleteIcon {
              fill: #a0a0a0;
              width: 14px;
              height: 14px;
              margin-left: 5px;

              &:hover {
                cursor: pointer;
                fill: #4957d8;
              }
            }
          }
        }

        .inputField,
        .dropdownField {
          height: 30px;
        }
      }
    }

    .addFieldButton {
      margin-top: 15px;
    }
  }

  .addNewFormButton {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.addFieldDropdown {
  li {
    padding: 9px 12px;
  }
}
