.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 580px;
  max-height: 480px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d2d5d9 #ffffff;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  background: #d2d5d9;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #b8babf;
}

.buttonBlock {
  display: flex;
  justify-content: center;
}

.input {
  height: 50px;
  margin-bottom: 20px;
  position: relative;
  div {
    input {
      border-bottom: 1px solid #a0a0a0;
      padding-left: 0px;
      padding-right: 15px;
      background-color: #f0f0f0;
    }
  }

  > span {
    position: absolute;
    top: 48px;
    left: 0px;
    color: red;
  }
}

.switch {
  margin-left: 40px;
  margin-top: 30px;
}

.selectBox {
  margin-left: 40px;
}

.switchName {
  vertical-align: top;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;
}
.stripeLogoSvgBlock {
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 73px;
    height: 30px;
  }
}
.stripeFormText {
  font-size: 22px;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 20px;
}

.stripeNote {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 10px;
  p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-left: 5px;
    margin-right: 5px;
    color: #4957d8;
  }
}

.contentHeader {
  font-size: 34px;
  font-style: normal;
  line-height: 140%;
  color: #000000;
  text-align: center;
  margin-bottom: 40px;
}
