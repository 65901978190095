.header {
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .metadata {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .titleContainer {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .label {
      color: #a0a0a0;
      font-weight: 400;
    }
  }
}
