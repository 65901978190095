@import '../constants.scss';

.link {
  font-size: 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  &.primary {
    &.light {
      color: $grey-500;
      font-weight: 400;
    }

    &.dark {
      color: $grey-400;

      &:hover {
        color: $white !important;
      }
    }
  }

  &.secondary {
    color: $accent-500;
  }

  &:hover {
    color: $accent-700 !important;
  }

  svg {
    fill: currentColor;
  }
}
