.container {
  margin-top: 30px;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .applications {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .application {
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      padding: 20px;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 10px;

          .appName {
            color: #000000;
            font-size: 16px;
            text-transform: capitalize;
          }

          svg {
            path {
              fill: #000000;
            }
          }
        }
      }

      .dropdownsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
      }
    }
  }
}
