.container {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex: 1;

  .smartListInput {
    height: 40px;
    width: 270px;
  }

  .errorMessage {
    font-size: 12px;
    color: var(--alerts-error);
  }

  .contentContainer {
    .title {
      font-size: 16px;
      line-height: 12px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .radioButtons {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: fit-content;

      .radioButton {
        & > span {
          top: 0%;
          transform: unset;
        }
      }

      .radioLabel {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        line-height: 10px;

        .subLabel {
          margin-top: 13px;
          font-size: 12px;
          color: #a0a0a0;
          font-weight: 300;
          line-height: 9px;
        }
      }
    }

    .tagsContainer {
      display: flex;
      gap: 15px;
      align-items: center;

      .tags {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;

        .tag {
          display: flex;
          gap: 5px;
          align-items: center;
          border-radius: 4px;
          background-color: #e8e8e8;
          padding: 10px;
          line-height: 10px;
          font-weight: 400;

          .removeIcon {
            width: 10px;
            height: 10px;
            cursor: pointer;

            path {
              fill: #000000;
            }
          }
        }
      }

      .dropdown {
        width: 275px;

        & > span {
          height: 40px;
        }

        .disabledOption {
          cursor: no-drop;
          color: #a0a0a0;
        }
      }
    }
  }
}

.addTagContainer {
  display: flex;
  column-gap: 10px;

  .attTagInput {
    height: 30px;
  }

  .addTagButton {
    width: 80px !important;
    height: 30px !important;
  }
}

.dropdownOptions {
  li:first-child {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
  }
}
