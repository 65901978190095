.content {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;

  .header {
    font-style: normal;
    font-size: 34px;
    text-align: center;
  }

  .text {
    width: 320px;
    margin: 20px auto 50px auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 2;
    text-align: center;
  }

  .mainImage {
    display: block;
    margin: 0 auto;
  }

  .agencyBlock {
    height: 150px;
    border: #d0d0d0 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      margin-right: 20px;
    }

    > span {
      font-style: normal;
      font-size: 20px;
    }
  }

  .spinnerBlock {
    width: 300px;
    margin: 80px auto 0 auto;
  }
}
