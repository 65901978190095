.container {
  margin-top: 30px;

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .label {
    font-size: 14px;

    .asterisk {
      font-size: 12px;
      color: #ff4f4f;
      position: relative;
      bottom: 4px;
    }
  }

  .inputContainer {
    .labelContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 10px;

      .limit {
        font-size: 12px;
        color: #a0a0a0;
      }
    }
  }

  .paymentPlanContainer {
    margin-top: 30px;

    .planInputs {
      display: flex;
      align-items: center;
      gap: 20px;

      .label {
        margin: 20px 0 10px;
      }

      .priceInput {
        max-width: 170px;
        width: 170px;

        .usd {
          font-size: 14px;
          color: #a0a0a0;
        }
      }
    }
  }

  .input {
    height: 50px;
  }
}
