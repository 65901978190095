.container {
  padding: 40px;
  width: 90%;
  max-width: 900px;

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .table {
      td {
        vertical-align: top;
      }

      .valueCell,
      .hostCell {
        position: relative;
        word-wrap: break-word;
        word-break: break-word;

        svg {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          fill: #000000;
          cursor: pointer;

          &:hover {
            fill: #3a3a3a;
          }
        }

        &:hover {
          svg {
            display: block;
          }
        }
      }

      .valueCell {
        word-wrap: break-word;
        word-break: break-word;
      }

      .loadingIcon {
        animation-name: rotate;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
    }

    .title {
      color: #000000;
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }

    .subTitle {
      font-size: 14px;
      color: #7d7c7c;
      text-align: center;
      max-width: 565px;
      margin: 0 auto;

      a {
        display: unset;
        font-size: 14px;
        color: #4957d8;
      }
    }
  }

  .buttonsWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
