.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000000;

  .improveText {
    margin-top: 25px;
    margin-bottom: 15px;
  }
}
