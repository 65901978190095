.container {
  .urlInput {
    margin-bottom: 20px;
  }

  .imageInput {
    display: none;
  }

  .buttonContainer {
    background-color: #2c2c2c;
    padding: 15px;
    border-radius: 4px;

    .button {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #ffffff;
      background-color: #4957d8;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #6674f4;
      }

      .arrowTop {
        transform: rotate(-90deg);

        path {
          fill: #ffffff;
        }
      }
    }
  }

  .customHeightContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background-color: #2c2c2c;
    padding: 14px 15px;
    border-radius: 4px;

    .inputIcon {
      path {
        fill: #d0d0d0;
      }
      rect {
        fill: #d0d0d0;
      }
    }

    .px {
      color: #5a5a5a;
      position: relative;
      cursor: default;
      user-select: none;
    }

    .arrows {
      top: -8px;
      right: -5px;
      gap: 10px;
    }

    .sizeFieldWrapper {
      margin-right: 0px;
      height: 20px;
      background-color: transparent !important;

      .inputContainerClassName {
        background-color: transparent !important;

        & > div {
          width: 80px;
        }

        div {
          background-color: transparent !important;

          input {
            text-align: end;
            width: fit-content;
          }
        }
      }

      .sizePostfix {
        display: flex;
      }

      .sizeField {
        border-radius: 0;
        padding-left: 10px;
      }

      .rightSizeField {
        border-radius: 0 5px 5px 0;
      }
    }

    .toggleContainer {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        color: #7d7c7c;
      }
    }
  }
}
