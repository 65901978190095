.popupContainer {
  top: -9999;
  left: -9999;
  position: absolute;
  z-index: 1;
  padding: 3px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  min-width: 100px;

  .textWrapper {
    padding: 1px 3px;
    border-radius: 4px;
    cursor: pointer;
    color: #000000;

    &:hover {
      background-color: #f0f0f0;
    }

    &.selected {
      background-color: #4957d8;
      color: #ffffff;
    }
  }
}
