.mainContent {
  display: grid;
  grid-template-columns: 280px auto;
  height: 100vh;

  .agencySection {
    height: 100vh;
    overflow: auto;
    background-color: #e8e8e8;
    position: relative;
    padding-top: 0 !important;

    .contentContainer {
      max-width: 1180px;
      width: 100%;
      margin: 0 auto;
    }

    .accounts {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
    }

    .userAccountContainer {
      padding: 0 30px 40px;

      .accounts {
        margin-bottom: 20px;
      }
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;

      .addButton {
        height: 40px;
        width: 220px;
        background-color: #4957d8;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: #ffffff;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .bannerContainer {
      height: 220px;
      margin-bottom: 60px;
      position: relative;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;

      .banner {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(40, 40, 40, 0.4);
        backdrop-filter: blur(60px);
        z-index: 1;
      }

      .bannerInfo {
        position: relative;
        padding-left: 30px;
        top: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        z-index: 1;
      }

      .agencyInfo {
        font-size: 24px;
        color: #ffffff;
        margin: 0;
        padding: 0;
        position: relative;
        bottom: 30px;

        .accountsNumber {
          font-size: 12px;
          color: #d0d0d0;
        }
      }
    }
    padding-top: 50px;

    .mainHeader {
      font-style: normal;
      font-size: 24px;
      text-align: center;
    }

    .accountsList {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px;
    }
  }
}

.accountCard {
  display: inline-block;
  width: 100%;
  height: 120px;
  background: #fafafa;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  position: relative;
  cursor: pointer;

  &.active {
    background-color: #cfd1dd;
  }

  .cardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    .info {
      display: flex;
      align-items: center;
      gap: 30px;

      .textContent {
        p {
          text-align: start;
          margin: 0;
        }

        .name {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 10px;

          .activeIndicator {
            background-color: #e5f4ed;
            border-radius: 4px;
            color: #25bb73;
            font-size: 12px;
            font-weight: 400;
            display: inline-flex;
            padding: 2px 3px;
            text-transform: uppercase;
          }
        }

        .description {
          font-size: 14px;
          color: #a0a0a0;
        }
      }
    }
  }

  .plusBlock {
    background: #ffffff;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    margin: 30px auto 0 auto;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .addAccountComment {
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #a0a0a0;
  }

  .addNewActions {
    list-style: none;
    margin: 0;
    position: absolute;
    padding: 10px 0;
    border-radius: 12px;
    width: 284px;
    background-color: #fff;
    bottom: -130px;
    z-index: 2;
  }

  .action {
    height: 50px;
    cursor: pointer;
    display: grid;
    padding: 0 20px;
    grid-template-columns: 25px auto;
    align-items: center;
    text-align: left;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .thumbnailBlock {
    background: #f0f0f0;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .thumbnail {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }

  .dots {
    background-color: #fafafa;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    display: none;
    border: #000 1px solid;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .dots {
      display: flex;
    }
  }

  .actions {
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    bottom: -140px;
    right: -190px;
    list-style: none;
    margin: 0;
    padding: 5px 0;
    width: 230px;
    z-index: 2;
  }

  .actionsWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
  }

  .clocks {
    path {
      fill: #faa500;
    }
  }
}

.spinnerBlock {
  padding: 200px 0 0 0;
  margin: 0 auto;
  width: 300px;
}

.logoThumbnail {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
