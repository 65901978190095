.csvColumn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contactField {
  max-width: 170px;

  .dropdown > span {
    height: 30px !important;
  }
}
