.loader {
  max-height: calc(100vh - 60px);
}

.productItem {
  display: grid;
  grid-template-columns: 100px 300px 50px 50px 80px 80px;
}

.header {
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  button {
    font-size: 14px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;

  .searchBlock {
    width: 320px;
    height: 40px;
    display: flex;
    align-items: center;
    column-gap: 18px;

    & > div:first-child {
      width: 220px;

      input {
        background-color: transparent;
      }
    }
  }

  .sortBlock {
    display: flex;
    align-items: center;
    column-gap: 6px;

    & > span {
      cursor: pointer;
    }

    .selectedOption {
      color: #5a5a5a;
    }
  }
}

.productsList {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}
