.nodeAdderMarker {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #6674f4;
  border-radius: 8px;
  border: 2px white solid;
}

.rootContainer {
  position: relative;
  height: 100%;
  overflow-y: auto;
  z-index: 1;
}
