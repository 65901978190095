@mixin button {
  width: 220px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
}

.button {
  @include button;
  border: 1px solid #000000;
  color: #000000;
}

.button:hover {
  cursor: pointer;
  color: #3e3e3e;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonBlack {
  @include button;
  color: #ffffff;
  background-color: #000000;
}

.buttonBlack:hover {
  cursor: pointer;
  background-color: #3e3e3e;
}

.buttonBlack {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container img {
  height: 16px;
  width: 16px;
  margin-right: 9px;
}
