.container {
  width: 100%;
  height: 160px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--side-bar-background);

  img {
    max-width: 370px;
    max-height: 130px;
    border-radius: 4px;
    object-fit: contain;
  }
}
