.container {
  position: absolute;
  right: 50%;
  background-color: black;
  border-radius: 5px;
  padding: 30px 20px 20px;
  width: 370px;
  z-index: 5;
  transform: translate(50%, 0);

  .draggableHeader {
    position: absolute;
    height: 14px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    cursor: all-scroll;

    .indicator {
      width: 40px;
      height: 4px;
      border-radius: 5px;
      background-color: #7d7c7c;
    }

    &:hover {
      background-color: #3e3e3e;

      .indicator {
        background-color: #a0a0a0;
      }
    }

    &.active {
      background-color: #5a5a5a;

      .indicator {
        background-color: #d0d0d0;
      }
    }
  }

  &.smallContainer {
    padding: 0;
    width: 150px;
    top: 48px;
    transform: translate(70%, 0);
    left: -30px;
    right: initial;
    height: fit-content;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid black;
      top: -8px;
      left: 15px;
    }
  }
}
