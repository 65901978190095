.settingsPage {
  display: flex;
  overflow-x: hidden;
}

.content {
  position: relative;
  background: #f0f0f0;
  min-height: 100vh;
  width: calc(100% - 280px);
  transform: translateX(0%);
  transition: all;
  transition-duration: 0.5s;
  margin-left: 280px;

  .contentContainer {
    max-width: 1180px;
    padding: 30px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
}

.settingNavigation {
  background: #000;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  height: 100vh;
  width: 280px;
  vertical-align: top;
  transform: translateX(0%);
  transition: all;
  transition-duration: 0.5s;
}
.settingNavigationInner {
  position: relative;
  background: #131313;
  color: #fff;
  display: flex;
  flex-direction: column;

  height: 100vh;
  width: 240px;
}
.sidebarToggleBtnBlock {
  cursor: pointer;
  position: absolute;
  right: -60px;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: #000000;
  display: none;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  &.openBlock {
    right: -40px;
  }

  img {
    width: 16px;
    height: 16px;
  }

  @media only screen and (max-width: 1000px) {
    display: flex;
  }
}
.moveLeft {
  transform: translateX(-300px);
}
.contentMoveLeft {
  margin-left: 0px;
}
.navItemsBlock {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
  &::-webkit-scrollbar {
    width: 0;
  }
}
.navItem {
  display: flex;
  align-items: center;
  width: 220px;
  height: 50px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  margin: 10px auto;

  &:first-child {
    margin: 0 auto;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #d0d0d0;
    padding-left: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    fill: #d0d0d0;
  }
  &:hover {
    background-color: #1f1f1f;
    a {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
}
.navItemActive {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  width: 220px;
  height: 50px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  margin: 10px auto;
  background-color: #252c68;
  &:first-child {
    margin: 0px auto;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    padding-left: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    fill: #fff;
  }
}

.logoContainer {
  height: 57px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-top: 20px;
  padding-bottom: 11px;
}

.logo {
  margin-top: 1px;
  height: 20px;
}

.topRoundedSvg {
  position: absolute;
  top: 0;
  right: -24px;
}
.bottomRoundedSvg {
  position: absolute;
  bottom: 0;
  right: 0px;
}

.creditsContainer {
  display: flex;
  width: 100%;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  position: relative;
  gap: 13px;
  &::before {
    content: '';
    position: absolute;
    background-color: #3e3e3e;
    height: 1px;
    width: 184px;
    top: 0;
    left: 25px;
  }

  .creditsInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 180px;
    // justify-content:space-between;
    .credit {
      flex-grow: 1;
    }
    span {
      margin-right: 0px;
    }
    .addSvgBackground {
      position: relative;
      text-align: center;
      width: 30px;
      height: 30px;
      padding: 6px 5px 5px 6px;
      background-color: #3e3e3e;
      border-radius: 50%;
      fill: #d0d0d0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .freeCreditsButton {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: none;
    width: 180px;
    background: #3e3e3e;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    span {
      svg {
        margin-top: 6px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .creditsContainer {
    display: none;
  }
}

.freeCreditsBlock {
  display: none;
}

@media (max-width: 1280px) {
  .card {
    width: 210px;
    height: 315px;
    margin: 9px 10px;
  }
  .cardPlusIcon {
    top: 50px;
    left: 30px;
  }
  .cardPlusText {
    font-size: 16px;
    top: 220px;
  }
}

@media (max-width: 600px) {
  .card {
    width: 150px;
    height: 225px;
    margin: 5px 7px;
  }
  .cardPlusIcon {
    width: 76px;
    height: 76px;
    top: 40px;
    left: 37px;

    .bookPlus {
      width: 30px;
      height: 30px;
    }
  }
  .cardPlusText {
    font-size: 14px;
    top: 140px;
  }
  .freeCreditsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: black;
    z-index: 100;
    padding: 0 15px;

    .creditsInfo {
      display: flex;
      column-gap: 12px;
      font-size: 16px;
      color: #d0d0d0;
    }

    .freeCreditsButtonBlock {
      display: flex;
      column-gap: 10px;

      .plusButton {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7px;
        padding-left: 1px;
        max-width: 30px;
        max-height: 30px;
        background: #3e3e3e;
      }

      .creditsButton {
        display: flex;
        justify-content: center;
        background: #3e3e3e;
        color: #d0d0d0;
        max-width: 130px;
        max-height: 30px;
        font-size: 14;
      }
    }
  }
}
