.domainItemBlock {
  display: flex;
  justify-content: space-between;
  height: 70px;
  min-width: 900px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  padding-right: 15px;
  background-color: #ffffff;
  > div {
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    &:nth-child(1) {
      width: 33.2%;
      padding-left: 20px;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
    &:nth-child(3) {
      width: 290px;
    }
    &:nth-child(4) {
      width: 100%;
      max-width: 190px;
      display: flex;
      justify-content: end;
      > div {
        &:nth-child(1) {
          button {
            background-color: white;
          }
        }
      }
    }
  }
}
.domainItemBlockName {
  color: #000;
  min-width: 215px;
  @media only screen and (max-width: 900px) {
    width: 215px;
    min-width: 215px;
  }
}
.domainItemBlockStatus {
  color: #faa500;
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    fill: #faa500;
    margin-right: 10px;
  }
  min-width: 225px;
  @media only screen and (max-width: 900px) {
    width: 225px;
    min-width: 225px;
  }
}
.domainItemBlockStatusSuccess {
  color: #faa500;
  display: flex;
  align-items: center;
  color: #25bb73;
  svg {
    width: 16px;
    height: 16px;
    fill: #25bb73;
    margin-right: 10px;
  }
  min-width: 225px;
  @media only screen and (max-width: 900px) {
    width: 225px;
    min-width: 225px;
  }
}
.domainItemBlockFunnel {
  min-width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  @media only screen and (max-width: 900px) {
    width: 240px;
    min-width: 240px;
  }
  > div {
    > div {
      border: none;

      &:nth-child(2) {
        > div {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
}
.pointsBlock {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 3px;
    width: 14px;
  }
  height: 20px;
  width: 15px;
  margin: auto 0;
  margin-left: 20px;
}
.removeBlock {
  cursor: pointer;
  width: 240px;
  height: 70px;
  position: relative;
  left: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #3e3e3e;
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: white;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
.associatedFunnelBlock {
  width: 200px;
}
.refreshDomainSvg {
  margin-right: 10px;
}
.selectedOption {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  svg {
    fill: #000000;
    min-width: 16px;
  }

  &:hover {
    color: #6674f4;
    svg {
      fill: #6674f4;
    }
  }
}
.domainSelectFunnelArrowSvg {
  margin-left: 10px;
}
.selectFunnelOptionsBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 230px;
  background-color: white;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  position: relative;
  left: -35%;
  top: -10px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 1500px) {
    left: -25%;
  }
  @media (max-width: 1300px) {
    left: -20%;
  }
  div {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid #d0d0d0;
  }
}
.selectFunnelOptionItem {
  min-height: 50px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    width: 16px;
    height: 16px;
  }
}
