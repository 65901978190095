.container {
  height: 78px;
  display: inline-block;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
}

.textarea {
  resize: none;
  height: 160px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 16px;
}

.textarea::placeholder {
  color: #7b8695;
}

.textarea:focus {
  outline: none;
}
