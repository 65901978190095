.editor {
  outline: none;
  white-space: unset;

  h1.title {
    font-size: 30px;
    font-weight: bold;
  }

  h2.title {
    font-size: 24px;
  }

  h3.title {
    font-size: 20px;
  }

  h4.title {
    font-size: 18px;
  }

  h5.title {
    font-size: 16px;
  }

  h6.title {
    font-size: 14px;
    color: #7d7c7c;
  }

  .title {
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 22px;
  }

  .paragraph {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6em;
    margin-bottom: 0.1em;
  }

  .listItem {
    margin-left: 18px;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }

  .audio {
    margin-bottom: 1em;
  }

  .table {
    margin: 10px;
    padding: 5px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .link {
    color: inherit;
  }
}
