.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalBlock,
.modalBlockShow {
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  position: absolute;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.modalBlockShow {
  opacity: 1;
  display: flex;
  //  width: 70%;
  // height: auto;
}

.modalBlockTitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  height: 62px;
  width: 330px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.modalBlockButton {
  display: flex;
  gap: 30px;

  div {
    background-color: #e2e2e2;
    display: flex;
    font-size: 15px;
    align-items: center;
    line-height: 20px;
    max-width: 155px;
    width: 155px;
    color: #5a5a5a;
    gap: 15px;
    padding: 14px 14px 14px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.modalBlockContinue,
.modalBlockBeginStart {
  &:hover {
    background-color: #fff;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // border-radius: 8px; //it's needed for bordering video
}

.playerContainer,
.playerContainerFull {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: auto;
  cursor: pointer;
}

.playerContainer {
  width: 70%;
  max-width: 1770px;
}

.playerContainerFull {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}

.timelineContainer {
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s linear;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  // border-end-start-radius: 8px; //it's needed for bordering video
  // border-bottom-right-radius: 8px; //it's needed for bordering video
}

.playerContainer:hover .timelineContainer,
.playerContainerFull:hover .timelineContainer {
  opacity: 1;
}

.timelineContainerHidden {
  opacity: 0 !important;
}

.timeline {
  width: 100%;
  height: 12px;
  transition: height 0.1s linear;
  border: 1px solid #a0a0a0;
  overflow: hidden;

  &:hover {
    height: 12px;
  }
}

.progressBarContainer {
  width: 100%;
  height: 100%;
}

.playProgress {
  transition: background-color 0.3s linear;
  height: 100%;
  background-color: #a0a0a0;
  z-index: 1;
}

.playProgressTransition {
  transition: width 0.3s linear, background-color 0.2s linear;
  height: 100%;
  background-color: #a0a0a0;
  z-index: 1;
}

.playProgressTransition:hover,
.playProgress:hover,
.timeline:hover .playProgress,
.timeline:hover .playProgressTransition {
  background-color: #fff;
}

.bufferProgress {
  height: 100%;
  position: absolute;
}

.optionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.playPauseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25px;
  min-width: 25px;
  width: 25px;
  height: 100%;
  margin-left: 8px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.playPause,
.muteSound,
.screenButton {
  fill: #a0a0a0;
  &:hover {
    fill: white;
  }
}

.soundScaleBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin: 0 10px;
  gap: 10px;

  span {
    cursor: pointer;
  }
}

.closeIconVisible {
  width: 20px;
  display: flex;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.closeIconHidden {
  display: none;
}

.spinnerPlace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.modalBlock:hover .closeIconHidden,
.modalBlockShow:hover .closeIconHidden {
  display: block !important;
}

.getStartedButton {
  background: #4957d8;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  height: 50px;
  width: 280px;
  border-radius: 50px;
  color: white;
  line-height: 50px;
  z-index: 100000;
  &:hover {
    opacity: 0.8;
  }
}
