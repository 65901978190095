.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 14px;
  width: 100%;
  max-width: 420px;

  .input {
    margin-top: 8px;
    height: 50px;
  }

  .error {
    border-color: red;
  }
}
