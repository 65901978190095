.container {
  min-width: 160px;
  display: inline-block;
  white-space: nowrap;
  &:hover {
    .border {
      background-color: var(--accent-dark-accent-hover);
    }
  }
}
.border {
  margin-top: 9px;
  width: 100%;
  height: 4px;
  background-color: #a0a0a0;
  cursor: pointer;
}
.borderPrimary {
  margin-top: 9px;
  width: 100%;
  height: 4px;
  background-color: var(--accent-accent);
  cursor: pointer;
}
.button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
}

.container {
  display: inline-block;
  cursor: pointer;
}
