.container {
  width: 100%;
  flex: 1;
  border: 1px solid transparent;
  border-radius: 4px;

  &.disabled {
    //NOTE: instead of disabled maybe we should do readOnly for consitency in other parts of the code
    // background-color: rgb(238, 238, 238);
  }

  .generating {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 4px;
    animation: gradientMove 2s ease infinite;
    background: linear-gradient(40deg, #9d9d9d, #dcdcdc);
    background-position: 10% 50%;
    background-size: 200% 200%;
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
