.container {
  flex-grow: 1;
  height: 100dvh;
  overflow: auto;
  background-color: #f0f0f0;

  .contentContainer {
    max-width: 1180px;
    padding: 30px;
    margin: 0 auto;

    &.loaderContainer {
      padding: 30px 30px 0;
    }

    .broadcastElements {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
}
