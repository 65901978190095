.container {
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  height: 100vh;

  .errorContainer {
    text-align: center;
    padding: 10px;
    align-items: center;
    color: red;
  }

  .progressContainer {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;

    .generationTitle {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .generationSubTitle {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #a0a0a0;
    }

    .generationPercent {
      text-align: center;
      margin-top: 10px;
    }
  }

  .backLink {
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin: 5px 0;
  }

  .subtitle {
    font-size: 14px;
    color: #a0a0a0;
    font-weight: 300;
    max-width: 300px;
    text-align: center;
  }

  .tabsWrapper {
    color: #a0a0a0;
    margin-top: 10px;

    .suggestions {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      .reloadIcon {
        fill: currentColor;
        cursor: pointer;

        &.loading {
          animation: rotate 1s linear infinite;
        }
      }
    }

    .tabs {
      display: flex;
      column-gap: 10px;
      margin-top: 12px;
    }
  }

  .form {
    width: 100%;
    max-width: 420px;
    margin-top: 30px;
    margin-bottom: 30px;

    .label {
      margin-bottom: 7px;
    }

    .input {
      width: 100%;
      margin-bottom: 20px;

      input {
        height: 50px;
      }
    }

    .textarea {
      width: 100%;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
