@mixin animate {
  overflow: hidden;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #d9d9d9 2%, #ececec 18%, #d9d9d9 33%);
  background-size: 1300px;
}

.placeholder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  @include animate;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
