.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  // background-color: rgba(255,0,0.4, 0.4);
  pointer-events: none;
}
.selectedRect {
  position: relative;
  border: 2px solid #4957d8;
}
.noPointerEvents {
  pointer-events: none;
}

.dot {
  pointer-events: auto;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #4957d8;
  border: 2px solid white;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: #8d98fb;
  }

  &:active {
    background-color: white;
    transform: scale(1.4);
  }
}

.left {
  left: -8px;
}

.right {
  right: -8px;
}

.top {
  top: -8px;
}

.bottom {
  bottom: -8px;
}
