.container {
  position: absolute;
  transform-origin: center;
  display: flex;

  // border: solid 1px red;
}

.frozen {
  pointer-events: none;
}

.image {
  width: 100%;
  height: 100%;
  user-select: none;
  object-fit: contain;
}

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
}

.textContainer {
  // width: min-content;
  display: flex;
  justify-content: center;
  width: 100%;
}

.text {
  padding: 10px;
  background-color: transparent;
  border: none;
  pointer-events: auto;
  transform-origin: top;

  div {
    overflow: unset;
  }

  // div[data-contents='true'] {
  //   overflow: hidden ;
  // }
  // div[data-block='true'] {
  //   overflow: unset;
  // }
}

.noTextSelect {
  user-select: none;
  // pointer-events: none;

  div {
    user-select: none;
    // pointer-events: none;
  }
}
