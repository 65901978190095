.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 420px;
  min-height: 400px;
  justify-content: center;

  .input {
    height: 50px;
  }
}
